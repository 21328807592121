import {
  Alert,
  Box,
  Button,
  CircularProgress,
  FormControl,
  Grid,
  IconButton,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  Snackbar,
  styled,
  Table,
  TableBody,
  TableCell,
  tableCellClasses,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TextField,
  Typography,
} from '@mui/material'
import { useContext, useEffect, useRef, useState } from 'react'
import { Context } from '../../../context/AuthContext'
import { getConference, getOrderItems, sendConference } from '../../../services/requestServices'
import { Add, Clear, ContactlessOutlined, Delete, Help } from '@mui/icons-material'
import { colorTableDefault } from '../../TemplateDefault'
import { getProductItensRfid } from '../../../services/productService'

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: colorTableDefault,
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
    color: 'inherit',
  },
}))

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: theme.palette.action.hover,
  },
  '&:last-child td, &:last-child th': {
    border: 0,
  },
}))

const PickingConference = () => {
  const token = localStorage.getItem('token')
  const { userRoles, nameUrl, subCompany, company, userId } = useContext(Context)
  const [subCompanyId, setSubCompanyId] = useState('')
  const inputRef = useRef(null)
  const [valueText, setValueText] = useState('')
  const [orders, setOrders] = useState([])
  const [selectedOrder, setSelectedOrder] = useState(null)
  const [orderItems, setOrderItems] = useState([])
  const [loading, setLoading] = useState(false)
  const [loadingAddCode, setLoadingAddCode] = useState(false)
  const [title, setTitle] = useState('')
  const [alerta, setAlerta] = useState(false)
  const [severity, setSeverity] = useState('warning')
  const [viewElearning, setViewElearning] = useState(false)
  const [page, setPage] = useState(0)
  const [rowsPerPage, setRowsPerPage] = useState(10)
  const [selectedEquipment, setSelectedEquipment] = useState('')
  const [messageError, setMessageError] = useState(false)
  const [codeList, setCodeList] = useState([])
  const [serials, setSerials] = useState([])
  const [filial, setFilial] = useState('')

  const vertical = 'top'
  const horizontal = 'center'

  const getAllOrders = async () => {
    setLoading(true)
    try {
      const response = await getConference(token, nameUrl, subCompanyId)
      setOrders(response.data.result)

      console.log(response)
      setLoading(false)
    } catch (e) {
      console.log(e)
      setLoading(false)
    }
  }

  const handleSelectOrder = async order => {
    setSelectedOrder(order)

    try {
      const response = await getOrderItems(
        token,
        nameUrl,
        subCompanyId,
        order.movimentationOrderNumberOtherSystem
      )
      setOrderItems(response.data.movimentationerp || [])
    } catch (error) {
      handleThrowError(error.response.data.title ?? 'Erro ao buscar Items.')
    }
  }

  useEffect(() => {
    getAllOrders()
  }, [subCompanyId])

  useEffect(() => {}, [orderItems])

  const handleChangeCodigoRfid = e => {
    setValueText(e.target.value)
    setMessageError('')
  }

  const handleChangePage = (event, newPage) => {
    setPage(newPage)
  }

  const getRowBackgroundColor = row => {
    if (row.quantityOrder === 0) {
      return '#A020F0'
    } else if (row.quantityPallet === row.quantityOrder) {
      return '#008000'
    } else if (row.quantityPallet < row.quantityOrder) {
      return '#008000'
    } else {
      return '#FF0000'
    }
  }

  const handleChangeRowsPerPage = event => {
    setRowsPerPage(+event.target.value)
    setPage(0)
  }

  const handleClose = () => {
    setAlerta(false)
  }
  const handleDelete = itemToDelete => {
    const updatedRows = codeList.filter(item => item !== itemToDelete)
    const updatedSerials = serials.filter(serial => !itemToDelete.serials.includes(serial))
    setCodeList(updatedRows)
    setSerials(updatedSerials)
    setTitle('Produto removido com sucesso.')
    setAlerta(true)
    setSeverity('success')
  }

  const handleCleanList = () => {
    setSerials([])
    setCodeList([])
    setTitle('Lista limpa com sucesso.')
    setAlerta(true)
    setSeverity('success')
  }
  const handleEmptyText = errorMessage => {
    setMessageError(errorMessage)
  }

  const handleThrowError = title => {
    setTitle(title)
    setAlerta(true)
    setSeverity('error')
    setValueText('')
    setMessageError('')
  }

  console.log(token)

  const handleAddList = async () => {
    if (valueText === '') {
      handleEmptyText('Campo obrigatório.')
      return
    }
    const serialExist = serials.find(item => item === valueText)

    if (serialExist) {
      handleThrowError(`Codigo ${valueText} já adicionado.`)
      setValueText('')
      setMessageError('')
      return
    }
    let newProduct
    let response

    try {
      setLoadingAddCode(true)
      response = await getProductItensRfid(token, nameUrl, subCompanyId, valueText)
      setValueText('')
    } catch (error) {
      handleThrowError(error.response.data.title ?? 'Erro ao buscar produto.')
      setLoadingAddCode(false)
      return
    }

    if (!response || !response.data) {
      handleThrowError('Produto não encontrado.')
      setLoadingAddCode(false)
      return
    }

    if (response.data.length === 0) {
      handleThrowError('Produto não encontrado.')
      setLoadingAddCode(false)
      return
    }

    const productData = response.data

    const orderItem = orderItems.find(
      item => item.idProductPartner === productData.idProductPartner
    )
    const quantityOrder = orderItem ? orderItem.quantity : 0

    const productExists = codeList.some(product => product.productId === response.data.productId)

    if (productExists) {
      setCodeList(prevList =>
        prevList.map(item =>
          item.productId === response.data.productId
            ? {
                ...item,
                serials: [...item.serials, valueText],
                quantityPallet: (item.quantityPallet || 0) + 1,
                quantityOrder,
              }
            : item
        )
      )
    } else {
      newProduct = {
        idProductPartner: response.data.idProductPartner ?? '',
        reference: response.data.referencia ?? '',
        color: response.data.color ?? '',
        barCode: response.data.barCode ?? '',
        subCompanyCNPJ: response.data.subCompanyCNPJ ?? '',
        dataExpiration: response.data.dataExpiration ?? '',
        productName: response.data.productName ?? '',
        size: response.data.size ?? '',
        productId: response.data.productId ?? '',
        serials: [valueText],
        quantityPallet: 1,
        sku: response.data.sku,
        quantityOrder,
      }

      setCodeList(prevList => [...prevList, newProduct])
    }

    setSerials(prevSerials => [...prevSerials, valueText])
    setValueText('')
    setLoadingAddCode(false)
  }

  const handleSendCodeList = async () => {
    if (codeList.length === 0) {
      setTitle('A lista está vazia. Adicione itens antes de enviar.')
      setAlerta(true)
      setSeverity('warning')
      return
    }

    if (userId) {
      setLoading(true)
      console.log(selectedOrder)
      try {
        const payload = codeList.flatMap(item =>
          item.serials.map(serial => ({
            movimentationOrderNumberOtherSystem: selectedOrder.movimentationOrderNumberOtherSystem,
            movimentationOrderNumberBihands: selectedOrder.movimentationOrderNumberBihands,
            idProductPartner: item.idProductPartner,
            found: true,
            movimentationOrderId: selectedOrder.movimentationOrderId,
            serial: serial,
            subCompanyId: subCompanyId,
          }))
        )

        console.log(payload, 'PAYLOAD')
        const response = await sendConference(token, nameUrl, payload)

        setTitle(response.data.message)
        setAlerta(true)
        setSeverity('success')
        setCodeList([])
        setFilial('')

        console.log('Resposta da API:', response.data)
      } catch (error) {
        console.error('Erro ao enviar lista:', error)
        setTitle('Erro ao enviar lista. Tente novamente.')
        setAlerta(true)
        setSeverity('error')
      } finally {
        setLoading(false)
      }
    }
  }

  return (
    <>
      <Box mt={7.5}>
        <Box sx={{ width: 1, display: 'flex', justifyContent: 'space-between' }}>
          <Snackbar
            anchorOrigin={{ vertical, horizontal }}
            open={alerta}
            onClose={handleClose}
            key={vertical + horizontal}
          >
            <Alert onClose={handleClose} severity={severity} sx={{ width: '100%' }}>
              {title}
            </Alert>
          </Snackbar>
          <Typography variant="h5"> Conferência de peças</Typography>
          <IconButton sx={{ justifySelf: 'flex-end' }}>
            <Help onClick={() => setViewElearning(true)} />
          </IconButton>
        </Box>
        <Grid container component={Paper} xs={12}>
          <Grid item xs={12} sm={12} md={6} lg={6} padding={2}>
            <FormControl sx={{ width: '100%', mt: 0 }}>
              <InputLabel id="demo-simple-select-label">Filial</InputLabel>
              <Select
                value={subCompanyId}
                onChange={event => setSubCompanyId(event.target.value)}
                sx={{ width: '100%' }}
                label="Filial"
                disabled={subCompany.length >= 1 ? false : true}
              >
                {subCompany.map((item, index) => (
                  <MenuItem value={item.subCompanyId} key={item.subCompanyId}>
                    {item.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={12} md={6} lg={6} padding={2}>
            <FormControl sx={{ width: '100%' }}>
              <InputLabel id="demo-simple-select-label">Pedido</InputLabel>
              <Select
                label="Selecione..."
                value={selectedOrder}
                onChange={e => handleSelectOrder(e.target.value)}
              >
                {orders?.map((item, index) => {
                  return (
                    <MenuItem value={item} key={index}>
                      {item.movimentationOrderNumberOtherSystem}
                    </MenuItem>
                  )
                })}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={12} md={6} lg={6} padding={2}>
            <FormControl sx={{ width: '100%', mt: 0 }}>
              <InputLabel sx={{ color: 'black' }} id="demo-simple-select-label">
                Escolha o equipamento
              </InputLabel>
              <Select
                value={selectedEquipment}
                onChange={event => {
                  setSelectedEquipment(event.target.value)
                  //   handleEquipmentChange(event)
                }}
                sx={{ width: '100%', color: 'black' }}
                label="Escolha o equipamento"
              >
                <MenuItem selected value={1}>
                  HID
                </MenuItem>
              </Select>
            </FormControl>
          </Grid>
          {selectedOrder && (
            <Grid item xs={12} sm={12} md={6} lg={6} padding={2}>
              <TextField
                inputRef={inputRef}
                autoFocus
                label="Codigo RFID"
                type="text"
                //   disabled={handleDisableInput()}
                //   onKeyDown={handleKeyPress}
                value={valueText}
                //   error={!!messageError}
                //   helperText={messageError}
                onChange={e => handleChangeCodigoRfid(e)}
                fullWidth
                variant="outlined"
                InputLabelProps={{
                  style: { color: 'black' },
                }}
                sx={{
                  '& .MuiInputLabel-root': {
                    color: 'black',
                  },
                  '& .MuiInputLabel-root.Mui-focused': {
                    color: 'red',
                  },
                }}
              />
            </Grid>
          )}

          <Grid item xs={12} sm={12} md={6} lg={6} padding={2}>
            <Box
              display="flex"
              justifyContent="flex-end"
              width="100%"
              alignItems="start"
              height="100%"
              sx={{ zIndex: theme => theme.zIndex.drawer + 1 }}
            >
              <Button
                sx={{
                  mr: 2,
                  height: '100%',
                  width: '100%',
                  maxHeight: '56px',
                }}
                variant="outlined"
                startIcon={<Clear />}
                onClick={() => handleCleanList()}
                // disabled={counting}
              >
                Limpar
              </Button>
              <Button
                sx={{
                  mr: 2,
                  height: '100%',
                  width: '100%',
                  maxHeight: '56px',
                  color: 'black',
                }}
                startIcon={<Add />}
                variant="contained"
                onClick={() => {
                  handleAddList()
                }}
                // disabled={counting}
              >
                {loadingAddCode ? <CircularProgress size={24} /> : 'Adicionar Código'}
              </Button>
              <Button
                sx={{
                  height: '100%',
                  width: '100%',
                  maxHeight: '56px',
                  color: 'black',
                }}
                variant="contained"
                onClick={handleSendCodeList}
                // disabled={counting}
              >
                {loading ? <CircularProgress size={24} /> : 'Enviar Informações'}
              </Button>
            </Box>
          </Grid>
        </Grid>

        <Box
          position="relative"
          display="flex"
          justifyContent="center"
          alignItems="center"
          flexDirection="column"
        >
          <Box display="flex" alignItems="center" justifyContent="center" gap={2} mt={2}>
            <Typography variant="h5">{`Total de produtos: ${codeList.length}`}</Typography>
            <Typography variant="h5">{`Total de itens contados: ${codeList.reduce(
              (total, item) => total + item.quantityPallet,
              0
            )}`}</Typography>
          </Box>
          <TableContainer component={Paper} sx={{ mt: '2rem' }}>
            <Table sx={{ minWidth: 700 }} aria-label="customized table">
              <TableHead>
                <TableRow>
                  <StyledTableCell align="left"> Código ERP</StyledTableCell>
                  <StyledTableCell align="left">Nome do Produto</StyledTableCell>
                  <StyledTableCell align="left">Quantidade</StyledTableCell>
                  <StyledTableCell align="left">Quantidade do Pedido</StyledTableCell>
                  <StyledTableCell align="left">Cor</StyledTableCell>
                  <StyledTableCell align="left">Tamanho</StyledTableCell>
                  <StyledTableCell align="left">Referencia</StyledTableCell>
                  <StyledTableCell align="left">SKU</StyledTableCell>

                  <StyledTableCell align="left">Ações</StyledTableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {codeList.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map(row => (
                  <StyledTableRow
                    key={row.name}
                    style={{ backgroundColor: getRowBackgroundColor(row), color: '#FFF' }}
                  >
                    <StyledTableCell align="left">{row.idProductPartner}</StyledTableCell>
                    <StyledTableCell align="left">{row.productName}</StyledTableCell>
                    <StyledTableCell align="left">{row.quantityPallet}</StyledTableCell>
                    <StyledTableCell align="left">{row.quantityOrder}</StyledTableCell>
                    <StyledTableCell align="left">{row.color}</StyledTableCell>
                    <StyledTableCell align="left">{row.size}</StyledTableCell>
                    <StyledTableCell align="left">{row.reference}</StyledTableCell>
                    <StyledTableCell align="left">{row.sku}</StyledTableCell>

                    <StyledTableCell align="left">
                      <IconButton onClick={() => handleDelete(row)}>
                        <Delete />
                      </IconButton>
                    </StyledTableCell>
                  </StyledTableRow>
                ))}
              </TableBody>
            </Table>
            <TablePagination
              rowsPerPageOptions={[10, 25, 100]}
              component="div"
              count={codeList.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </TableContainer>
        </Box>
      </Box>
    </>
  )
}
export default PickingConference
