import { useState, useContext, useEffect, useRef } from 'react'
import FileDownloadIcon from '@mui/icons-material/FileDownload'
import { Context } from '../../../context/AuthContext'
import Alert from '@mui/material/Alert'
import * as XLSX from 'xlsx'

import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Backdrop,
  Box,
  Button,
  Checkbox,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogTitle,
  FormControl,
  Grid,
  IconButton,
  InputLabel,
  Paper,
  Snackbar,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TextField,
  Tooltip,
  Typography,
  tableCellClasses,
  Chip,
} from '@mui/material'
import HelpIcon from '@mui/icons-material/Help'
import styled from '@emotion/styled'
import { colorTableDefault } from '../../TemplateDefault'
import { Add, DeleteForever, Edit, ExpandMore, Replay } from '@mui/icons-material'

import { createListProductService, createProductService } from '../../../services/productService'

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: colorTableDefault,
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}))

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  '&:last-child td, &:last-child th': {
    border: 0,
  },
}))

const UploadProductsNew = () => {
  const { subCompany, company, nameUrl } = useContext(Context)
  const [file, setFile] = useState(null)
  const [valueFile, setValueFile] = useState('')
  const token = localStorage.getItem('token')
  const [alerta, setAlerta] = useState(false)
  const [title, setTitle] = useState('')
  const [titleDialog, setTitleDialog] = useState('')
  const [severity, setSeverity] = useState('error')
  const [loading, setLoading] = useState(false)
  const [open, setOpen] = useState(false)
  const [expanded, setExpanded] = useState(false)
  const vertical = 'top'
  const horizontal = 'center'
  const [page, setPage] = useState(0)
  const [rowsPerPage, setRowsPerPage] = useState(10)
  const [statusMessage, setStatusMessage] = useState({})
  const [test1, setTest1] = useState({})
  const [test2, setTest2] = useState({})
  const [statusTable, setStatusTable] = useState(false)
  const [statusInfo, setStatusInfo] = useState(false)
  const [currentBatchIndex, setCurrentBatchIndex] = useState(0)
  const [progress, setProgress] = useState(0)

  const [dialogAction, setDialogAction] = useState(null)

  const [singleProd, setSingleProd] = useState({
    id: '',
    idProductPartner: '',
    name: '',
    description: '',
    price: '',
  })

  const [status, setStatus] = useState('Pronto')
  const [deleteId, setDeleteId] = useState('')
  const [search, setSearch] = useState('')
  const [selectedStatus, setSelectedStatus] = useState('')

  const [edit, setEdit] = useState(false)
  const [viewElearning, setViewElearning] = useState(false)
  const [itemExcel, setItemExceç] = useState([])
  const [itemProd, setItensProd] = useState([])
  const [selectedItems, setSelectedItems] = useState([])

  const [filter, setFilter] = useState([])
  const [selectAll, setSelectAll] = useState(false)

  const [sentProdsCount, setSentProdsCount] = useState(0)
  const [failedProds, setFailedProds] = useState([])
  const [numberItem, setNumberItem] = useState([])
  const [listOrderExit, setListOrderExist] = useState([])
  const [totaProdsSelectedCount, setTotaProdsSelectedCount] = useState(0)
  const ref = useRef()

  const handleChangePage = (event, newPage) => {
    setPage(newPage)
  }

  const handleChangeRowsPerPage = event => {
    setRowsPerPage(event.target.value)
    setPage(0)
    setSelectAll(event.target.value === 'All')
  }

  const handleItemSelect = itemId => {
    setSelectedItems(prevSelectedItems => {
      if (prevSelectedItems.includes(itemId)) {
        return prevSelectedItems.filter(id => id !== itemId)
      } else {
        return [...prevSelectedItems, itemId]
      }
    })
  }

  const handleSelectAll = event => {
    const isChecked = event.target.checked

    setSelectAll(isChecked)
    setSelectedItems(prevSelectedItems => {
      if (!selectAll) {
        return filter.map((item, index) => item.idProductPartner)
      } else {
        return []
      }
    })
    setRowsPerPage(isChecked ? filter.length + 1 : 10)
  }

  useEffect(() => {
    console.log(subCompany, 'subCOmpanu')
    if (selectedItems.length === filter.length) {
      setSelectAll(true)
    } else {
      setSelectAll(false)
    }
  }, [selectedItems, filter])

  useEffect(() => {}, [singleProd])

  useEffect(() => {}, [itemExcel, statusMessage])

  const handleSelectFile = e => {
    setLoading(true)
    setItensProd([])
    setValueFile(e.target.name[0])
    const selectedFile = e.target.files[0]

    if (!selectedFile) {
      setFile(null)
      return
    }
    setFile(selectedFile)
    //sendFile(selectedFile)
    readExcel(selectedFile)
  }

  function formatarMoedaBRL(valor) {
    const formatter = new Intl.NumberFormat('pt-BR', {
      style: 'currency',
      currency: 'BRL',
      minimumFractionDigits: 2,
    })

    return formatter.format(valor)
  }

  // Função atualizada para processar o Excel e mapear corretamente os campos exigidos pela API
  const readExcel = async file => {
    const reader = new FileReader()
    await new Promise(resolve => setTimeout(resolve, 1000))

    reader.onload = async e => {
      const data = e.target.result
      const workbook = XLSX.read(data, { type: 'binary' })
      const firstSheetName = workbook.SheetNames[0]
      const worksheet = workbook.Sheets[firstSheetName]

      const excelData = []
      const range = XLSX.utils.decode_range(worksheet['!ref'])

      for (let rowNum = range.s.r + 1; rowNum <= range.e.r; rowNum++) {
        const row = []
        let hasData = false

        for (let colNum = range.s.c; colNum <= range.e.c; colNum++) {
          const cellAddress = { c: colNum, r: rowNum }
          const cellRef = XLSX.utils.encode_cell(cellAddress)
          const cell = worksheet[cellRef]

          if (cell && cell.v !== undefined && cell.v !== null) {
            hasData = true
          }

          row.push(cell ? cell.v : undefined)
        }

        if (hasData) {
          excelData.push(row)
        }
      }

      const dataList = excelData.map(item => ({
        companyId: subCompany[0].companyId,
        active: true,
        name: item[0] !== undefined && item[0] !== null ? String(item[0]) : '-',
        description: item[1] !== undefined && item[1] !== null ? String(item[1]) : '-',
        codColor: item[5] !== undefined && item[5] !== null ? String(item[5]) : '0',
        color: item[3] !== undefined && item[3] !== null ? String(item[3]) : '-',
        barCode: item[4] !== undefined && item[4] !== null ? String(item[4]) : '0',
        idProductPartner: item[2] !== undefined && item[2] !== null ? String(item[2]) : '0',
        brand: item[6] !== undefined && item[6] !== null ? String(item[6]) : '-',
        supplier: item[7] !== undefined && item[7] !== null ? String(item[7]) : '-',
        picking: item[8] !== undefined && item[8] !== null ? String(item[8]) : '0',
        referencia: item[9] !== undefined && item[9] !== null ? String(item[9]) : '-',
        sku: item[10] !== undefined && item[10] !== null ? String(item[10]) : '0',
        stockActual: item[11] !== undefined && item[11] !== null ? String(item[11]) : '0',
        size: item[12] !== undefined && item[12] !== null ? String(item[12]) : '-',
        typeProduct: item[13] !== undefined && item[13] !== null ? String(item[13]) : '0',
        quantityTime: item[14] !== undefined && item[14] !== null ? String(item[14]) : '0',
        price: item[15] !== undefined && item[15] !== null ? String(item[15]) : '0',
        quantity: item[16] !== undefined && item[16] !== null ? String(item[16]) : '0',
        installment: item[17] !== undefined && item[17] !== null ? String(item[17]) : '0',
        country: item[18] !== undefined && item[18] !== null ? String(item[18]) : '-',
        subGroup: item[19]!== undefined && item[19] !== null ? String(item[19]) : '-',
        collection: item[20] !== undefined && item[20] !== null ? String(item[20]) : '-',
        department: item[21] !== undefined && item[21] !== null ? String(item[21]) : '-',

      }))

      console.log(dataList, 'dataList')
      setItensProd(dataList)
      setLoading(false)
      setValueFile('')
      resetFile()
    }

    reader.readAsBinaryString(file)
  }

  useEffect(() => {
    if (Array.isArray(test1) && Array.isArray(test2)) {
      const missingItems = test1.filter(
        item1 => !test2.some(item2 => item2.idProductPartner === item1.idProductPartner)
      )

      console.log(test1, ' teste1')
      console.log(missingItems, ' itens')
    }
  }, [test1, test2])

  useEffect(() => {
    console.log(test1, ' teste1')
  }, [])

  const resetFile = () => {
    ref.current.value = ''
  }

  const addToList = single => {
    const { id, idProductPartner, name, description, price } = single

    if (!idProductPartner || !name || !description || !price) {
      setStatus('Todos os campos são obrigatórios')
      return
    }

    const orderExists = itemProd.some(item => item.idProductPartner === idProductPartner)

    if (!edit && orderExists) {
      setStatus('Número de ordem já existe na lista')
      return
    }

    const repetido = itemProd.find(item => item.id === id)

    if (repetido) {
      const updatedClient = {
        ...repetido,
        idProductPartner,
        name,
        description,
        price,
      }

      const updatedIndex = itemProd.findIndex(item => item.id === id)

      if (updatedIndex !== -1) {
        const updatedItemClient = [...itemProd]
        updatedItemClient[updatedIndex] = updatedClient
        setItensProd(updatedItemClient)
      }

      setStatus('Cliente atualizado')
      resetFields()
      setExpanded(false)
    } else {
      if (!idProductPartner) {
        setStatus('Código inválido')
        return
      }

      const newClient = {
        id,
        idProductPartner,
        name,
        description,
        price,
      }

      setItensProd(curr => [...curr, newClient])
      setStatus('Cliente adicionado')
      resetFields()
      setExpanded(false)
    }
  }

  const resetFields = () => {
    setEdit(false)
    setSingleProd({
      idProductPartner: '',
      name: '',
      description: '',
      price: '',
    })
  }

  useEffect(() => {}, [itemProd])

  useEffect(() => {}, [numberItem])

  const handleGetEdit = single => {
    setEdit(true)
    setExpanded(true)
    setSingleProd({
      id: single.id,
      name: single.name,
      description: single.description,
      price: single.price,
      idProductPartner: single.idProductPartner,
    })
  }

  const handleActionList = action => {
    if (selectedItems.length === 0) {
      setSeverity('error')
      setTitle('Selecione um item')
      setAlerta(true)
    } else {
      setOpen(true)
      setTitleDialog('Tem certeza que enviar esses itens?')
      setDialogAction(action)
    }
  }

  const handleSaveUnity = async data => {
    setStatusTable(true)
    setSentProdsCount(0)
    setFailedProds([])

    try {
      const updatedFilter = filter.map(row => {
        if (row.idProductPartner === data.idProductPartner) {
          return {
            ...row,
            loading: true,
          }
        }
        return row
      })

      setFilter(updatedFilter)
      await new Promise(resolve => setTimeout(resolve, 1000))

      const response = await createProductService(token, nameUrl, data)

      const updatedFilterWithStatus = updatedFilter.map(row => {
        if (row.idProductPartner === data.idProductPartner) {
          return {
            ...row,
            loading: false,
            status: response.data.message,
          }
        }
        return row
      })
      setFilter(updatedFilterWithStatus)
      setSeverity('success')
      await new Promise(resolve => setTimeout(resolve, 1000))

      const updatedFilterWithoutSuccess = updatedFilterWithStatus.filter(
        row => row.idProductPartner !== data.idProductPartner
      )

      setSentProdsCount(prevSentProdsCount => prevSentProdsCount + 1)
      setFilter(updatedFilterWithoutSuccess)
    } catch (error) {
      const updatedFilter = filter.map(row => {
        if (row.idProductPartner === data.idProductPartner) {
          return {
            ...row,
            loading: false,
            status: 'Falha ao enviar, tente novamente!',
          }
        }
        return row
      })
      setSeverity('error')
      setFilter(updatedFilter)
      setFailedProds(prevSentProdsCount => prevSentProdsCount + 1)
    }
  }

  const handleSaveProductsSelected = async () => {
    setStatusTable(true)
    setSentProdsCount(0)
    setFailedProds([])

    const selectedProducts = itemProd.filter(prod => selectedItems.includes(prod.idProductPartner))

    for (const selectedProduct of selectedProducts) {
      try {
        const updatedFilter = filter.map(row => {
          if (row.idProductPartner === selectedProduct.idProductPartner) {
            return {
              ...row,
              loading: true,
            }
          }
          return row
        })

        setFilter(updatedFilter)
        await Promise.resolve()
        await new Promise(resolve => setTimeout(resolve, 1000))
        const response = await createProductService(token, nameUrl, selectedProduct)

        const updatedFilterWithStatus = updatedFilter.map(row => {
          if (row.idProductPartner === selectedProduct.idProductPartner) {
            return {
              ...row,
              loading: false,
              status: response.data.message,
            }
          }
          return row
        })

        setFilter(updatedFilterWithStatus)
        setSeverity('success')
        await new Promise(resolve => setTimeout(resolve, 1000))

        const updatedFilterWithoutSuccess = updatedFilterWithStatus.filter(
          row => row.idProductPartner !== selectedProduct.idProductPartner
        )

        setSentProdsCount(prevSentProdsCount => prevSentProdsCount + 1)

        setItensProd(prevItemProd =>
          prevItemProd.filter(item => item.idProductPartner !== selectedProduct.idProductPartner)
        )

        setFilter(updatedFilterWithoutSuccess)
      } catch (error) {
        const updatedFilter = filter.map(row => {
          if (row.idProductPartner === selectedProduct.idProductPartner) {
            return {
              ...row,
              loading: false,
              status: 'Falha ao enviar, tente novamente!',
            }
          }
          return row
        })

        setSeverity('error')
        setFilter(updatedFilter)
        setFailedProds(prevSentProdsCount => prevSentProdsCount + 1)
      }
    }

    setStatusTable(false)
  }

  const handleSaveProd = async () => {
    setStatusInfo(true)
    setStatusTable(true)
    setSentProdsCount(0)
    setFailedProds([])
    setProgress(0)

    const totalItems = itemProd.length
    const batchSize = 40 // Processar 40 itens por vez
    setNumberItem(totalItems)
    const numBatches = Math.ceil(totalItems / batchSize)
    let updatedFilter = [...itemProd]

    for (let batchIndex = 0; batchIndex < numBatches; batchIndex++) {
      try {
        await new Promise(resolve => setTimeout(resolve, 1000))
        setCurrentBatchIndex(batchIndex + 1)
        const batchStart = batchIndex * batchSize
        const batchEnd = Math.min((batchIndex + 1) * batchSize, totalItems)
        const batchProducts = itemProd.slice(batchStart, batchEnd)

        const newProgress = ((batchIndex + 1) / numBatches) * 100
        setProgress(newProgress)
        setFilter(prevFilter => {
          return prevFilter.map(row => {
            if (batchProducts.some(product => product.idProductPartner === row.idProductPartner)) {
              return {
                ...row,
                loading: true,
              }
            }
            return row
          })
        })

        await new Promise(resolve => setTimeout(resolve, 1000))
        try {
          const responses = await Promise.all(
            batchProducts.map(product => {
              const trimmedProduct = Object.fromEntries(
                Object.entries(product).map(([key, value]) => [
                  key,
                  typeof value === 'string' ? value.replace(/\s+/g, '') : value
                ])
              )
              return createProductService(token, nameUrl, trimmedProduct)
            })
          )

          setFilter(prevFilter => {
            return prevFilter.map(row => {
              if (
                batchProducts.some(product => product.idProductPartner === row.idProductPartner)
              ) {
                const response = responses.find(
                  response => response.data.idProductPartner === row.idProductPartner
                )
                return {
                  ...row,
                  loading: false,
                  status: response ? response.data.message : 'Erro no envio',
                }
              }
              return row
            })
          })

          setSeverity('success')
          await new Promise(resolve => setTimeout(resolve, 1000))

          updatedFilter = updatedFilter.filter(
            row => !batchProducts.some(product => product.idProductPartner === row.idProductPartner)
          )
          setFilter(updatedFilter)
          setSentProdsCount(prevSentProdsCount => prevSentProdsCount + batchProducts.length)
        } catch (error) {
          setFilter(prevFilter => {
            return prevFilter.map(row => {
              if (
                batchProducts.some(product => product.idProductPartner === row.idProductPartner)
              ) {
                return {
                  ...row,
                  loading: false,
                  status: 'Falha ao enviar, tente novamente!',
                }
              }
              return row
            })
          })
          setSeverity('error')
          setFilter(updatedFilter)
          setFailedProds(prevFailedProdsCount => prevFailedProdsCount + batchProducts.length)
        }
      } catch (error) {
        setFilter(prevFilter => {
          return prevFilter.map(row => {
            if (itemProd.some(product => product.idProductPartner === row.idProductPartner)) {
              return {
                ...row,
                loading: false,
                status: 'Falha ao enviar, tente novamente!',
              }
            }
            return row
          })
        })
        setSeverity('error')
        setFilter(updatedFilter)
        setFailedProds(prevFailedProdsCount => prevFailedProdsCount + itemProd.length)
      }
    }

    setStatusTable(false)
    setStatusInfo(false)
  }

  const deleteDialog = (clientId, del) => {
    setDeleteId(clientId)
    setOpen(true)
    setTitleDialog('Tem certeza que deseja excluir este item?')
    setDialogAction(del)
  }

  const handleDeleteItem = () => {
    const updatedItems = filter.filter(item => item.productId !== deleteId)
    setSeverity('success')
    setTitle('Item apagado com sucesso!')
    setAlerta(true)
    setItensProd(updatedItems)
    setOpen(false)
  }

  const handleClose = () => {
    setOpen(false)
    setAlerta(false)
  }

  const filterRow = () => {
    const filtered = itemProd.filter(
      row =>
        row.idProductPartner.toLowerCase().includes(search.toLowerCase()) ||
        row.description.toLowerCase().includes(search.toLowerCase()) ||
        row.barCode.toLowerCase().includes(search.toLowerCase())
    )
    setFilter(filtered)
  }

  useEffect(() => {
    filterRow()
  }, [search, itemProd])

  return (
    <>
      <Box sx={{ mt: 7.5 }}>
        {statusInfo && (
          <Backdrop
            sx={{ color: '#fff', zIndex: theme => theme.zIndex.drawer + 1 }}
            open={statusInfo}
          >
            <Box
              display="flex"
              alignItems="center"
              justifyContent="center"
              flexDirection="column"
              position="relative"
            >
              <CircularProgress value={progress} sx={{ color: colorTableDefault }} size={60} />
              <Box
                sx={{
                  position: 'absolute',
                  top: '26%',
                  left: '50%',
                  transform: 'translate(-50%, -50%)',
                }}
              >
                <Typography>{Math.round(progress)}%</Typography>
              </Box>
              <Box textAlign="center" p={2}>
                {selectedItems.length > 0 ? (
                  <Typography>
                    {totaProdsSelectedCount.toLocaleString()} de {Math.floor(sentProdsCount)} Itens
                    Enviados...
                  </Typography>
                ) : (
                  <Typography>
                    {numberItem.toLocaleString()} de {sentProdsCount.toLocaleString()} Produtos
                    Enviadas...
                  </Typography>
                )}
              </Box>
            </Box>
          </Backdrop>
        )}
        <Dialog
          open={open}
          onClose={handleClose}
          aria-describedby="alert-dialog-slide-description"
          sx={{ textAlign: 'center' }}
        >
          <DialogTitle>{titleDialog}</DialogTitle>
          <DialogActions>
            <Button onClick={handleClose}>Cancelar</Button>
            <Button
              onClick={() => {
                handleClose()
                if (dialogAction === 'delete') {
                  handleDeleteItem()
                } else if (dialogAction === 'save') {
                  handleSaveProductsSelected()
                }
                setDialogAction(null)
              }}
            >
              Sim
            </Button>
          </DialogActions>
        </Dialog>
        <Snackbar
          anchorOrigin={{ vertical, horizontal }}
          open={alerta}
          onClose={handleClose}
          key={vertical + horizontal}
        >
          <Alert onClose={handleClose} severity={severity} sx={{ width: '100%' }}>
            {title}
          </Alert>
        </Snackbar>

        <Box sx={{ width: 1, display: 'flex', justifyContent: 'space-between' }}>
          <Typography variant="h5">Carga de Produto</Typography>
          <IconButton sx={{ justifySelf: 'flex-end' }} onClick={() => setViewElearning(true)}>
            <HelpIcon />
          </IconButton>
        </Box>
        <Accordion expanded={expanded} sx={{ mt: 2 }}>
          <AccordionSummary
            expandIcon={<ExpandMore />}
            aria-controls="panel1a-content"
            id="panel1a-header"
            onClick={() => setExpanded(!expanded)}
          >
            <Typography variant="body1">{edit ? 'Editar Produto' : 'Cadastrar Produto'}</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Box
              sx={{
                width: 1,
                mt: 2,
                display: 'flex',
                gap: 2,
                border: '1px solid black',
                borderRadius: 2,
                p: 2,
                flexDirection: 'column',
              }}
            >
              <Grid container spacing={2}>
                <Grid item xs={12} md={4}>
                  <FormControl sx={{ width: '100%' }}>
                    <TextField
                      sx={{ width: '100%' }}
                      value={singleProd.idProductPartner}
                      id="outlined-basic"
                      variant="outlined"
                      type="text"
                      label="Codigo do Produto"
                      onChange={e =>
                        setSingleProd({ ...singleProd, idProductPartner: e.target.value })
                      }
                    />
                  </FormControl>
                </Grid>
                <Grid item xs={12} md={4}>
                  <FormControl sx={{ width: '100%' }}>
                    <TextField
                      sx={{ width: '100%' }}
                      value={singleProd.name}
                      id="outlined-basic"
                      variant="outlined"
                      type="text"
                      label="Nome"
                      onChange={e => setSingleProd({ ...singleProd, name: e.target.value })}
                    />
                  </FormControl>
                </Grid>
                <Grid item xs={12} md={4}>
                  <FormControl sx={{ width: 1 }}>
                    <TextField
                      sx={{ width: '100%' }}
                      value={singleProd.description}
                      id="outlined-basic"
                      variant="outlined"
                      type="text"
                      label="Descriçáo"
                      onChange={e => setSingleProd({ ...singleProd, description: e.target.value })}
                    />
                  </FormControl>
                </Grid>
                <Grid item xs={12} md={4}>
                  <FormControl sx={{ width: 1 }}>
                    <TextField
                      sx={{ width: '100%' }}
                      value={singleProd.price}
                      id="outlined-basic"
                      variant="outlined"
                      type="text"
                      label="Preço"
                      onChange={e => {
                        setSingleProd({ ...singleProd, price: e.target.value })
                      }}
                    />
                  </FormControl>
                </Grid>
                <Grid
                  item
                  xs={12}
                  sx={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end' }}
                >
                  <Button variant="outlined" onClick={() => addToList(singleProd)}>
                    {edit ? 'Atualizar Cliente' : 'Adicionar à lista'}
                  </Button>
                  {edit && (
                    <Button
                      variant="contained"
                      sx={{ ml: 1, display: 'flex', alignItems: 'center' }}
                      onClick={() => {
                        resetFields()
                      }}
                    >
                      Adicionar novo cliente <Add sx={{ ml: 1 }} />
                    </Button>
                  )}
                </Grid>
              </Grid>
            </Box>
          </AccordionDetails>
        </Accordion>
        <Box
          sx={{ justifyContent: 'space-between', width: 1, minWidth: 1 }}
          display={{ xs: 'column', sm: 'column', md: 'flex', lg: 'flex', xl: 'flex' }}
        >
          <Box
            sx={{
              alignItems: 'center',
              textAlign: 'center',
              mt: 2,
              border: '1px solid black',
              width: { xs: 1, md: 0.34 },
              minWidth: { xs: 1, md: 0.3 },
              p: 2,
            }}
          >
            <a
              className="dropzone"
              target="_blank"
              rel="noreferrer"
              href={
                company.companyId === '4eeae559-76c0-4fc7-8d63-08242930d72f'
                  ? 'https://file.bihands.com/template/UploadProducNew.xlsx'
                  : 'https://file.bihands.com/template/UploadProducNew.xlsx'
              }
            >
              <FileDownloadIcon sx={{ fontSize: 100 }} />
            </a>
            <InputLabel>Faça o download do exemplo de carga</InputLabel>
          </Box>
          <Box
            sx={{
              mt: 2,
              border: { md: '1px solid black' },
              p: 2,
            }}
            width={{ xs: '100%', sm: '100%', md: '65%', lg: '65%', xl: '65%' }}
          >
            <InputLabel>Insira aqui o documento para enviar</InputLabel>
            <TextField
              sx={{ width: '100%', mt: 1 }}
              id="standard-basic"
              variant="standard"
              value={valueFile}
              onChange={handleSelectFile}
              ref={ref}
              type="file"
              accept=".xls, .xlsx, .ods"
            />
            <Box
              sx={{
                mt: 4,
                alignItems: 'center',
                textAlign: 'center',
                display: 'flex',
                justifyContent: 'space-between',
              }}
            >
              <Typography>Apenas arquivos .XLS ou .XLSX</Typography>
            </Box>
          </Box>
        </Box>

        <Box>
          <TableContainer component={Paper} sx={{ mt: 2 }}>
            <Box>
              <Grid container spacing={3}>
                <Grid item xs={3}>
                  <TextField
                    sx={{ width: '100%', mb: 1 }}
                    id="standard-basic"
                    variant="outlined"
                    label="Procurar"
                    value={search}
                    onChange={e => setSearch(e.target.value)}
                    type="text"
                  />
                </Grid>
              </Grid>

              <Box display="flex" justifyContent="space-between" alignItems="end">
                <Box>
                  <Typography variant="body" sx={{ marginBottom: 2, mr: 0 }}>
                    {filter.length <= 1
                      ? `${filter.length} Resultado`
                      : `${filter.length} Resultados`}
                  </Typography>
                  {selectedItems.length > 0 && (
                    <Typography variant="body" sx={{ marginBottom: 2, ml: 2 }}>
                      {selectedItems.length <= 1
                        ? `${selectedItems.length} Item Selecionado`
                        : `${selectedItems.length} Itens Selecionados`}
                    </Typography>
                  )}
                  {statusTable && (
                    <>
                      <Typography variant="body" sx={{ marginBottom: 2, ml: 2 }}>
                        {listOrderExit.length > 0 && `${listOrderExit.length} ordens existentes`}
                      </Typography>
                    </>
                  )}
                  {statusTable && (
                    <>
                      <Typography variant="body" sx={{ marginBottom: 2, ml: 2 }}>
                        {sentProdsCount <= 1
                          ? `${sentProdsCount} Itens Enviado`
                          : `${sentProdsCount} Itens Enviados`}
                      </Typography>
                      <Typography variant="body" sx={{ marginBottom: 2, ml: 2 }}>
                        {failedProds.length > 1
                          ? `${failedProds.length} Itens não enviados`
                          : `${failedProds.length} Iten não enviados`}
                      </Typography>
                    </>
                  )}
                </Box>
                {selectedItems.length > 0 ? (
                  <Button
                    variant="contained"
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: ' space-between',
                      fontSize: '.9em',
                    }}
                    onClick={() => handleActionList('save')}
                  >
                    Enviar Selecionados
                  </Button>
                ) : (
                  <Button
                    variant="contained"
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: ' space-between',
                      fontSize: '.9em',
                    }}
                    onClick={() => handleSaveProd()}
                  >
                    Enviar Lista
                  </Button>
                )}
              </Box>
            </Box>

            <Table sx={{ minWidth: 700, mt: 1 }} aria-label="customized table">
              <TableHead>
                <TableRow>
                  <StyledTableCell align="center">
                    <Box display="flex" flexDirection="column" alignItems="center">
                      <Tooltip title="Selecionar Todos" arrow>
                        <span>
                          <Checkbox
                            checked={selectAll}
                            onChange={handleSelectAll}
                            color="primary"
                          />
                        </span>
                      </Tooltip>
                    </Box>
                  </StyledTableCell>
                  <StyledTableCell align="center" width="10%">
                   Editar
                  </StyledTableCell>
                  <StyledTableCell align="center" width="10%">
                   Deletar
                  </StyledTableCell>
                  
                  <StyledTableCell align="center">Nome</StyledTableCell>
                  <StyledTableCell align="center">Descrição</StyledTableCell>
                  <StyledTableCell align="center">Codigo do Produto</StyledTableCell>
                  <StyledTableCell align="center">Cor</StyledTableCell>
                  <StyledTableCell align="center">Codigo de barras</StyledTableCell>
                  <StyledTableCell align="center">Codigo da cor</StyledTableCell>
                  <StyledTableCell align="center">Marca</StyledTableCell>
                  <StyledTableCell align="center">Fabricante</StyledTableCell>
                  <StyledTableCell align="center">Posição Baixa</StyledTableCell>
                  <StyledTableCell align="center">Referência</StyledTableCell>
                  <StyledTableCell align="center">Sku</StyledTableCell>
                  <StyledTableCell align="center">Estoque atual</StyledTableCell>
                  <StyledTableCell align="center">Tamanho</StyledTableCell>
                  <StyledTableCell align="center">Tipo</StyledTableCell>
                  <StyledTableCell align="center">Quantidade Tempo</StyledTableCell>
                  <StyledTableCell align="center">Preço</StyledTableCell>
                  <StyledTableCell align="center">Qtde/Cx embarque</StyledTableCell>
                  <StyledTableCell align="center">Parcelamento</StyledTableCell>
                  <StyledTableCell align="center">País</StyledTableCell>

                  
                  <StyledTableCell align="center">Sub grupo/características</StyledTableCell>
                  <StyledTableCell align="center">Coleção</StyledTableCell>
                  <StyledTableCell align="center">Departamento/Tipo                  </StyledTableCell>
                  {statusTable && <StyledTableCell align="center">Status</StyledTableCell>}

                </TableRow>
              </TableHead>
              <TableBody>
                {filter
                  .filter(
                    row => selectedStatus === '' || statusMessage[row.productId] === selectedStatus
                  )
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((row, index) => (
                    <StyledTableRow key={index}>
                      <StyledTableCell align="center">
                        <Checkbox
                          checked={selectedItems.includes(row.idProductPartner)}
                          onChange={() => handleItemSelect(row.idProductPartner)}
                        />
                      </StyledTableCell>
                      <StyledTableCell align="center">
                        <Box display="flex" alignItems="center" justifyContent="space-between">



                          <Tooltip title="Editar" arrow>
                            <IconButton onClick={() => handleGetEdit(row)}>
                              <Edit sx={{ cursor: 'pointer' }} />
                            </IconButton>
                          </Tooltip>
                        </Box>
                      </StyledTableCell>
                      <StyledTableCell align="center">
                        <Box display="flex" alignItems="center" justifyContent="space-between">


                          <Tooltip title="Apagar" arrow>
                            <IconButton onClick={() => deleteDialog(row.codClient, 'delete')}>
                              <DeleteForever sx={{ cursor: 'pointer' }} />
                            </IconButton>
                          </Tooltip>


                        </Box>
                      </StyledTableCell>
                      <StyledTableCell align="center">{row.name}</StyledTableCell>
                      <StyledTableCell align="center">{row.description}</StyledTableCell>
                      <StyledTableCell align="center">{row.idProductPartner}</StyledTableCell>
                      <StyledTableCell align="center">{row.color}</StyledTableCell>
                      <StyledTableCell align="center">{row.barCode}</StyledTableCell>
                      <StyledTableCell align="center">{row.codColor}</StyledTableCell>
                      <StyledTableCell align="center">{row.brand}</StyledTableCell>
                      <StyledTableCell align="center">{row.supplier}</StyledTableCell>
                      <StyledTableCell align="center">{row.picking}</StyledTableCell>
                      <StyledTableCell align="center">{row.referencia}</StyledTableCell>
                      <StyledTableCell align="center">{row.sku}</StyledTableCell>
                      <StyledTableCell align="center">{row.stockActual}</StyledTableCell>
                      <StyledTableCell align="center">{row.size}</StyledTableCell>
                      <StyledTableCell align="center">{row.typeProduct}</StyledTableCell>
                      <StyledTableCell align="center">{row.quantityTime}</StyledTableCell>
                      <StyledTableCell align="center">
                        {formatarMoedaBRL(row.price) || '0'}
                      </StyledTableCell>
                      <StyledTableCell align="center">{row.quantity}</StyledTableCell>
                      <StyledTableCell align="center">{row.installment}</StyledTableCell>
                      <StyledTableCell align="center">{row.country}</StyledTableCell>
                      <StyledTableCell align="center">{row.subGroup}</StyledTableCell>
                      <StyledTableCell align="center">{row.collection}</StyledTableCell>
                      <StyledTableCell align="center">{row.department}</StyledTableCell>
                      {statusTable && (
                        <StyledTableCell align="center">
                          {row.loading ? (
                            <CircularProgress sx={{ color: colorTableDefault }} size={20} />
                          ) : (
                            row.status && <Chip color={severity} label={row.status} />
                          )}
                        </StyledTableCell>
                      )}


                    
                      
                     
                    </StyledTableRow>
                  ))}
              </TableBody>
            </Table>
            <TablePagination
              rowsPerPageOptions={[10, 25, 100]}
              component="div"
              count={filter.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </TableContainer>
        </Box>

        <Backdrop sx={{ color: '#fff', zIndex: theme => theme.zIndex.drawer + 1 }} open={loading}>
          <CircularProgress color="inherit" />
        </Backdrop>
      </Box>
    </>
  )
}

export default UploadProductsNew

