import Backdrop from '@mui/material/Backdrop'
import CircularProgress from '@mui/material/CircularProgress'
import React, { useContext, useState, useEffect, useCallback } from 'react'
import { Routes as BrowserRouter, Link, Navigate, Route } from 'react-router-dom'
import { setPermission } from '../permissions/userPermissions'
import { ErpIntegracao } from '../const/RoleConstants'

import Home from '../components/Home'
import IntegrationProcess from '../components/IntegrationProcess'
import LoginForm from '../components/LoginForm'
import Company from '../components/View/Company'
import EmailParameters from '../components/View/EmailParameters'
import IntegrationDefault from '../components/View/IntegrationDefault'
import Parameters from '../components/View/Parameters'
import ParametersGroup from '../components/View/ParametersGroup'
import Plan from '../components/View/Plan'
// import Product from "../components/View/Product";
import CompanyCreate from '../components/Create/CompanyCreate'
import EmailParametersCreate from '../components/Create/EmailParametersCreate'
import EmployeeCreate from '../components/Create/EmployeeCreate'
import EquipmentCreate from '../components/Create/EquipmentCreate'
import ExpeditionOrderCreate from '../components/Create/ExpeditionOrderCreate'
import IntegrationCredencialCreate from '../components/Create/IntegrationCredencialCreate'
import IntegrationDefaultCreate from '../components/Create/IntegrationDefaultCreate'
import MonitorCreate from '../components/Create/MonitorCreate'
import ParametersCreate from '../components/Create/ParametersCreate'
import ParametersGroupCreate from '../components/Create/ParametersGroupCreate'
import PlanCreate from '../components/Create/PlanCreate'
import PrinterCreate from '../components/Create/PrinterCreate'
import PrinterZplCreate from '../components/Create/PrinterZplCreate'
import ProductAssociationCreate from '../components/Create/ProductAssociationCreate'
import ProductionReport from '../components/View/ProductionReport'
import ReasonForOrderCreate from '../components/Create/ReasonForOrderCreate'
import RoleCreate from '../components/Create/RoleCreate'
import SegmentCreate from '../components/Create/SegmentCreate'
import SegmentGroupCreate from '../components/Create/SegmentGroupCreate'
import SubCompanyCreate from '../components/Create/SubCompanyCreate'
import TransferOrderCreate from '../components/Create/TransferOrderCreate'
import UpdateCreate from '../components/Create/UpdateCreate'
import CompanyDelete from '../components/Delete/CompanyDelete'
import EmailParametersDelete from '../components/Delete/EmailParametersDelete'
import EmployeeDelete from '../components/Delete/EmployeeDelete'
import EquipmentDelete from '../components/Delete/EquipmentDelete'
import IntegrationDefaultDelete from '../components/Delete/IntegrationDefaultDelete'
import MonitorDelete from '../components/Delete/MonitorDelete'
import ParametersDelete from '../components/Delete/ParametersDelete'
import ParametersGroupDelete from '../components/Delete/ParametersGroupDelete'
import PrinterDelete from '../components/Delete/PrinterDelete'
import PrinterZplDelete from '../components/Delete/PrinterZplDelete'
import ProductAssociationDelete from '../components/Delete/ProductAssociationDelete'
import ReasonForOrderDelete from '../components/Delete/ReasonForOrderDelete'
import RoleDelete from '../components/Delete/RoleDelete'
import SegmentDelete from '../components/Delete/SegmentDelete'
import SegmentGroupDelete from '../components/Delete/SegmentGroupDelete'
import SegmentGroupRoleDelete from '../components/Delete/SegmentGroupRoleDelete'
import SubCompanyDelete from '../components/Delete/SubCompanyDelete'
import CompanyEdit from '../components/Edit/CompanyEdit'
import EmailParametersEdit from '../components/Edit/EmailParametersEdit'
import EmployeeEdit from '../components/Edit/EmployeeEdit'
import EquipmentEdit from '../components/Edit/EquipmentEdit'
import ExpeditionOrderItem from '../components/Edit/ExpeditionOrderItem'
import IntegrationCredencialEdit from '../components/Edit/IntegrationCredencialEdit'
import IntegrationDefaultEdit from '../components/Edit/IntegrationDefaultEdit'
import MonitorEdit from '../components/Edit/MonitorEdit'
import ParametersEdit from '../components/Edit/ParametersEdit'
import ParametersGroupEdit from '../components/Edit/ParametersGroupEdit'
import PrinterEdit from '../components/Edit/PrinterEdit'
import PrinterZplEdit from '../components/Edit/PrinterZplEdit'
import ProductAssociationEdit from '../components/Edit/ProductAssociationEdit'
import ReasonForOrderEdit from '../components/Edit/ReasonForOrderEdit'
import RoleEdit from '../components/Edit/RoleEdit'
import SegmentEdit from '../components/Edit/SegmentEdit'
import SegmentGroupEdit from '../components/Edit/SegmentGroupEdit'
import SubCompanyEdit from '../components/Edit/SubCompanyEdit'
import TransferOrderItem from '../components/Edit/TransferOrderItem'
import TemplateDefault from '../components/TemplateDefault'
import Employee from '../components/View/Employee'
import EmployeeRole from '../components/View/EmployeeRole'
import Equipment from '../components/View/Equipment'
import ExpeditionOrder from '../components/View/ExpeditionOrder'
import IntegrationCredencial from '../components/View/IntegrationCredencial'
import MonitoringEquipmentAutomatic from '../components/View/MonitoringEquipmentAutomatic'
import Printer from '../components/View/Printer'
import PrinterZpl from '../components/View/PrinterZpl'
import Product from '../components/View/Product'
import ProductAssociation from '../components/View/ProductAssociation'
import ReasonForOrder from '../components/View/ReasonForOrder'
import ReportCountStock from '../components/View/ReportCountStock'
import ReportInventory from '../components/View/ReportInventory'
import ReportExtractCountStock from '../components/View/ReportExtractCountStock'
import ReportPurchase from '../components/View/ReportPurchase'
import Role from '../components/View/Role'
import Segment from '../components/View/Segment'
import SegmentGroup from '../components/View/SegmentGroup'
import SegmentGroupRole from '../components/View/RulesCategory'
import SubCompany from '../components/View/SubCompany'
import TransferOrder from '../components/View/TransferOrder'
import Update from '../components/View/Update'
import { Context } from '../context/AuthContext'

import AlocationStockCreate from '../components/Create/AlocationStockCreate'
import AlocationStockEdit from '../components/Edit/AlocationStockEdit'
import AlocationStock from '../components/View/AlocationStock'
import MovimentationBreak from '../components/View/MovimentationBreak'
import UploadProducts from '../components/View/UploadProducts'

import CountStockOrderCreate from '../components/Create/CountStockOrderCreate'
import LabelOrderCreate from '../components/Create/LabelOrderCreate'
import OutStockOrderCreate from '../components/Create/OutStockOrder'
import StockEntryOrderCreate from '../components/Create/StockEntryOrderCreate'
import MovimentationOrderDelete from '../components/Delete/MovimentationOrderDelete'
import CountStockOrderEdit from '../components/Edit/CountStockOrderEdit'
import LabelOrderEdit from '../components/Edit/LabelOrderEdit'
import OutStockOrderEdit from '../components/Edit/OutStockOrderEdit'
import StockEntryOrderEdit from '../components/Edit/StockEntryOrderEdit'
import ForgotPassword from '../components/ForgotPassword'
import CountStockOrder from '../components/View/CountStockOrder'
import LabelOrder from '../components/View/LabelOrder'
import OutStockOrder from '../components/View/OutStockOrder'
import StockEntryOrder from '../components/View/StockEntryOrder'
// import NewUsersPassword from "../components/NewUsersPassword";
import PlanEdit from '../components/Edit/PlanEdit'
import ResetPassword from '../components/ResetPassword'
// import ZPLPrint from "../components/Edit/ZPLPrint";
import AntiTheftReports from '../components/View/AntiTheftReports'
import LinkProduct from '../components/View/LinkProduct'
import PickToLight from '../components/View/PickToLight'
import ReportAccess from '../components/View/ReportAccess'
import ReportDissassociation from '../components/View/ReportDissassociation'

import FaccaoCreate from '../components/Create/FaccaoCreate'
import FactionEdit from '../components/Edit/FactionEdit'
import ReportFaccao from '../components/View/ReportFaccao'

import DepositCreate from '../components/Create/DepositCreate'
import LaundryCreate from '../components/Create/LaundryCreate'
import PrinterZPLDefaultCreate from '../components/Create/PrinterZPLDefaultCreate'
import DepositEdit from '../components/Edit/DepositEdit'
import LaundryEdit from '../components/Edit/LaundryEdit'
import PrinterZPLDefaultEdit from '../components/Edit/PrinterZPLDefaultEdit'
import Deposit from '../components/View/Deposit'
import PrinterLaundry from '../components/View/PrinterLaundry'
import PrinterZPLDefault from '../components/View/PrinterZPLDefault'
import UploadConferenceParts from '../components/View/UploadConferenceParts'
// import DepositDelete from "../components/Delete/DepositDelete";
import MovimentationBreakCreate from '../components/Create/MovimentationBreakCreate'
import ZPLRefCreate from '../components/Create/ZPLRefCreate'
import FactionDelete from '../components/Delete/FactionDelete'
import IntegrationCredencialDelete from '../components/Delete/IntegrationCredencialDelete'
import PlanDelete from '../components/Delete/PlanDelete'
import PrinterZPLDefaultDelete from '../components/Delete/PrinterZPLDefaultDelete'
import ZPLRefDelete from '../components/Delete/ZPLRefDelete'
import MovimentationBreakEdit from '../components/Edit/MovimentationBreakEdit'
import RouteEditor from '../components/Edit/MovimentationRouteEdit/RouteEditor'
import PickingListEdit from '../components/Edit/PickingListEdit'
import ZPLRefEdit from '../components/Edit/ZPLRefEdit'
import NewUsersPassword from '../components/NewUsersPassword'
import CarWeight from '../components/View/CarWeight'
import Driver from '../components/View/Driver'
import Elearning from '../components/View/Elearning'
import IntegrationAssociation from '../components/View/IntegrationAssociation'
import ListDriver from '../components/View/ListDriver'
import LoteReport from '../components/View/LoteReport'
import MovBreakKanban from '../components/View/MovimentationBreak/Kanbam'
import MovimentationOrderRoute from '../components/View/MovimentationRoute'
import NotaFiscal from '../components/View/NotaFiscal'
import PickingList from '../components/View/PickingList'
import Process from '../components/View/Process'
import Profile from '../components/View/Profile'
import QuantityBox from '../components/View/QuantityBox'
import ReportAlocationParts from '../components/View/ReportAlocationParts'
import ReportConference from '../components/View/ReportConference'
import ReportCurrentInventory from '../components/View/ReportCurrentInventory'
import ReportEtiqueta from '../components/View/ReportEtiqueta'
import ReportInventoryMovement from '../components/View/ReportInvetoryMovement'
import ReportLocation from '../components/View/ReportLocation'
import ReportPicking from '../components/View/ReportPicking'
import ReportPosition from '../components/View/ReportPosition'
import ReportPositionFree from '../components/View/ReportPositionFree'
import MyCompany from '../components/View/MyCompany'
import ReportProductsActive from '../components/View/ReportProductsActive'
import SalesOrder from '../components/View/SalesOrder'
import TimeExpiration from '../components/View/TimeExpiration'
import TransferBetweenLocations from '../components/View/TransferBetweenLocations'
import ZPLRef from '../components/View/ZPLRef'
import PalletBlock from '../components/View/PalletBlock'
import ReceiveOrderPicking from '../components/View/ReceiveOrderPicking'
import ReportLoteProduct from '../components/View/ReportLoteProduct'
import ReportProductPickingNotFound from '../components/View/ReportProductPickingNotFound'
import ReportBlockedProducts from '../components/View/ReportBlockedProducts'
import ReportMovimentationStockByProduct from '../components/View/ReportMovimentationStockByProduct'
import DasboardStock from '../components/DashboardStock'
import EmailGroup from '../components/View/EmailGroup'
import RoadMap from '../components/View/RoadMap'
import EquipmentSold from '../components/View/EquipmentSold'
import { FunctionsPositions } from '../components/View/FunctionsPositions'
import { ListUser } from '../components/View/ListUser'
import { PermissionsGroup } from '../components/View/PermissionsGroup'
import { CountStockDesk } from '../components/View/CountStockDesk'
import { NewSaleOrder } from '../components/View/NewSaleOrder'
import UpdateProduction from '../components/View/UpdateProduction'
import UploadClient from '../components/View/UploadClient'
import Client from '../components/View/Client'
import TemplateLabel from '../components/View/TemplateLabel'
import UpdateMovimentation from '../components/View/UpdateMovimentation'
import TagLogistics from '../components/View/TagLogistics'
import Rules from '../components/View/Rules'
import RulesCategory from '../components/View/RulesCategory'
import UploadProductsNew from '../components/View/UploadProductsNew'
import { PermissionsUser } from '../components/View/PermissionsUser'
import { PrivateRoute } from './PrivateRoute'
import { PageAuthorization } from '../components/PageAuthorization'
import PrintTagSerialId from '../components/View/PrintTagSerialId'
import PrintTagSerial from '../components/View/PrintTagSerial'
import ReportMovimentationHistoryByProduct from '../components/View/ReportMovimentationHistoryByProduct'
import ReceiveManualEntry from '../components/View/ReceiveManualEntry'
import { DahsboardHelpe, Helpdesk } from '../components/View/Helpdesk'
import { Ticket } from '../components/View/Ticket'
import { TicketSuport } from '../components/View/TicketSuport'
import { Attendants } from '../components/View/Attendants'
import { PageIntegrationSuccess } from '../components/PageIntegrationSuccess'
import { Order } from '../components/View/Order'
import { Onboarding } from '../components/View/Onboarding'
import TemplateEquipament from '../components/View/TemplateEquipament'
import TemplateTags from '../components/View/TemplateTags'
import EquipmentDetailsLog from '../components/View/Equipment/Components'
import { DetailsOnboarding } from '../components/View/DetailsOnboarding'
import MovimentationReturn from '../components/View/MovimentationReturn'
import Rejeito from '../components/View/Rejeito'
import TagReprocessing from '../components/Create/TagReprocessing'
import Log from '../components/View/Log'
import UploadAlocationStock from '../components/View/UploadAlocationStock'
import DasboardBobinas from '../components/DashboardBelgo'
import DuplicateRemover from '../components/View/DuplicateRemover'
import Association from '../components/View/Association'
import UploadMovimentationAlocation from '../components/View/UploadMovimentationAlocation'
import { TicketViewResponse } from '../components/View/TicketSuport/Components/TicketViewReponse'
import { TicketCreate } from '../components/Create/TicketCreate'
import { TicketCreatePage } from '../components/Create/TicketCreate/TicketCreatePage'
import { TicketViewReponseClient } from '../components/View/TicketSuport/Components/TicketViewReponseClient'
import { DashboardSales } from '../components/View/DashboardSales'
import ChargerDashboardVendor from '../components/View/ChargerDashboardVendor'
import { PermissionsUserGroup } from '../components/View/PermissionsUserGroup'
import AntiTheftReportsProduct from '../components/View/AntiTheftReportsProduct'
import ChargerDashboardRouting from '../components/View/ChargerDashboardRouting'
import ChargerDashboardClient from '../components/View/ChargerDashboardClient'
import ChargerDashboardSeller from '../components/View/ChargerDashboardSeller'
import ChargerDashboardUploadBillstopay from '../components/View/ChargerDashboardUploadBillstopay'
import MovimentationType from '../components/View/MovimentationType'
import PrintReference from '../components/View/PrintReference'
import PrintOrder from '../components/View/PrintOrder'
import AssociationBarCode from '../components/View/AssociationBarCode'
import Disassociate from '../components/View/Disassociate'
import NewSampleControl from '../components/View/NewSampleControl'
import StockAudit from '../components/View/StockAudit'
import UploadQuantity from '../components/View/UploadQuantity'
import ReportNewSampleControl from '../components/View/ReportNewSampleControl'
import LogClient from '../components/View/LogClient'
import { CountStockTransferOrder } from '../components/View/CountStockTransferOrder'
import { CountOnline } from '../components/View/CountOnline'
import AssociationBarCodeClient from '../components/View/AssociationBarCodeClient'
import ChargerAssociationProduct from '../components/View/ChargerAssociationProduct'
import ChargerDeposit from '../components/View/ChargerDeposit'
import ChargerMovimentation from '../components/View/ChargerMovimentation'
import ChargerAlocation from '../components/View/ChargerAlocation'
import DashboardMock from '../components/DashboardMock'
import UploadSerial from '../components/View/UploadSerial'
import UploadSubCompany from '../components/View/UploadSubCompany'
import LabelOrderClient from '../components/View/LabelOrderClient'
import LabelOrderClientCreate from '../components/Create/LabelOrderClientCreate'
import CompanyList from '../components/View/CompanyList'
import UploadStcokActual from '../components/View/UploadStcokActual'
import ReportCountStockConsolid from '../components/View/ReportCountStockConsolid'
import ReportCountActive from '../components/View/ReportCountActive'
import UploadDeleteProducts from '../components/View/UploadDeleteProducts'
import UploadCreateProducts from '../components/View/UploadCreateProducts'
import ProductClient from '../components/View/ProductClient'
import SensorAndPerson from '../components/View/SensorAndPerson'
import GatewayLocation from '../components/View/GatewayLocation'
import ReportPeople from '../components/View/ReportPeople'
import DashboardRTLS from '../components/DashboardRTLS'
import ReportMovimentationStockInfo from '../components/View/ReportMovimentationStockInfo'
import Gpt from '../components/View/Gpt'
import CountStockCategory from '../components/View/CountStockCategory'
import AssociationSerials from '../components/View/AssociationSerials'
import ReportCountStockWms from '../components/View/ReportCountStockWms'
import ReportConferenceActions from '../components/View/ReportConferenceActions'
import DashboardSebrae from '../components/DashboardSebrae'
import UploadDepreciation from '../components/View/UploadDepreciation'
import Pdv from '../components/View/Pdv'
import InventoryClient from '../components/View/InventoryClient'
import ConferenceClient from '../components/View/ConferenceClient'
import CountStockClient from '../components/Create/ConferenceClientForm/components'
import PdvClient from '../components/View/PdvClient'
import MachineReceipt from '../components/View/MachineReceipt'
import UploadImage from '../components/UploadImage'
import ChargeRecipeMachine from '../components/View/ChargeRecipeMachine'
import PhotoReport from '../components/View/PhotoReport'
import ListReading from '../components/View/ListReading'
import CountEntryStock from '../components/View/CountEntryStock'
import CountStock from '../components/View/CountStock'
import CountStockOut from '../components/View/CountStockOut'
import TransferBetweeFnBranches from '../components/View/TransferBetweenBranches'
import TransferBetweenAlocations from '../components/View/TransferBetweenAlocations'
import PickingConference from '../components/View/PickingConference'
import MachineRecipe from '../components/View/MachineRecipe'
import TransferBetweenBranches from '../components/View/TransferBetweenBranches'

const AppRoutes = () => {
  const { authenticated, loading, userRolesNew, nameUrl } = useContext(Context)

  const [permissionIntegrationProcess, setPermissionIntegrationProcess] = useState(false)
  const [permissionIntegrationSebrae, setPermissionIntegrationSebrae] = useState(false)
  const [permissionRTLS, setPermissionRTLS] = useState(false)

  const [loaded, setLoaded] = useState(false)

  useEffect(() => {
    const checkRoles = async () => {
      const dashboardSebrae = userRolesNew.some(role => role.rolesName === 'View_DashboardSebrae')
      const hasERPIntegrationRole = userRolesNew.some(role => role.rolesName === 'ERP_INTEGRACAO')
      const hasRTLSRole = userRolesNew.some(role => role.rolesName === 'RTLS')

      setPermissionIntegrationSebrae(dashboardSebrae)
      setPermissionIntegrationProcess(hasERPIntegrationRole)
      setPermissionRTLS(hasRTLSRole)
      setLoaded(true)
    }

    checkRoles()
  }, [userRolesNew])

  if (!loaded || loading) {
    return (
      <Backdrop sx={{ color: '#fff', zIndex: theme => theme.zIndex.drawer + 1 }} open={true}>
        <CircularProgress color="inherit" />
      </Backdrop>
    )
  }

  return (
    <BrowserRouter>
      <Route path="/Login" element={<LoginForm />} />
      <Route path="/Aviso" element={<PageAuthorization />} />
      <Route path="/PageIntegrationSuccess" element={<PageIntegrationSuccess />} />
      <Route
        path="/Profile/*"
        element={
          <PrivateRoute role="">
            <TemplateDefault>
              <Profile />
            </TemplateDefault>
          </PrivateRoute>
        }
      />
      {loaded && (
        <Route
          path="/*"
          element={
            <PrivateRoute role={''} loading={loading}>
              <TemplateDefault>
                {permissionRTLS ? (
                  <DashboardRTLS />
                ) : permissionIntegrationProcess ? (
                  <IntegrationProcess />
                ) : permissionIntegrationSebrae ? (
                  <DashboardSebrae />
                ) : (
                  <Home />
                )}{' '}
              </TemplateDefault>
            </PrivateRoute>
          }
        />
      )}
      <Route
        path="/DasboardBobinas/*"
        element={
          <PrivateRoute role="View_Dahsboard_Bobinas">
            <TemplateDefault>
              <DasboardBobinas />
            </TemplateDefault>
          </PrivateRoute>
        }
      />
      <Route
        path="/UploadStockActual/*"
        element={
          <PrivateRoute role="CurrentInventory_Reports">
            <TemplateDefault>
              <UploadStcokActual />
            </TemplateDefault>
          </PrivateRoute>
        }
      />
      <Route
        path="/DasboardIventory/*"
        element={
          <PrivateRoute role="View_Dahsboard_Inventário">
            <TemplateDefault>
              <DasboardStock />
            </TemplateDefault>
          </PrivateRoute>
        }
      />
      <Route
        path="/DashboardSales/*"
        element={
          <PrivateRoute role="View_DashboardSales">
            <TemplateDefault>
              <DashboardSales />
            </TemplateDefault>
          </PrivateRoute>
        }
      />
      <Route
        path="/ChargerDashboardVendor/*"
        element={
          <PrivateRoute role="Charge_ChargerDashboardVendor">
            <TemplateDefault>
              <ChargerDashboardVendor />
            </TemplateDefault>
          </PrivateRoute>
        }
      />
      <Route
        path="/ChargerDashboardClient/*"
        element={
          <PrivateRoute role="Charge_ChargerDashboardClient">
            <TemplateDefault>
              <ChargerDashboardClient />
            </TemplateDefault>
          </PrivateRoute>
        }
      />
      <Route
        path="/ChargerDashboardRouting/*"
        element={
          <PrivateRoute role="Charger_DashboardRouting">
            <TemplateDefault>
              <ChargerDashboardRouting />
            </TemplateDefault>
          </PrivateRoute>
        }
      />
      <Route
        path="/ChargerDashboardSeller/*"
        element={
          <PrivateRoute role="Charger_DashboardSeller">
            <TemplateDefault>
              <ChargerDashboardSeller />
            </TemplateDefault>
          </PrivateRoute>
        }
      />
      <Route
        path="/ChargerDashboardUploadBillstopay/*"
        element={
          <PrivateRoute role="Charger_UploadBillstopay">
            <TemplateDefault>
              <ChargerDashboardUploadBillstopay />
            </TemplateDefault>
          </PrivateRoute>
        }
      />
      <Route
        path="/products/*"
        element={
          <PrivateRoute role="View_Product">
            <TemplateDefault>
              <Product />
            </TemplateDefault>
          </PrivateRoute>
        }
      />
      <Route
        path="/ProductClient/*"
        element={
          <PrivateRoute role="View_ProductClient">
            <TemplateDefault>
              <ProductClient />
            </TemplateDefault>
          </PrivateRoute>
        }
      />
      <Route
        path="/PrintTagSerial/*"
        element={
          <PrivateRoute role="View_PrintTagSerial">
            <TemplateDefault>
              <PrintTagSerial />
            </TemplateDefault>
          </PrivateRoute>
        }
      />
      <Route
        path="/PrintTagSerialId/:movimentationOrderId/*"
        element={
          <PrivateRoute role="">
            <TemplateDefault>
              <PrintTagSerialId />
            </TemplateDefault>
          </PrivateRoute>
        }
      />
      <Route
        path="/ProductAssociation/*"
        exact
        element={
          <PrivateRoute role="View_Association">
            <TemplateDefault>
              <ProductAssociation />
            </TemplateDefault>
          </PrivateRoute>
        }
      />
      <Route
        path="/Association/*"
        exact
        element={
          <PrivateRoute role="View_AlocationStockPOC">
            <TemplateDefault>
              <Association />
            </TemplateDefault>
          </PrivateRoute>
        }
      />
      <Route
        path="/TagReprocessing/*"
        exact
        element={
          <PrivateRoute role="View_TagReprocessing">
            <TemplateDefault>
              <TagReprocessing />
            </TemplateDefault>
          </PrivateRoute>
        }
      />
      <Route
        path="/UploadMovimentationAlocation/*"
        exact
        element={
          <PrivateRoute role="">
            <TemplateDefault>
              <UploadMovimentationAlocation />
            </TemplateDefault>
          </PrivateRoute>
        }
      />
      <Route
        path="/DuplicateRemover/*"
        exact
        element={
          <PrivateRoute role="View_DuplicateRemover">
            <TemplateDefault>
              <DuplicateRemover />
            </TemplateDefault>
          </PrivateRoute>
        }
      />
      <Route
        path="/ProductAssociationCreate/*"
        exact
        element={
          <PrivateRoute role="Create_ProductAssociation">
            <TemplateDefault>
              <ProductAssociationCreate />
            </TemplateDefault>
          </PrivateRoute>
        }
      />
      <Route
        path="/ProductAssociationDelete/:id/*"
        exact
        element={
          <PrivateRoute role="Edit_ProductAssociation">
            <TemplateDefault>
              <ProductAssociationDelete />
            </TemplateDefault>
          </PrivateRoute>
        }
      />
      <Route
        path="/ProductAssociationEdit/:id/*"
        exact
        element={
          <PrivateRoute role="Delete_ProductAssociation">
            <TemplateDefault>
              <ProductAssociationEdit />
            </TemplateDefault>
          </PrivateRoute>
        }
      />
      {/*Permissao teste*/}
      <Route
        path="/PalletBlock/*"
        exact
        element={
          <PrivateRoute role="">
            <TemplateDefault>
              <PalletBlock />
            </TemplateDefault>
          </PrivateRoute>
        }
      />
      <Route
        path="/ReceiveOrderPicking/*"
        exact
        element={
          <PrivateRoute role="ReceiveOrderPicking_WMS">
            <TemplateDefault>
              <ReceiveOrderPicking />
            </TemplateDefault>
          </PrivateRoute>
        }
      />
      <Route
        path="/ReceiveManualEntry/*"
        exact
        element={
          <PrivateRoute role="ReceiveManualEntry_WMS">
            <TemplateDefault>
              <ReceiveManualEntry />
            </TemplateDefault>
          </PrivateRoute>
        }
      />
      <Route
        path="/AntiTheftReportsProduct/*"
        exact
        element={
          <PrivateRoute role="Reports_AntiTheftReportsProduct">
            <TemplateDefault>
              <AntiTheftReportsProduct />
            </TemplateDefault>
          </PrivateRoute>
        }
      />
      <Route
        path="/AlocationStock/*"
        exact
        element={
          <PrivateRoute role="View_AlocationStock">
            <TemplateDefault>
              <AlocationStock />
            </TemplateDefault>
          </PrivateRoute>
        }
      />
      <Route
        path="/UploadAlocationStock/*"
        exact
        element={
          <PrivateRoute role="Charge_AlocationParts">
            <TemplateDefault>
              <UploadAlocationStock />
            </TemplateDefault>
          </PrivateRoute>
        }
      />
      <Route
        path="/AlocationStockCreate/*"
        exact
        element={
          <PrivateRoute role="Create_AlocationStock">
            <TemplateDefault>
              <AlocationStockCreate />
            </TemplateDefault>
          </PrivateRoute>
        }
      />
      <Route
        path="/AlocationStockEdit/:alocationId/:subCompanyId"
        exact
        element={
          <PrivateRoute role="Edit_AlocationStock">
            <TemplateDefault>
              <AlocationStockEdit />
            </TemplateDefault>
          </PrivateRoute>
        }
      />
      {/*Permissao teste*/}
      <Route
        path="/ChargeProducts/*"
        exact
        element={
          <PrivateRoute role="Charge_Products">
            <TemplateDefault>
              <UploadProducts />
            </TemplateDefault>
          </PrivateRoute>
        }
      />
      <Route
        path="/UploadDeleteProducts/*"
        exact
        element={
          <PrivateRoute role="View_UploadDeleteProducts">
            <TemplateDefault>
              <UploadDeleteProducts />
            </TemplateDefault>
          </PrivateRoute>
        }
      />
      <Route
        path="/UploadCreateProducts/*"
        exact
        element={
          <PrivateRoute role="View_UploadCreateProducts">
            <TemplateDefault>
              <UploadCreateProducts />
            </TemplateDefault>
          </PrivateRoute>
        }
      />
      <Route
        path="/MachineReceipt/*"
        exact
        element={
          <PrivateRoute role="">
            <TemplateDefault>
              <MachineReceipt />
            </TemplateDefault>
          </PrivateRoute>
        }
      />
      <Route
        path="/UploadSubCompany/*"
        exact
        element={
          <PrivateRoute role="">
            <TemplateDefault>
              <UploadSubCompany />
            </TemplateDefault>
          </PrivateRoute>
        }
      />
      <Route
        path="/ChargerAssociationProduct/*"
        exact
        element={
          <PrivateRoute role="View_AssociationBarCodeClient">
            <TemplateDefault>
              <ChargerAssociationProduct />
            </TemplateDefault>
          </PrivateRoute>
        }
      />
      <Route
        path="/ChargerDeposit/*"
        exact
        element={
          <PrivateRoute role="View_AssociationBarCodeClient">
            <TemplateDefault>
              <ChargerDeposit />
            </TemplateDefault>
          </PrivateRoute>
        }
      />
      <Route
        path="/ChargerAlocation/*"
        exact
        element={
          <PrivateRoute role="View_AssociationBarCodeClient">
            <TemplateDefault>
              <ChargerAlocation />
            </TemplateDefault>
          </PrivateRoute>
        }
      />
      <Route
        path="/ChargerMovimentation/*"
        exact
        element={
          <PrivateRoute role="View_AssociationBarCodeClient">
            <TemplateDefault>
              <ChargerMovimentation />
            </TemplateDefault>
          </PrivateRoute>
        }
      />
      {/*Permissao teste*/}
      <Route
        path="/ChargeProductsNew/*"
        exact
        element={
          <PrivateRoute role="Charge_Products">
            <TemplateDefault>
              <UploadProductsNew />
            </TemplateDefault>
          </PrivateRoute>
        }
      />
      <Route
        path="/UploadSerial/*"
        exact
        element={
          <PrivateRoute role="View_UploadsSerial">
            <TemplateDefault>
              <UploadSerial />
            </TemplateDefault>
          </PrivateRoute>
        }
      />
      <Route
        path="/ChargeProduction/*"
        exact
        element={
          <PrivateRoute role="Charge_Production">
            <TemplateDefault>
              <UpdateProduction />
            </TemplateDefault>
          </PrivateRoute>
        }
      />
      <Route
        path="/UploadQuantity/*"
        exact
        element={
          <PrivateRoute role="Charge_UploadQuantity">
            <TemplateDefault>
              <UploadQuantity />
            </TemplateDefault>
          </PrivateRoute>
        }
      />
      <Route
        path="/ReportNewSampleControl/*"
        exact
        element={
          <PrivateRoute role="ReportNewSampleControl">
            <TemplateDefault>
              <ReportNewSampleControl />
            </TemplateDefault>
          </PrivateRoute>
        }
      />
      <Route
        path="/StockAudit/*"
        exact
        element={
          <PrivateRoute role="View_StockAudit">
            <TemplateDefault>
              <StockAudit />
            </TemplateDefault>
          </PrivateRoute>
        }
      />
      <Route
        path="/ChargeMovimentationAlocation/*"
        exact
        element={
          <PrivateRoute role="Charge_MovimentationAlocation">
            <TemplateDefault>
              <UpdateMovimentation />
            </TemplateDefault>
          </PrivateRoute>
        }
      />
      <Route
        path="/ChargeClient/*"
        exact
        element={
          <PrivateRoute role="Charge_Client">
            <TemplateDefault>
              <UploadClient />
            </TemplateDefault>
          </PrivateRoute>
        }
      />
      <Route
        path="/LinkProducts/*"
        exact
        element={
          <PrivateRoute role="View_LinkProduct">
            <TemplateDefault>
              <LinkProduct />
            </TemplateDefault>
          </PrivateRoute>
        }
      />
      <Route
        path="/Deposit/*"
        exact
        element={
          <PrivateRoute role="View_Deposit">
            <TemplateDefault>
              <Deposit />
            </TemplateDefault>
          </PrivateRoute>
        }
      />
      <Route
        path="/DepositCreate/*"
        exact
        element={
          <PrivateRoute role="Create_Deposit">
            <TemplateDefault>
              <DepositCreate />
            </TemplateDefault>
          </PrivateRoute>
        }
      />
      <Route
        path="/DepositEdit/:id/*"
        exact
        element={
          <PrivateRoute role="Edit_Deposit">
            <TemplateDefault>
              <DepositEdit />
            </TemplateDefault>
          </PrivateRoute>
        }
      />
      <Route
        path="/QuantityBox/*"
        exact
        element={
          <PrivateRoute role="View_QuantityBox">
            <TemplateDefault>
              <QuantityBox />
            </TemplateDefault>
          </PrivateRoute>
        }
      />
      <Route
        path="/PhotoReport"
        exact
        element={
          <PrivateRoute>
            <TemplateDefault>
              <PhotoReport />
            </TemplateDefault>
          </PrivateRoute>
        }
      />
      <Route
        path="/MovBreak/*"
        exact
        element={
          <PrivateRoute role="View_MovimentationBreak">
            <TemplateDefault>
              <MovimentationBreak />
            </TemplateDefault>
          </PrivateRoute>
        }
      />
      <Route
        path="/MovBreak/*"
        exact
        element={
          <PrivateRoute role="View_MovimentationBreak">
            <TemplateDefault>
              <MovimentationBreak />
            </TemplateDefault>
          </PrivateRoute>
        }
      />
      <Route
        path="/Sisplan_status_leitura/*"
        exact
        element={
          <PrivateRoute role="sisplan">
            <TemplateDefault>
              <ListReading />
            </TemplateDefault>
          </PrivateRoute>
        }
      />
      <Route
        path="/MovBreakCreate/*"
        exact
        element={
          <PrivateRoute role="Create_MovimentationBreak">
            <TemplateDefault>
              <MovimentationBreakCreate />
            </TemplateDefault>
          </PrivateRoute>
        }
      />
      <Route
        path="/MovBreakEdit/:id/*"
        exact
        element={
          <PrivateRoute role="Edit_MovimentationBreak">
            <TemplateDefault>
              <MovimentationBreakEdit />
            </TemplateDefault>
          </PrivateRoute>
        }
      />
      {/* <Route 
      path="/MovBreakEdit/:id/*" 
      exact 
      element={
        <PrivateRoute>
          <TemplateDefault>
            <MovBreakEdit/>
          </TemplateDefault>
        </PrivateRoute>
        } 
      /> */}
      <Route
        path="/MovBreakKanban/*"
        exact
        element={
          <PrivateRoute role="View_Kanban">
            <TemplateDefault>
              <MovBreakKanban />
            </TemplateDefault>
          </PrivateRoute>
        }
      />
      <Route
        path="/MovimentationRoute/*"
        exact
        element={
          <PrivateRoute role="View_MovimentationRoute">
            <TemplateDefault>
              <MovimentationOrderRoute />
            </TemplateDefault>
          </PrivateRoute>
        }
      />
      <Route
        path="/RouteEditor/:id/:subCompanyId/*"
        exact
        element={
          <PrivateRoute role="Edit_MovimentationRoute">
            <TemplateDefault>
              <RouteEditor />
            </TemplateDefault>
          </PrivateRoute>
        }
      />
      <Route
        path="/TransferOrder/*"
        exact
        element={
          <PrivateRoute role="View_TransferOrder">
            <TemplateDefault>
              <TransferOrder />
            </TemplateDefault>
          </PrivateRoute>
        }
      />
      <Route
        path="/CountOnline/*"
        exact
        element={
          <PrivateRoute role="View_CountOnline">
            <TemplateDefault>
              <CountOnline />
            </TemplateDefault>
          </PrivateRoute>
        }
      />
      <Route
        path="/MovimentationType/:id"
        exact
        element={
          <PrivateRoute role="">
            <TemplateDefault>
              <MovimentationType />
            </TemplateDefault>
          </PrivateRoute>
        }
      />
      <Route
        path="/TransferBetweenLocations/*"
        exact
        element={
          <PrivateRoute role="View_TransferBetweenLocations">
            <TemplateDefault>
              <TransferBetweenLocations />
            </TemplateDefault>
          </PrivateRoute>
        }
      />
      <Route
        path="/MovimentationOrderDelete/:id/*"
        exact
        element={
          <PrivateRoute role="">
            <TemplateDefault>
              <MovimentationOrderDelete />
            </TemplateDefault>
          </PrivateRoute>
        }
      />
      <Route
        path="/TransferOrderItem/:id/*"
        exact
        element={
          <PrivateRoute role="Edit_TransferOrder">
            <TemplateDefault>
              <TransferOrderItem />
            </TemplateDefault>
          </PrivateRoute>
        }
      />
      <Route
        path="/TransferOrderCreate/*"
        exact
        element={
          <PrivateRoute role="Create_TransferOrder">
            <TemplateDefault>
              <TransferOrderCreate />
            </TemplateDefault>
          </PrivateRoute>
        }
      />
      <Route
        path="/Process/*"
        exact
        element={
          <PrivateRoute role="View_Process">
            <TemplateDefault>
              <Process />
            </TemplateDefault>
          </PrivateRoute>
        }
      />
      <Route
        path="/ExpeditionOrder/*"
        exact
        element={
          <PrivateRoute role="View_ExpeditionOrder">
            <TemplateDefault>
              <ExpeditionOrder />
            </TemplateDefault>
          </PrivateRoute>
        }
      />
      <Route
        path="/PickingList/*"
        exact
        element={
          <PrivateRoute role="View_PickingList">
            <TemplateDefault>
              <PickingList />
            </TemplateDefault>
          </PrivateRoute>
        }
      />
      <Route
        path="/PickingListEdit/:id/*"
        exact
        element={
          <PrivateRoute role="Edit_PickingList">
            <TemplateDefault>
              <PickingListEdit />
            </TemplateDefault>
          </PrivateRoute>
        }
      />
      <Route
        path="/ExpeditionOrderItem/:id/*"
        exact
        element={
          <PrivateRoute role="Edit_PickingList">
            <TemplateDefault>
              <ExpeditionOrderItem />
            </TemplateDefault>
          </PrivateRoute>
        }
      />
      <Route
        path="/ExpeditionOrderCreate/*"
        exact
        element={
          <PrivateRoute role="Create_PickingList">
            <TemplateDefault>
              <ExpeditionOrderCreate />
            </TemplateDefault>
          </PrivateRoute>
        }
      />
      <Route
        path="/CountStockOrder/*"
        exact
        element={
          <PrivateRoute role="View_CountStockOrder">
            <TemplateDefault>
              <CountStockOrder />
            </TemplateDefault>
          </PrivateRoute>
        }
      />
      <Route
        path="/CountStockOrderClient/*"
        exact
        element={
          <PrivateRoute role="View_CountStockOrder_ControlActive">
            <TemplateDefault>
              <CountStockOrder />
            </TemplateDefault>
          </PrivateRoute>
        }
      />
      <Route
        path="/CountStockOrder/:id/*"
        exact
        element={
          <PrivateRoute role="Edit_CountStockOrder">
            <TemplateDefault>
              <CountStockOrderEdit />
            </TemplateDefault>
          </PrivateRoute>
        }
      />
      <Route
        path="/CountStockOrderCreate/*"
        exact
        element={
          <PrivateRoute role="Create_CountStockOrder">
            <TemplateDefault>
              <CountStockOrderCreate />
            </TemplateDefault>
          </PrivateRoute>
        }
      />
      <Route
        path="/CountStockOrderEdit/:id/*"
        exact
        element={
          <PrivateRoute role="Edit_CountStockOrder">
            <TemplateDefault>
              <CountStockOrderEdit />
            </TemplateDefault>
          </PrivateRoute>
        }
      />
      <Route
        path="/StockEntryOrder/*"
        exact
        element={
          <PrivateRoute role="View_StockEntryOrder">
            <TemplateDefault>
              <StockEntryOrder />
            </TemplateDefault>
          </PrivateRoute>
        }
      />
      <Route
        path="/SalesOrder/*"
        exact
        element={
          <PrivateRoute role="Purchase_Reports">
            <TemplateDefault>
              <SalesOrder />
            </TemplateDefault>
          </PrivateRoute>
        }
      />
      <Route
        path="/NewSampleControl/*"
        exact
        element={
          <PrivateRoute role="View_NewSampleControl">
            <TemplateDefault>
              <NewSampleControl />
            </TemplateDefault>
          </PrivateRoute>
        }
      />
      <Route
        path="/MovimentationReturn/*"
        exact
        element={
          <PrivateRoute role="View_MovimentationRoute">
            <TemplateDefault>
              <MovimentationReturn />
            </TemplateDefault>
          </PrivateRoute>
        }
      />
      <Route
        path="/UpdateMovimentation/*"
        exact
        element={
          <PrivateRoute role="">
            <TemplateDefault>
              <UpdateMovimentation />
            </TemplateDefault>
          </PrivateRoute>
        }
      />
      <Route
        path="/StockEntryOrderCreate/*"
        exact
        element={
          <PrivateRoute role="Create_StockEntryOrder">
            <TemplateDefault>
              <StockEntryOrderCreate />
            </TemplateDefault>
          </PrivateRoute>
        }
      />
      <Route
        path="/StockEntryOrderEdit/:id/*"
        exact
        element={
          <PrivateRoute role="Edit_StockEntryOrder">
            <TemplateDefault>
              <StockEntryOrderEdit />
            </TemplateDefault>
          </PrivateRoute>
        }
      />
      <Route
        path="/OutStockOrder/*"
        exact
        element={
          <PrivateRoute role="View_OutStockOrder">
            <TemplateDefault>
              <OutStockOrder />
            </TemplateDefault>
          </PrivateRoute>
        }
      />
      <Route
        path="/OutStockOrderEdit/:id/*"
        exact
        element={
          <PrivateRoute role="Edit_OutStockOrder">
            <TemplateDefault>
              <OutStockOrderEdit />
            </TemplateDefault>
          </PrivateRoute>
        }
      />
      <Route
        path="/OutStockOrderCreate/*"
        exact
        element={
          <PrivateRoute role="Create_OutStockOrder">
            <TemplateDefault>
              <OutStockOrderCreate />
            </TemplateDefault>
          </PrivateRoute>
        }
      />
      <Route
        path="/LabelOrder/*"
        exact
        element={
          <PrivateRoute role="View_LabelOrder">
            <TemplateDefault>
              <LabelOrder />
            </TemplateDefault>
          </PrivateRoute>
        }
      />
      <Route
        path="/LabelOrderClient/*"
        exact
        element={
          <PrivateRoute role="View_LabelOrder_Client">
            <TemplateDefault>
              <LabelOrderClient />
            </TemplateDefault>
          </PrivateRoute>
        }
      />
      <Route
        path="/LabelOrderClient/LabelOrderCreate*"
        exact
        element={
          <PrivateRoute role="Create_LabelOrder_Client">
            <TemplateDefault>
              <LabelOrderClientCreate />
            </TemplateDefault>
          </PrivateRoute>
        }
      />
      <Route
        path="/LabelOrderCreate/*"
        exact
        element={
          <PrivateRoute role="Create_LabelOrder">
            <TemplateDefault>
              <LabelOrderCreate />
            </TemplateDefault>
          </PrivateRoute>
        }
      />
      <Route
        path="/LabelOrderEdit/:id/*"
        exact
        element={
          <PrivateRoute role="Edit_LabelOrder">
            <TemplateDefault>
              <LabelOrderEdit />
            </TemplateDefault>
          </PrivateRoute>
        }
      />
      <Route
        path="/ChargeConference/*"
        exact
        element={
          <PrivateRoute role="Charge_Conference">
            <TemplateDefault>
              <UploadConferenceParts />
            </TemplateDefault>
          </PrivateRoute>
        }
      />
      <Route
        path="/ReportBlockedProducts/*"
        exact
        element={
          <PrivateRoute role="BlockedProducts_Reports">
            <TemplateDefault>
              <ReportBlockedProducts />
            </TemplateDefault>
          </PrivateRoute>
        }
      />
      <Route
        path="/ReportProductPickingNotFound"
        exact
        element={
          <PrivateRoute role="ProductPickingNotFound_Reports">
            <TemplateDefault>
              <ReportProductPickingNotFound />
            </TemplateDefault>
          </PrivateRoute>
        }
      />
      <Route
        path="/ReportLoteProduct/*"
        exact
        element={
          <PrivateRoute role="LoteProduct_Reports">
            <TemplateDefault>
              <ReportLoteProduct />
            </TemplateDefault>
          </PrivateRoute>
        }
      />
      <Route
        path="/ReportCountStock/*"
        exact
        element={
          <PrivateRoute role="View_ReportCountStock">
            <TemplateDefault>
              <ReportCountStock />
            </TemplateDefault>
          </PrivateRoute>
        }
      />
      <Route
        path="/ReportCountActive/*"
        exact
        element={
          <PrivateRoute role="View_ReportCountActive">
            <TemplateDefault>
              <ReportCountActive />
            </TemplateDefault>
          </PrivateRoute>
        }
      />
      <Route
        path="/ReportCountStockConsoled/*"
        exact
        element={
          <PrivateRoute role="View_ReportCountStockConsoled">
            <TemplateDefault>
              <ReportCountStockConsolid />
            </TemplateDefault>
          </PrivateRoute>
        }
      />
      <Route
        path="/ReportPurchase/*"
        exact
        element={
          <PrivateRoute role="">
            <TemplateDefault>
              <ReportPurchase />
            </TemplateDefault>
          </PrivateRoute>
        }
      />
      <Route
        path="/ReportExtractCountStock/*"
        exact
        element={
          <PrivateRoute role="ExtractCountStock_Reports">
            <TemplateDefault>
              <ReportExtractCountStock />
            </TemplateDefault>
          </PrivateRoute>
        }
      />
      <Route
        path="/ReportBalanceStock/*"
        exact
        element={
          <PrivateRoute role="InventoryBalance_Reports">
            <TemplateDefault>
              <ReportInventory />
            </TemplateDefault>
          </PrivateRoute>
        }
      />
      <Route
        path="/ReportMovimentationStockByProduct/*"
        exact
        element={
          <PrivateRoute role="MovimentationStockByProduct_Reports">
            <TemplateDefault>
              <ReportMovimentationStockByProduct />
            </TemplateDefault>
          </PrivateRoute>
        }
      />
      <Route
        path="/Log/*"
        exact
        element={
          <PrivateRoute role="View_Log">
            <TemplateDefault>
              <Log />
            </TemplateDefault>
          </PrivateRoute>
        }
      />
      <Route
        path="/LogClient/*"
        exact
        element={
          <PrivateRoute role="View_LogClient">
            <TemplateDefault>
              <LogClient />
            </TemplateDefault>
          </PrivateRoute>
        }
      />
      <Route
        path="/ReportMovimentationHistoryByProduct/*"
        exact
        element={
          <PrivateRoute role="MovimentationHistoryByProduct_Report">
            <TemplateDefault>
              <ReportMovimentationHistoryByProduct />
            </TemplateDefault>
          </PrivateRoute>
        }
      />
      <Route
        path="/AntiTheftReports/*"
        exact
        element={
          <PrivateRoute role="AnthiTheft_Reports">
            <TemplateDefault>
              <AntiTheftReports />
            </TemplateDefault>
          </PrivateRoute>
        }
      />
      <Route
        path="/ReportAccess/*"
        exact
        element={
          <PrivateRoute role="ReportAccess_Reports">
            <TemplateDefault>
              <ReportAccess />
            </TemplateDefault>
          </PrivateRoute>
        }
      />
      <Route
        path="/ReportDissassociation/*"
        exact
        element={
          <PrivateRoute role="Dissassociation_Reports">
            <TemplateDefault>
              <ReportDissassociation />
            </TemplateDefault>
          </PrivateRoute>
        }
      />
      <Route
        path="/PrintReference/*"
        exact
        element={
          <PrivateRoute role="">
            <TemplateDefault>
              <PrintReference />
            </TemplateDefault>
          </PrivateRoute>
        }
      />
      <Route
        path="/PrintOrder/*"
        exact
        element={
          <PrivateRoute role="">
            <TemplateDefault>
              <PrintOrder />
            </TemplateDefault>
          </PrivateRoute>
        }
      />
      <Route
        path="/AssociationBarCode/*"
        exact
        element={
          <PrivateRoute role="View_AssociationBarCode">
            <TemplateDefault>
              <AssociationBarCode />
            </TemplateDefault>
          </PrivateRoute>
        }
      />
      <Route
        path="/AssociationBarCodeClient/*"
        exact
        element={
          <PrivateRoute role="View_AssociationBarCodeClient">
            <TemplateDefault>
              <AssociationBarCodeClient />
            </TemplateDefault>
          </PrivateRoute>
        }
      />
      <Route
        path="/Disassociate/*"
        exact
        element={
          <PrivateRoute role="View_Disassociate">
            <TemplateDefault>
              <Disassociate />
            </TemplateDefault>
          </PrivateRoute>
        }
      />
      <Route
        path="/RoadMap/*"
        exact
        element={
          <PrivateRoute role="Roadmap_Report">
            <TemplateDefault>
              <RoadMap />
            </TemplateDefault>
          </PrivateRoute>
        }
      />
      <Route
        path="/ReportAlocationParts/*"
        exact
        element={
          <PrivateRoute role="PartsAlocation_Reports">
            <TemplateDefault>
              <ReportAlocationParts />
            </TemplateDefault>
          </PrivateRoute>
        }
      />
      <Route
        path="/ReportLocation/*"
        exact
        element={
          <PrivateRoute role="Alocation_Reports">
            <TemplateDefault>
              <ReportLocation />
            </TemplateDefault>
          </PrivateRoute>
        }
      />
      <Route
        path="/LoteReport/*"
        exact
        element={
          <PrivateRoute role="Lote_Reports">
            <TemplateDefault>
              <LoteReport />
            </TemplateDefault>
          </PrivateRoute>
        }
      />
      <Route
        path="/ProductionReport/*"
        exact
        element={
          <PrivateRoute role="Production_Reports">
            <TemplateDefault>
              <ProductionReport />
            </TemplateDefault>
          </PrivateRoute>
        }
      />
      <Route
        path="/PrinterZpl/*"
        exact
        element={
          <PrivateRoute role="View_PrinterZpl">
            <TemplateDefault>
              <PrinterZpl />
            </TemplateDefault>
          </PrivateRoute>
        }
      />
      <Route
        path="/TagLogistics/*"
        exact
        element={
          <PrivateRoute role="View_TagLogistics">
            <TemplateDefault>
              <TagLogistics />
            </TemplateDefault>
          </PrivateRoute>
        }
      />
      <Route
        path="/PrinterZplCreate/*"
        exact
        element={
          <PrivateRoute role="PrinterZplVIEW">
            <TemplateDefault>
              <PrinterZplCreate />
            </TemplateDefault>
          </PrivateRoute>
        }
      />
      <Route
        path="/PrinterZplEdit/:id/*"
        exact
        element={
          <PrivateRoute role="PrinterZplVIEW">
            <TemplateDefault>
              <PrinterZplEdit />
            </TemplateDefault>
          </PrivateRoute>
        }
      />
      <Route
        path="/PrinterZplDelete/:id/*"
        exact
        element={
          <PrivateRoute role="PrinterZplVIEW">
            <TemplateDefault>
              <PrinterZplDelete />
            </TemplateDefault>
          </PrivateRoute>
        }
      />
      <Route
        path="/PrinterLaundry/*"
        exact
        element={
          <PrivateRoute role="PrinterLaundryView">
            <TemplateDefault>
              <PrinterLaundry />
            </TemplateDefault>
          </PrivateRoute>
        }
      />
      <Route
        path="/PrinterLaundryCreate/*"
        exact
        element={
          <PrivateRoute role="PrinterLaundryView">
            <TemplateDefault>
              <LaundryCreate />
            </TemplateDefault>
          </PrivateRoute>
        }
      />
      <Route
        path="/PrinterLaundryEdit/:id/*"
        exact
        element={
          <PrivateRoute role="PrinterLaundryView">
            <TemplateDefault>
              <LaundryEdit />
            </TemplateDefault>
          </PrivateRoute>
        }
      />
      <Route
        path="/Equipment/*"
        exact
        element={
          <PrivateRoute role="View_Equipment">
            <TemplateDefault>
              <Equipment />
            </TemplateDefault>
          </PrivateRoute>
        }
      />
      <Route
        path="/EquipmentCreate/*"
        exact
        element={
          <PrivateRoute role="Create_Equipment">
            <TemplateDefault>
              <EquipmentCreate />
            </TemplateDefault>
          </PrivateRoute>
        }
      />
      <Route
        path="/EquipmentEdit/:id/*"
        exact
        element={
          <PrivateRoute role="Edit_Equipment">
            <TemplateDefault>
              <EquipmentEdit />
            </TemplateDefault>
          </PrivateRoute>
        }
      />
      <Route
        path="/EquipmentDelete/:id/*"
        exact
        element={
          <PrivateRoute role="Delete_Equipment">
            <TemplateDefault>
              <EquipmentDelete />
            </TemplateDefault>
          </PrivateRoute>
        }
      />
      <Route
        path="/EquipmentDetailsLog/:id/*"
        exact
        element={
          <PrivateRoute role="EquipmentVIEW">
            <TemplateDefault>
              <EquipmentDetailsLog />
            </TemplateDefault>
          </PrivateRoute>
        }
      />
      <Route
        path="/SubCompany/*"
        exact
        element={
          <PrivateRoute role="View_SubCompany">
            <TemplateDefault>
              <SubCompany />
            </TemplateDefault>
          </PrivateRoute>
        }
      />
      <Route
        path="/SubCompanyCreate/*"
        exact
        element={
          <PrivateRoute role="Create_SubCompany">
            <TemplateDefault>
              <SubCompanyCreate />
            </TemplateDefault>
          </PrivateRoute>
        }
      />
      <Route
        path="/SubCompanyEdit/:id/*"
        exact
        element={
          <PrivateRoute role="Edit_SubCompany">
            <TemplateDefault>
              <SubCompanyEdit />
            </TemplateDefault>
          </PrivateRoute>
        }
      />
      <Route
        path="/Client"
        exact
        element={
          <PrivateRoute role="View_Client">
            <TemplateDefault>
              <Client />
            </TemplateDefault>
          </PrivateRoute>
        }
      />
      <Route
        path="/SubCompanyDelete/:id/*"
        exact
        element={
          <PrivateRoute role="Delete_SubCompany">
            <TemplateDefault>
              <SubCompanyDelete />
            </TemplateDefault>
          </PrivateRoute>
        }
      />
      <Route
        path="/IntegrationCredencial/*"
        exact
        element={
          <PrivateRoute role="View_IntegrationCredencial">
            <TemplateDefault>
              <IntegrationCredencial />
            </TemplateDefault>
          </PrivateRoute>
        }
      />
      <Route
        path="/IntegrationCredencialCreate/*"
        exact
        element={
          <PrivateRoute role="Create_IntegrationCredencial">
            <TemplateDefault>
              <IntegrationCredencialCreate />
            </TemplateDefault>
          </PrivateRoute>
        }
      />
      <Route
        path="/IntegrationCredencialEdit/:id/*"
        exact
        element={
          <PrivateRoute role="Edit_IntegrationCredencial">
            <TemplateDefault>
              <IntegrationCredencialEdit />
            </TemplateDefault>
          </PrivateRoute>
        }
      />
      <Route
        path="/IntegrationCredencialDelete/:id/*"
        exact
        element={
          <PrivateRoute role="Delete_IntegrationCredencial">
            <TemplateDefault>
              <IntegrationCredencialDelete />
            </TemplateDefault>
          </PrivateRoute>
        }
      />
      <Route
        path="/IntegrationAssociation/*"
        exact
        element={
          <PrivateRoute role="View_IntegrationAssociation">
            <TemplateDefault>
              <IntegrationAssociation />
            </TemplateDefault>
          </PrivateRoute>
        }
      />
      <Route
        path="/Employee/*"
        exact
        element={
          <PrivateRoute role="View_Employee">
            <TemplateDefault>
              <Employee />
            </TemplateDefault>
          </PrivateRoute>
        }
      />
      <Route
        path="/EmployeeCreate/*"
        exact
        element={
          <PrivateRoute role="Create_Employee">
            <TemplateDefault>
              <EmployeeCreate />
            </TemplateDefault>
          </PrivateRoute>
        }
      />
      <Route
        path="/EmployeeEdit/:id/*"
        exact
        element={
          <PrivateRoute role="Edit_Employee">
            <TemplateDefault>
              <EmployeeEdit />
            </TemplateDefault>
          </PrivateRoute>
        }
      />
      <Route
        path="/EmployeeRole/:id/*"
        exact
        element={
          <PrivateRoute role="">
            <TemplateDefault>
              <EmployeeRole />
            </TemplateDefault>
          </PrivateRoute>
        }
      />
      <Route
        path="/EmployeeDelete/:id/*"
        exact
        element={
          <PrivateRoute role="Delete_Employee">
            <TemplateDefault>
              <EmployeeDelete />
            </TemplateDefault>
          </PrivateRoute>
        }
      />
      <Route
        path="/MyCompany/*"
        exact
        element={
          <PrivateRoute role="View_Company">
            <TemplateDefault>
              <MyCompany />
            </TemplateDefault>
          </PrivateRoute>
        }
      />
      <Route
        path="/Printer/*"
        exact
        element={
          <PrivateRoute role="View_Printer">
            <TemplateDefault>
              <Printer />
            </TemplateDefault>
          </PrivateRoute>
        }
      />
      <Route
        path="/PrinterCreate/*"
        exact
        element={
          <PrivateRoute role="Create_Printer">
            <TemplateDefault>
              <PrinterCreate />
            </TemplateDefault>
          </PrivateRoute>
        }
      />
      <Route
        path="/PrinterEdit/:id/*"
        exact
        element={
          <PrivateRoute role="Edit_Printer">
            <TemplateDefault>
              <PrinterEdit />
            </TemplateDefault>
          </PrivateRoute>
        }
      />
      <Route
        path="/PrinterDelete/:id/*"
        exact
        element={
          <PrivateRoute role="Delete_Printer">
            <TemplateDefault>
              <PrinterDelete />
            </TemplateDefault>
          </PrivateRoute>
        }
      />
      <Route
        path="/PrinterZPLDefault/*"
        exact
        element={
          <PrivateRoute role="View_PrinterZPLDefault">
            <TemplateDefault>
              <PrinterZPLDefault />
            </TemplateDefault>
          </PrivateRoute>
        }
      />
      <Route
        path="/PrinterZPLDefaultCreate/*"
        exact
        element={
          <PrivateRoute role="Create_PrinterZPLDefault">
            <TemplateDefault>
              <PrinterZPLDefaultCreate />
            </TemplateDefault>
          </PrivateRoute>
        }
      />
      <Route
        path="/TemplateLabel/*"
        exact
        element={
          <PrivateRoute role="View_TemplateLabel">
            <TemplateDefault>
              <TemplateLabel />
            </TemplateDefault>
          </PrivateRoute>
        }
      />
      <Route
        path="/PrinterZPLDefaultEdit/:id/*"
        exact
        element={
          <PrivateRoute role="Edit_PrinterZPLDefault">
            <TemplateDefault>
              <PrinterZPLDefaultEdit />
            </TemplateDefault>
          </PrivateRoute>
        }
      />
      <Route
        path="/PrinterZPLDefaultDelete/:id/*"
        exact
        element={
          <PrivateRoute role="Delete_PrinterZPLDefault">
            <TemplateDefault>
              <PrinterZPLDefaultDelete />
            </TemplateDefault>
          </PrivateRoute>
        }
      />
      <Route
        path="/ReportFaccao/*"
        exact
        element={
          <PrivateRoute role="View_ReportFaccao">
            <TemplateDefault>
              <ReportFaccao />
            </TemplateDefault>
          </PrivateRoute>
        }
      />
      <Route
        path="/FaccaoCreate/*"
        exact
        element={
          <PrivateRoute role="Create_ReportFaccao">
            <TemplateDefault>
              <FaccaoCreate />
            </TemplateDefault>
          </PrivateRoute>
        }
      />
      <Route
        path="/FactionEdit/:id/*"
        exact
        element={
          <PrivateRoute role="Edit_ReportFaccao">
            <TemplateDefault>
              <FactionEdit />
            </TemplateDefault>
          </PrivateRoute>
        }
      />
      <Route
        path="/FactionDelete/:id/*"
        Edit_MonitoringEquipment
        exact
        element={
          <PrivateRoute role="Delete_ReportFaccao">
            <TemplateDefault>
              <FactionDelete />
            </TemplateDefault>
          </PrivateRoute>
        }
      />
      <Route
        path="/MonitoringEquipmentAutomatic/*"
        exact
        element={
          <PrivateRoute role="View_MonitoringEquipment">
            <TemplateDefault>
              <MonitoringEquipmentAutomatic />
            </TemplateDefault>
          </PrivateRoute>
        }
      />
      <Route
        path="/MonitoringCreate/*"
        exact
        element={
          <PrivateRoute role="Create_MonitoringEquipment">
            <TemplateDefault>
              <MonitorCreate />
            </TemplateDefault>
          </PrivateRoute>
        }
      />
      <Route
        path="/MonitoringDelete/:id/*"
        exact
        element={
          <PrivateRoute role="Delete_MonitoringEquipment">
            <TemplateDefault>
              <MonitorDelete />
            </TemplateDefault>
          </PrivateRoute>
        }
      />
      <Route
        path="/MonitoringEdit/:id/*"
        exact
        element={
          <PrivateRoute role="Edit_MonitoringEquipment">
            <TemplateDefault>
              <MonitorEdit />
            </TemplateDefault>
          </PrivateRoute>
        }
      />
      <Route
        path="/ZPLRef/*"
        exact
        element={
          <PrivateRoute role="View_ZPLRef">
            <TemplateDefault>
              <ZPLRef />
            </TemplateDefault>
          </PrivateRoute>
        }
      />
      <Route
        path="/ZPLRefCreate/*"
        exact
        element={
          <PrivateRoute role="Create_ZPLRef">
            <TemplateDefault>
              <ZPLRefCreate />
            </TemplateDefault>
          </PrivateRoute>
        }
      />
      <Route
        path="/ZPLRefEdit/:id/*"
        exact
        element={
          <PrivateRoute role="Edit_ZPLRef">
            <TemplateDefault>
              <ZPLRefEdit />
            </TemplateDefault>
          </PrivateRoute>
        }
      />
      <Route
        path="/ZPLRefDelete/:id/*"
        exact
        element={
          <PrivateRoute role="Delete_ZPLRef">
            <TemplateDefault>
              <ZPLRefDelete />
            </TemplateDefault>
          </PrivateRoute>
        }
      />
      <Route
        path="/TimeExpiration/*"
        exact
        element={
          <PrivateRoute role="View_TimeExpiration">
            <TemplateDefault>
              <TimeExpiration />
            </TemplateDefault>
          </PrivateRoute>
        }
      />
      <Route
        path="/Parameters/*"
        exact
        element={
          <PrivateRoute role="View_Parameters">
            <TemplateDefault>
              <Parameters />
            </TemplateDefault>
          </PrivateRoute>
        }
      />
      <Route
        path="/ParametersCreate/*"
        exact
        element={
          <PrivateRoute role="Create_Parameters">
            <TemplateDefault>
              <ParametersCreate />
            </TemplateDefault>
          </PrivateRoute>
        }
      />
      <Route
        path="/ParametersEdit/:id/*"
        exact
        element={
          <PrivateRoute role="Edit_Parameters">
            <TemplateDefault>
              <ParametersEdit />
            </TemplateDefault>
          </PrivateRoute>
        }
      />
      <Route
        path="/ParametersDelete/:id/*"
        exact
        element={
          <PrivateRoute role="Delete_Parameters">
            <TemplateDefault>
              <ParametersDelete />
            </TemplateDefault>
          </PrivateRoute>
        }
      />
      <Route
        path="/EmailParameters/*"
        exact
        element={
          <PrivateRoute role="View_EmailParameters">
            <TemplateDefault>
              <EmailParameters />
            </TemplateDefault>
          </PrivateRoute>
        }
      />
      <Route
        path="/Elearning/*"
        exact
        element={
          <PrivateRoute role="View_Elearning">
            <TemplateDefault>
              <Elearning />
            </TemplateDefault>
          </PrivateRoute>
        }
      />
      <Route
        path="/EmailParametersCreate/*"
        exact
        element={
          <PrivateRoute role="Create_EmailParameters">
            <TemplateDefault>
              <EmailParametersCreate />
            </TemplateDefault>
          </PrivateRoute>
        }
      />
      <Route
        path="/EmailParametersEdit/:id/*"
        exact
        element={
          <PrivateRoute role="Edit_EmailParameters">
            <TemplateDefault>
              <EmailParametersEdit />
            </TemplateDefault>
          </PrivateRoute>
        }
      />
      <Route
        path="/EmailParametersDelete/:id/*"
        exact
        element={
          <PrivateRoute role="Delete_EmailParameters">
            <TemplateDefault>
              <EmailParametersDelete />
            </TemplateDefault>
          </PrivateRoute>
        }
      />
      <Route
        path="/EquipmentSold/*"
        exact
        element={
          <PrivateRoute role="View_EquipmentSold">
            <TemplateDefault>
              <EquipmentSold />
            </TemplateDefault>
          </PrivateRoute>
        }
      />
      <Route
        path="/IntegrationDefault/*"
        exact
        element={
          <PrivateRoute role="View_IntegrationDefault">
            <TemplateDefault>
              <IntegrationDefault />
            </TemplateDefault>
          </PrivateRoute>
        }
      />
      <Route
        path="/IntegrationDefaultCreate/*"
        exact
        element={
          <PrivateRoute role="Create_IntegrationDefault">
            <TemplateDefault>
              <IntegrationDefaultCreate />
            </TemplateDefault>
          </PrivateRoute>
        }
      />
      <Route
        path="/IntegrationDefaultEdit/:id/*"
        exact
        element={
          <PrivateRoute role="Edit_IntegrationDefault">
            <TemplateDefault>
              <IntegrationDefaultEdit />
            </TemplateDefault>
          </PrivateRoute>
        }
      />
      <Route
        path="/IntegrationDefaultDelete/:id/*"
        exact
        element={
          <PrivateRoute role="Delete_IntegrationDefault">
            <TemplateDefault>
              <IntegrationDefaultDelete />
            </TemplateDefault>
          </PrivateRoute>
        }
      />
      <Route
        path="/Role/*"
        exact
        element={
          <PrivateRoute role="View_Role">
            <TemplateDefault>
              <Role />
            </TemplateDefault>
          </PrivateRoute>
        }
      />
      <Route
        path="/Rules/*"
        exact
        element={
          <PrivateRoute role="">
            <TemplateDefault>
              <Rules />
            </TemplateDefault>
          </PrivateRoute>
        }
      />
      <Route
        path="/RoleCreate/*"
        exact
        element={
          <PrivateRoute role="Create_Role">
            <TemplateDefault>
              <RoleCreate />
            </TemplateDefault>
          </PrivateRoute>
        }
      />
      <Route
        path="/RoleEdit/:id/*"
        exact
        element={
          <PrivateRoute role="Edit_Role">
            <TemplateDefault>
              <RoleEdit />
            </TemplateDefault>
          </PrivateRoute>
        }
      />
      <Route
        path="/RoleDelete/:id/*"
        exact
        element={
          <PrivateRoute role="Delete_Role">
            <TemplateDefault>
              <RoleDelete />
            </TemplateDefault>
          </PrivateRoute>
        }
      />
      <Route
        path="/Segment/*"
        exact
        element={
          <PrivateRoute role="View_Segment">
            <TemplateDefault>
              <Segment />
            </TemplateDefault>
          </PrivateRoute>
        }
      />
      <Route
        path="/RulesCategory/*"
        exact
        element={
          <PrivateRoute role="">
            <TemplateDefault>
              <RulesCategory />
            </TemplateDefault>
          </PrivateRoute>
        }
      />
      <Route
        path="/SegmentCreate/*"
        exact
        element={
          <PrivateRoute role="Create_Segment">
            <TemplateDefault>
              <SegmentCreate />
            </TemplateDefault>
          </PrivateRoute>
        }
      />
      <Route
        path="/SegmentEdit/:id/*"
        exact
        element={
          <PrivateRoute role="Edit_Segment">
            <TemplateDefault>
              <SegmentEdit />
            </TemplateDefault>
          </PrivateRoute>
        }
      />
      <Route
        path="/SegmentDelete/:id/*"
        exact
        element={
          <PrivateRoute role="Delete_Segment">
            <TemplateDefault>
              <SegmentDelete />
            </TemplateDefault>
          </PrivateRoute>
        }
      />
      <Route
        path="/SegmentGroup/*"
        exact
        element={
          <PrivateRoute role="View_SegmentGroup">
            <TemplateDefault>
              <SegmentGroup />
            </TemplateDefault>
          </PrivateRoute>
        }
      />
      <Route
        path="/SegmentGroupCreate/*"
        exact
        element={
          <PrivateRoute role="Create_SegmentGroup">
            <TemplateDefault>
              <SegmentGroupCreate />
            </TemplateDefault>
          </PrivateRoute>
        }
      />
      <Route
        path="/SegmentGroupEdit/:id/*"
        exact
        element={
          <PrivateRoute role="Edit_SegmentGroup">
            <TemplateDefault>
              <SegmentGroupEdit />
            </TemplateDefault>
          </PrivateRoute>
        }
      />
      <Route
        path="/SegmentGroupDelete/:id/*"
        exact
        element={
          <PrivateRoute role="Delete_SegmentGroup">
            <TemplateDefault>
              <SegmentGroupDelete />
            </TemplateDefault>
          </PrivateRoute>
        }
      />
      <Route
        path="/SegmentGroupRole/:id/*"
        exact
        element={
          <PrivateRoute role="SegmentVIEW">
            <TemplateDefault>
              <SegmentGroupRole />
            </TemplateDefault>
          </PrivateRoute>
        }
      />
      <Route
        path="/SegmentGroupRoleDelete/:segmentGroupId/:roleId/*"
        exact
        element={
          <PrivateRoute role="SegmentVIEW">
            <TemplateDefault>
              <SegmentGroupRoleDelete />
            </TemplateDefault>
          </PrivateRoute>
        }
      />
      <Route
        path="/VersionUpdate/*"
        exact
        element={
          <PrivateRoute role="View_Update">
            <TemplateDefault>
              <Update />
            </TemplateDefault>
          </PrivateRoute>
        }
      />
      <Route
        path="/UpdateCreate/*"
        exact
        element={
          <PrivateRoute role="UpdateView">
            <TemplateDefault>
              <UpdateCreate />
            </TemplateDefault>
          </PrivateRoute>
        }
      />
      <Route
        path="/Plan/*"
        exact
        element={
          <PrivateRoute role="View_Plan">
            <TemplateDefault>
              <Plan />
            </TemplateDefault>
          </PrivateRoute>
        }
      />
      <Route
        path="/PlanCreate/*"
        exact
        element={
          <PrivateRoute role="Create_Plan	">
            <TemplateDefault>
              <PlanCreate />
            </TemplateDefault>
          </PrivateRoute>
        }
      />
      <Route
        path="/PlanEdit/:id/*"
        exact
        element={
          <PrivateRoute role="Edit_Plan">
            <TemplateDefault>
              <PlanEdit />
            </TemplateDefault>
          </PrivateRoute>
        }
      />
      <Route
        path="/PlanDelete/:id/*"
        exact
        element={
          <PrivateRoute role="Delete_Plan">
            <TemplateDefault>
              <PlanDelete />
            </TemplateDefault>
          </PrivateRoute>
        }
      />
      <Route
        path="/Company/*"
        exact
        element={
          <PrivateRoute role="View_CompanySystem">
            <TemplateDefault>
              <Company />
            </TemplateDefault>
          </PrivateRoute>
        }
      />
      <Route
        path="/CompanyCreate/*"
        exact
        element={
          <PrivateRoute role="Create_Company">
            <TemplateDefault>
              <CompanyCreate />
            </TemplateDefault>
          </PrivateRoute>
        }
      />
      <Route
        path="/CompanyEdit/:id/*"
        exact
        element={
          <PrivateRoute role="Edit_Company">
            <TemplateDefault>
              <CompanyEdit />
            </TemplateDefault>
          </PrivateRoute>
        }
      />
      <Route
        path="/CompanyDelete/:id/*"
        exact
        element={
          <PrivateRoute role="Delete_Company">
            <TemplateDefault>
              <CompanyDelete />
            </TemplateDefault>
          </PrivateRoute>
        }
      />
      <Route
        path="/ReasonForOrder/*"
        exact
        element={
          <PrivateRoute role="View_ReasonForOrder">
            <TemplateDefault>
              <ReasonForOrder />
            </TemplateDefault>
          </PrivateRoute>
        }
      />
      <Route
        path="/ReasonForOrderCreate/*"
        exact
        element={
          <PrivateRoute role="Create_ReasonForOrder">
            <TemplateDefault>
              <ReasonForOrderCreate />
            </TemplateDefault>
          </PrivateRoute>
        }
      />
      <Route
        path="/ReasonForOrderEdit/:id/*"
        exact
        element={
          <PrivateRoute role="Edit_ReasonForOrder">
            <TemplateDefault>
              <ReasonForOrderEdit />
            </TemplateDefault>
          </PrivateRoute>
        }
      />
      <Route
        path="/ReasonForOrderDelete/:id/*"
        exact
        element={
          <PrivateRoute role="Delete_ReasonForOrder">
            <TemplateDefault>
              <ReasonForOrderDelete />
            </TemplateDefault>
          </PrivateRoute>
        }
      />
      <Route
        path="/ParametersGroup/*"
        exact
        element={
          <PrivateRoute role="View_ParametersGroup">
            <TemplateDefault>
              <ParametersGroup />
            </TemplateDefault>
          </PrivateRoute>
        }
      />
      <Route
        path="/ParametersGroupCreate/*"
        exact
        element={
          <PrivateRoute role="Create_ParametersGroup">
            <TemplateDefault>
              <ParametersGroupCreate />
            </TemplateDefault>
          </PrivateRoute>
        }
      />
      <Route
        path="/ParametersGroupEdit/:id/*"
        exact
        element={
          <PrivateRoute role="Edit_ParametersGroup">
            <TemplateDefault>
              <ParametersGroupEdit />
            </TemplateDefault>
          </PrivateRoute>
        }
      />
      <Route
        path="/ParametersGroupDelete/:id/*"
        exact
        element={
          <PrivateRoute role="Delete_ParametersGroup">
            <TemplateDefault>
              <ParametersGroupDelete />
            </TemplateDefault>
          </PrivateRoute>
        }
      />
      <Route path="/ForgotPassword" exact element={<ForgotPassword />} />
      <Route
        path="/Account/ResetPassword/*"
        element={
          <PrivateRoute role="">
            <TemplateDefault>
              <ResetPassword />
            </TemplateDefault>
          </PrivateRoute>
        }
      />
      <Route path="/account/NewUsersPassword/*" element={<NewUsersPassword />} />
      <Route
        path="/CarWeight/*"
        element={
          <PrivateRoute role="View_CarWeight">
            <TemplateDefault>
              <CarWeight />
            </TemplateDefault>
          </PrivateRoute>
        }
      />
      <Route
        path="/Driver/*"
        element={
          <PrivateRoute role="View_Driver">
            <TemplateDefault>
              <Driver />
            </TemplateDefault>
          </PrivateRoute>
        }
      />
      <Route
        path="/ListDriver/:id/*"
        element={
          <PrivateRoute role="View_Driver">
            <TemplateDefault>
              <ListDriver />
            </TemplateDefault>
          </PrivateRoute>
        }
      />
      <Route
        path="/NotaFiscal/*"
        element={
          <PrivateRoute role="Charge_NotaFiscal">
            <TemplateDefault>
              <NotaFiscal />
            </TemplateDefault>
          </PrivateRoute>
        }
      />
      <Route
        path="/ReportInventoryMovement/*"
        element={
          <PrivateRoute role="InventoryMovement_Reports">
            <TemplateDefault>
              <ReportInventoryMovement />
            </TemplateDefault>
          </PrivateRoute>
        }
      />
      <Route
        path="/ReportCurrentInventory/*"
        element={
          <PrivateRoute role="CurrentInventory_Reports">
            <TemplateDefault>
              <ReportCurrentInventory />
            </TemplateDefault>
          </PrivateRoute>
        }
      />
      <Route
        path="/ReportConference/*"
        element={
          <PrivateRoute role="Conference_Reports">
            <TemplateDefault>
              <ReportConference />
            </TemplateDefault>
          </PrivateRoute>
        }
      />
      <Route
        path="/ReportEtiqueta/*"
        element={
          <PrivateRoute role="TagsPrint_Reports">
            <TemplateDefault>
              <ReportEtiqueta />
            </TemplateDefault>
          </PrivateRoute>
        }
      />
      <Route
        path="/ReportPicking/*"
        element={
          <PrivateRoute role="Picking_Reports">
            <TemplateDefault>
              <ReportPicking />
            </TemplateDefault>
          </PrivateRoute>
        }
      />
      <Route
        path="/ReportPosition/*"
        element={
          <PrivateRoute role="Position_Reports">
            <TemplateDefault>
              <ReportPosition />
            </TemplateDefault>
          </PrivateRoute>
        }
      />
      <Route
        path="/ReportProductsActive/*"
        element={
          <PrivateRoute role="ProductsActive_Reports">
            <TemplateDefault>
              <ReportProductsActive />
            </TemplateDefault>
          </PrivateRoute>
        }
      />
      <Route
        path="/ReportPositionFree/*"
        element={
          <PrivateRoute role="PositionFree_Reports">
            <TemplateDefault>
              <ReportPositionFree />
            </TemplateDefault>
          </PrivateRoute>
        }
      />
      <Route
        path="/EmailGroup/*"
        element={
          <PrivateRoute role="View_EmailGroup">
            <TemplateDefault>
              <EmailGroup />
            </TemplateDefault>
          </PrivateRoute>
        }
      />
      <Route
        path="/ListUser/*"
        element={
          <PrivateRoute role="View_ListUser">
            <TemplateDefault>
              <ListUser />
            </TemplateDefault>
          </PrivateRoute>
        }
      />
      {/* <Route
        path="/FunctionsPositions/*"
        element={
          <PrivateRoute role="">
            <TemplateDefault>
              <FunctionsPositions />
            </TemplateDefault>
          </PrivateRoute>
        }
      /> */}
      <Route
        path="/PermissionsGroup/*"
        element={
          <PrivateRoute role="View_Permissions_Group">
            <TemplateDefault>
              <PermissionsGroup />
            </TemplateDefault>
          </PrivateRoute>
        }
      />
      <Route
        path="/PermissionsUserGroup/:id/*"
        element={
          <PrivateRoute role="">
            <TemplateDefault>
              <PermissionsUserGroup />
            </TemplateDefault>
          </PrivateRoute>
        }
      />
      <Route
        path="/PermissionsUser/*"
        element={
          <PrivateRoute role="">
            <TemplateDefault>
              <PermissionsUser />
            </TemplateDefault>
          </PrivateRoute>
        }
      />
      <Route
        path="/permissionsUser/:companyIdParams/:subCompanyIdParams/:userIdParams/*"
        element={
          <PrivateRoute role="">
            <TemplateDefault>
              <PermissionsUser />
            </TemplateDefault>
          </PrivateRoute>
        }
      />
      <Route
        path="/CountStockDesk/*"
        element={
          <PrivateRoute role="View_CountStockDesk">
            <TemplateDefault>
              <CountStockDesk />
            </TemplateDefault>
          </PrivateRoute>
        }
      />{' '}
      <Route
        path="/CountStockDesk/:typeOrder/:movimentationOrderId/*"
        element={
          <PrivateRoute role="">
            <TemplateDefault>
              <CountStockDesk />
            </TemplateDefault>
          </PrivateRoute>
        }
      />
      <Route
        path="/NewSaleOrder/*"
        element={
          <PrivateRoute role="">
            <TemplateDefault>
              <NewSaleOrder />
            </TemplateDefault>
          </PrivateRoute>
        }
      />
      <Route
        path="/NewSaleOrder/:typeOrder/:movimentationOrderId/*"
        element={
          <PrivateRoute role="">
            <TemplateDefault>
              <NewSaleOrder />
            </TemplateDefault>
          </PrivateRoute>
        }
      />
      <Route
        path="/Helpdesk/*"
        element={
          <PrivateRoute role="View_Dahsboard_HelpDesk">
            <TemplateDefault>
              <DahsboardHelpe />
            </TemplateDefault>
          </PrivateRoute>
        }
      />
      <Route
        path="/Ticket/*"
        element={
          <PrivateRoute role="View_Ticket">
            <TemplateDefault>
              <Ticket />
            </TemplateDefault>
          </PrivateRoute>
        }
      />
      <Route
        path="/TicketCreate/*"
        element={
          <PrivateRoute role="Create_TicketSuport">
            <TemplateDefault>
              <TicketCreatePage />
            </TemplateDefault>
          </PrivateRoute>
        }
      />
      <Route
        path="/Order/*"
        element={
          <PrivateRoute role="View_Order">
            <TemplateDefault>
              <Order />
            </TemplateDefault>
          </PrivateRoute>
        }
      />
      <Route
        path="/Onboarding/*"
        element={
          <PrivateRoute role="Menu_Onboarding	">
            <TemplateDefault>
              <Onboarding />
            </TemplateDefault>
          </PrivateRoute>
        }
      />
      <Route
        path="/DetailsOnboarding/*"
        element={
          <PrivateRoute role="Menu_Onboarding">
            <TemplateDefault>
              <DetailsOnboarding />
            </TemplateDefault>
          </PrivateRoute>
        }
      />
      <Route
        path="/TicketSuport/*"
        element={
          <PrivateRoute role="View_TicketSuport">
            <TemplateDefault>
              <TicketSuport />
            </TemplateDefault>
          </PrivateRoute>
        }
      />
      <Route
        path="/TicketViewResponse/:movimentationOrderTicketId/*"
        element={
          <PrivateRoute role="View_TicketSuport">
            <TemplateDefault>
              <TicketViewResponse />
            </TemplateDefault>
          </PrivateRoute>
        }
      />
      <Route
        path="/TicketViewReponseClient/:movimentationOrderTicketId/*"
        element={
          <PrivateRoute role="View_Ticket">
            <TemplateDefault>
              <TicketViewReponseClient />
            </TemplateDefault>
          </PrivateRoute>
        }
      />
      <Route
        path="/Attendants/*"
        element={
          <PrivateRoute role="View_Attendants">
            <TemplateDefault>
              <Attendants />
            </TemplateDefault>
          </PrivateRoute>
        }
      />
      <Route
        path="/TemplateEquipament/*"
        element={
          <PrivateRoute role="View_TemplateEquipament">
            <TemplateDefault>
              <TemplateEquipament />
            </TemplateDefault>
          </PrivateRoute>
        }
      />
      <Route
        path="/TemplateTags/*"
        element={
          <PrivateRoute role="View_TemplateTags">
            <TemplateDefault>
              <TemplateTags />
            </TemplateDefault>
          </PrivateRoute>
        }
      />
      <Route
        path="/Rejeito/*"
        element={
          <PrivateRoute role="View_Rejeito">
            <TemplateDefault>
              <Rejeito />
            </TemplateDefault>
          </PrivateRoute>
        }
      />
      <Route
        path="/DashboardMock/*"
        element={
          <PrivateRoute role="">
            <TemplateDefault>
              <DashboardMock />
            </TemplateDefault>
          </PrivateRoute>
        }
      />
      <Route
        path="/SensorAndPerson/*"
        element={
          <PrivateRoute role="RTLS">
            <TemplateDefault>
              <SensorAndPerson />
            </TemplateDefault>
          </PrivateRoute>
        }
      />
      <Route
        path="/GatewayLocation/*"
        element={
          <PrivateRoute role="RTLS">
            <TemplateDefault>
              <GatewayLocation />
            </TemplateDefault>
          </PrivateRoute>
        }
      />
      <Route
        path="/ReportPeople/*"
        element={
          <PrivateRoute role="">
            <TemplateDefault>
              <ReportPeople />
            </TemplateDefault>
          </PrivateRoute>
        }
      />
      <Route
        path="/DashboardRTLS/*"
        element={
          <PrivateRoute role="">
            <TemplateDefault>
              <DashboardRTLS />
            </TemplateDefault>
          </PrivateRoute>
        }
      />
      <Route
        path="/ReportMovimentationStockInfo/*"
        element={
          <PrivateRoute role="ReportMovimentationStockInfo">
            <TemplateDefault>
              <ReportMovimentationStockInfo />
            </TemplateDefault>
          </PrivateRoute>
        }
      />
      <Route
        path="/Gpt/*"
        element={
          <PrivateRoute role="BihandsChat">
            <TemplateDefault>
              <Gpt />
            </TemplateDefault>
          </PrivateRoute>
        }
      />
      <Route
        path="/CountStockCategory/*"
        element={
          <PrivateRoute role="View_CountStockCategory">
            <TemplateDefault>
              <CountStockCategory />
            </TemplateDefault>
          </PrivateRoute>
        }
      />
      <Route
        path="/UpdateQuantity/*"
        element={
          <PrivateRoute role="View_Update_Quantity">
            <TemplateDefault>
              <AssociationSerials />
            </TemplateDefault>
          </PrivateRoute>
        }
      />
      <Route
        path="/ReportCountStockWms/*"
        element={
          <PrivateRoute role="View_ReportCountStockWms">
            <TemplateDefault>
              <ReportCountStockWms />
            </TemplateDefault>
          </PrivateRoute>
        }
      />
      <Route
        path="/ReportConferenceActions/*"
        element={
          <PrivateRoute role="View_ReportConferenceActions">
            <TemplateDefault>
              <ReportConferenceActions />
            </TemplateDefault>
          </PrivateRoute>
        }
      />
      <Route
        path="/DashboardSebrae/*"
        element={
          <PrivateRoute role="View_DashboardSebrae">
            <TemplateDefault>
              <DashboardSebrae />
            </TemplateDefault>
          </PrivateRoute>
        }
      />
      <Route
        path="/UploadDepreciation/*"
        element={
          <PrivateRoute role="View_UploadDepreciation">
            <TemplateDefault>
              <UploadDepreciation />
            </TemplateDefault>
          </PrivateRoute>
        }
      />
      <Route
        path="/PdvClient/*"
        element={
          <PrivateRoute role="">
            <TemplateDefault>
              <PdvClient />
            </TemplateDefault>
          </PrivateRoute>
        }
      />
      <Route
        path="/InventoryClient*"
        element={
          <PrivateRoute role="View_InventoryClient">
            <TemplateDefault>
              <InventoryClient />
            </TemplateDefault>
          </PrivateRoute>
        }
      />
      <Route
        path="/ConferenceClient*"
        element={
          <PrivateRoute role="View_CountStockClient">
            <TemplateDefault>
              <ConferenceClient />
            </TemplateDefault>
          </PrivateRoute>
        }
      />
      <Route
        path="/CountStockClient/:id*"
        element={
          <PrivateRoute role="View_CountStockClient">
            <TemplateDefault>
              <CountStockClient />
            </TemplateDefault>
          </PrivateRoute>
        }
      />
      <Route
        path="/uploadImage"
        element={
          <PrivateRoute role="View_Product">
            <TemplateDefault>
              <UploadImage />
            </TemplateDefault>
          </PrivateRoute>
        }
      />
      <Route
        path="/chargeRecipeMachine"
        element={
          <PrivateRoute role="View_Product">
            <TemplateDefault>
              <ChargeRecipeMachine />
            </TemplateDefault>
          </PrivateRoute>
        }
      />
      <Route
        path="/CountEntryStock/:typeOrder/:orderId/:subCompanyId*"
        element={
          <PrivateRoute role="">
            <TemplateDefault>
              <CountEntryStock />
            </TemplateDefault>
          </PrivateRoute>
        }
      />
      <Route
        path="/CountStock"
        element={
          <PrivateRoute role="">
            <TemplateDefault>
              <CountStock />
            </TemplateDefault>
          </PrivateRoute>
        }
      />
      <Route
        path="/CountStockOut/:typeOrder/:orderId/:subCompanyId*"
        element={
          <PrivateRoute role="">
            <TemplateDefault>
              <CountStockOut />
            </TemplateDefault>
          </PrivateRoute>
        }
      />
      <Route
        path="/TransferBetweenBranches/:typeOrder/:orderId/:subCompanyId/:subCompanyIdTo*"
        element={
          <PrivateRoute role="">
            <TemplateDefault>
              <TransferBetweenBranches />
            </TemplateDefault>
          </PrivateRoute>
        }
      />
      <Route
        path="/TransferBetweenAlocations/:typeOrder/:orderId/:subCompanyIdFrom/:subCompanyIdTo*"
        element={
          <PrivateRoute role="">
            <TemplateDefault>
              <TransferBetweenAlocations />
            </TemplateDefault>
          </PrivateRoute>
        }
      />
      <Route
        path="/PickToLight/*"
        exact
        element={
          <PrivateRoute role="View_Parts_conference">
            <TemplateDefault>
              {/* <PickToLight /> */}
              <PickingConference />
            </TemplateDefault>
          </PrivateRoute>
        }
      />
      <Route
        path="/TransferBetweenBranches/"
        element={
          <PrivateRoute role="">
            <TemplateDefault>
              <TransferBetweenBranches />
            </TemplateDefault>
          </PrivateRoute>
        }
      />
      <Route
        path="/TransferBetweenAlocations/"
        element={
          <PrivateRoute role="">
            <TemplateDefault>
              <TransferBetweenAlocations />
            </TemplateDefault>
          </PrivateRoute>
        }
      />
      {/* novo sub menu */}
      <Route
        path="/machineRecipe"
        element={
          <PrivateRoute role="">
            <TemplateDefault>
              <MachineRecipe />
            </TemplateDefault>
          </PrivateRoute>
        }
      />
      {/* 
      <Route path="/account/NewUsersPassword/" element={<NewUsersPassword/>} />

      <PrivateRoute path="/DepositDelete/:id/*" exact element={<DepositDelete/>} />
      
      CountStockOut
            */}
    </BrowserRouter>
  )
}

export default AppRoutes
