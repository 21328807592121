import React, { useState, useEffect, useContext } from 'react'
import {
  Box,
  Typography,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  TextField,
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  IconButton,
  Snackbar,
  Alert,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Dialog,
  DialogActions,
  DialogTitle,
  styled,
  tableCellClasses,
  TablePagination,
} from '@mui/material'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import EditIcon from '@mui/icons-material/Edit'
import DeleteIcon from '@mui/icons-material/Delete'
import HelpIcon from '@mui/icons-material/Help'
import { Context } from '../../../context/AuthContext'
import {
  getAllMachineReceipts,
  getMachineReceipt,
  createMachineReceipt,
  editMachineReceipt,
  deleteMachineReceipt,
} from '../../../services/machineRecipeServices'
import { AiFillPlusCircle } from 'react-icons/ai'
import { colorTableDefault } from '../../TemplateDefault'
import ViewElearning from '../../ViewElearning'
import PermissionComponent from '../../../permissions'

const MachineRecipe = () => {
  const { subCompany, nameUrl } = useContext(Context)
  const token = localStorage.getItem('token')
  const [subCompanyId, setSubCompanyId] = useState('')
  const [registrationSubCompanyId, setRegistrationSubCompanyId] = useState('')
  const [filterSubCompanyId, setFilterSubCompanyId] = useState('')
  const [receipts, setReceipts] = useState([])
  const [currentReceipt, setCurrentReceipt] = useState({
    sequenceReceipt: '',
    description: '',
    internalParNumber: '',
  })
  const [isEditing, setIsEditing] = useState(false)
  const [alert, setAlert] = useState({ open: false, message: '', severity: 'success' })
  const [expanded, setExpanded] = useState(false)
  const [open, setOpen] = useState(false)
  const [idDelete, setIdDelete] = useState('')
  const [viewElearning, setViewElearning] = useState(false)
  const [page, setPage] = useState(0)
  const [rowsPerPage, setRowsPerPage] = useState(10)
  const [totalData, setTotalData] = useState(0)
  const [search, setSearch] = useState('')
  const [searching, setSearching] = useState(false)

  const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: colorTableDefault,
      color: theme.palette.common.white,
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: 14,
      padding: '8px',
    },
  }))

  const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.action.hover,
    },
    '&:last-child td, &:last-child th': {
      border: 0,
    },
  }))

  useEffect(() => {
    if (filterSubCompanyId) {
      fetchReceipts()
    }
  }, [filterSubCompanyId, page, rowsPerPage])

  useEffect(() => {
    handleFetchData()
  }, [search])

  const fetchReceipts = async () => {
    if (!filterSubCompanyId) return // Não busca se não houver filial selecionada
    try {
      const response = await getAllMachineReceipts(token, nameUrl, {
        SubCompanyId: filterSubCompanyId,
        limit: rowsPerPage,
        offset: page * rowsPerPage,
      })
      setReceipts(response.data.result)
      setTotalData(response.data.total)
    } catch (error) {
      console.error('Erro ao buscar recibos:', error)
      setAlert({ open: true, message: 'Erro ao buscar recibos', severity: 'error' })
    }
  }

  const handleFetchData = async (subCompanyId = filterSubCompanyId) => {
    if (subCompanyId) {
      setSearching(true)
      try {
        const response = await getAllMachineReceipts(token, nameUrl, {
          SubCompanyId: subCompanyId,
          limit: rowsPerPage,
          offset: 0,
          search: search,
        })
        setReceipts(response.data.result)
        setTotalData(response.data.total)
        setPage(0)
      } catch (error) {
        console.error('Erro ao buscar recibos:', error)
        setAlert({ open: true, message: 'Erro ao buscar recibos', severity: 'error' })
      }
      setSearching(false)
    }
  }

  const handleSubmit = async e => {
    e.preventDefault()
    try {
      if (isEditing) {
        await editMachineReceipt(token, nameUrl, currentReceipt)
        setAlert({ open: true, message: 'Recibo atualizado com sucesso', severity: 'success' })
      } else {
        await createMachineReceipt(token, nameUrl, {
          ...currentReceipt,
          subCompanyId: registrationSubCompanyId,
        })
        setAlert({ open: true, message: 'Recibo criado com sucesso', severity: 'success' })
        setFilterSubCompanyId(registrationSubCompanyId) // Atualiza o filtro com a filial do novo recibo
      }
      setCurrentReceipt({ sequenceReceipt: '', description: '', internalParNumber: '' })
      setIsEditing(false)
      setExpanded(false)
      if (filterSubCompanyId) {
        fetchReceipts()
      }
    } catch (error) {
      console.error('Erro ao submeter recibo:', error)
      setAlert({ open: true, message: 'Erro ao submeter recibo', severity: 'error' })
    }
  }

  const handleEdit = receipt => {
    setCurrentReceipt(receipt)
    setIsEditing(true)
    setExpanded(true)
  }

  const handleDelete = async () => {
    try {
      await deleteMachineReceipt(token, nameUrl, { receiptMachineId: idDelete })
      setAlert({ open: true, message: 'Recibo deletado com sucesso', severity: 'success' })
      fetchReceipts()
      setOpen(false)
    } catch (error) {
      console.error('Erro ao deletar recibo:', error)
      setAlert({ open: true, message: 'Erro ao deletar recibo', severity: 'error' })
    }
  }

  const handleChangePage = (event, newPage) => {
    setPage(newPage)
  }

  const handleChangeRowsPerPage = event => {
    setRowsPerPage(parseInt(event.target.value, 10))
    setPage(0)
  }

  const handleOpenDeleteDialog = receiptMachineId => {
    setIdDelete(receiptMachineId)
    setOpen(true)
  }

  return (
    <Box sx={{ mt: 7.5 }}>
      <Snackbar
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        open={alert.open}
        autoHideDuration={6000}
        onClose={() => setAlert({ ...alert, open: false })}
      >
        <Alert onClose={() => setAlert({ ...alert, open: false })} severity={alert.severity}>
          {alert.message}
        </Alert>
      </Snackbar>

      <ViewElearning pageName={'MachineRecipe'} open={viewElearning} setOpen={setViewElearning} />

      <Box sx={{ width: 1, display: 'flex', justifyContent: 'space-between' }}>
        <Typography variant="h5">Recibos de Máquinas</Typography>
        <IconButton sx={{ justifySelf: 'flex-end' }} onClick={() => setViewElearning(true)}>
          <HelpIcon />
        </IconButton>
      </Box>

      <Accordion expanded={expanded}>
        <AccordionSummary expandIcon={<ExpandMoreIcon />} onClick={() => setExpanded(!expanded)}>
          <Typography>{isEditing ? 'Editar Recibo' : 'Cadastrar Recibo'}</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <form onSubmit={handleSubmit}>
            <FormControl sx={{ width: '100%', mb: 2 }}>
              <InputLabel id="registration-subcompId-label">Filial para Cadastro</InputLabel>
              <Select
                label="Selecione a empresa para cadastro"
                onChange={e => setRegistrationSubCompanyId(e.target.value)}
                value={registrationSubCompanyId}
              >
                {subCompany.map(item => (
                  <MenuItem key={item.subCompanyId} value={item.subCompanyId}>
                    {item.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <Box sx={{ display: 'flex', gap: 2, my: 2 }}>
              <TextField
                label="Sequência do Recibo"
                value={currentReceipt.sequenceReceipt}
                onChange={e =>
                  setCurrentReceipt({ ...currentReceipt, sequenceReceipt: e.target.value })
                }
                required
                sx={{ width: '30%' }}
              />
              <TextField
                label="Descrição"
                value={currentReceipt.description}
                onChange={e =>
                  setCurrentReceipt({ ...currentReceipt, description: e.target.value })
                }
                required
                sx={{ width: '30%' }}
              />
              <TextField
                label="Número Interno da Peça"
                value={currentReceipt.internalParNumber}
                onChange={e =>
                  setCurrentReceipt({ ...currentReceipt, internalParNumber: e.target.value })
                }
                required
                sx={{ width: '30%' }}
              />
              <Button type="submit" variant="contained">
                {isEditing ? 'Atualizar' : 'Criar'}
              </Button>
            </Box>
          </form>
        </AccordionDetails>
      </Accordion>

      <Box sx={{ display: 'flex', alignItems: 'center', gap: 2, mt: 2 }}>
        <FormControl sx={{ width: '50%' }}>
          <InputLabel id="filter-subcompId-label">Filtrar por Filial</InputLabel>
          <Select
            label="Filtrar por Filial"
            onChange={e => {
              setFilterSubCompanyId(e.target.value)
              handleFetchData(e.target.value)
            }}
            value={filterSubCompanyId}
          >
            {subCompany.map(item => (
              <MenuItem key={item.subCompanyId} value={item.subCompanyId}>
                {item.name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>

        <TextField
          variant="outlined"
          placeholder="Procurar por Recibo"
          type="search"
          onInput={e => setSearch(e.target.value)}
          sx={{ flex: 1 }}
        />
      </Box>

      <TableContainer component={Paper} sx={{ mt: '2rem' }}>
        <Table aria-label="customized table">
          <TableHead>
            <TableRow sx={{ backgroundColor: '#EA931A' }}>
              <TableCell sx={{ color: 'white' }}>Editar</TableCell>
              <TableCell sx={{ color: 'white' }}>Apagar</TableCell>
              <TableCell sx={{ color: 'white' }}>Sequência do Recibo</TableCell>
              <TableCell sx={{ color: 'white' }}>Descrição</TableCell>
              <TableCell sx={{ color: 'white' }}>Número Interno da Peça</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {receipts.map(receipt => (
              <TableRow key={receipt.receiptMachineId}>
                <TableCell>
                  <IconButton onClick={() => handleEdit(receipt)}>
                    <EditIcon />
                  </IconButton>
                </TableCell>
                <TableCell>
                  <IconButton onClick={() => handleOpenDeleteDialog(receipt.receiptMachineId)}>
                    <DeleteIcon />
                  </IconButton>
                </TableCell>
                <TableCell>{receipt.sequenceReceipt}</TableCell>
                <TableCell>{receipt.description}</TableCell>
                <TableCell>{receipt.internalParNumber}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>

      <Dialog
        open={open}
        onClose={() => setOpen(false)}
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle>Tem certeza que deseja excluir esse item?</DialogTitle>
        <DialogActions>
          <Button onClick={() => setOpen(false)}>Cancelar</Button>
          <Button onClick={handleDelete}>Sim</Button>
        </DialogActions>
      </Dialog>
    </Box>
  )
}

export default MachineRecipe
