import axios from 'axios'

//Transferências Entre Filiais
export const getTransferOrderService = async (token, nameUrl, limit, offset, order) => {
  let orderToString
  if (order) {
    orderToString = 'asc'
  } else {
    orderToString = 'desc'
  }

  const response = await axios.get(
    `/${nameUrl}/api/MovimentationOrder/GetTransferPagination?limit=${limit}&offset=${offset}&Order=${orderToString}`,
    {
      headers: {
        Authorization: 'Bearer ' + token,
      },
    }
  )

  return response
}

export const getUniqueTransferOrderService = async (token, nameUrl, movimentationOrderId) => {
  const response = await axios.post(
    `/${nameUrl}/api/MovimentationOrder/GetId`,
    {
      movimentationOrderId: movimentationOrderId,
    },
    {
      headers: {
        Authorization: 'Bearer ' + token,
      },
    }
  )
  return response
}

export const createTransferOrderService = async (token, nameUrl, transferOrder) => {
  const response = await axios.post(
    `/${nameUrl}/api/MovimentationOrder/SendTransference`,
    {
      reasonfororderId: transferOrder.reasonfororderId,
      movimentationOrderNumberOtherSystem: transferOrder.movimentationOrderNumberOtherSystem,
      subCompanyIdFrom: transferOrder.subCompanyIdFrom,
      subCompanyIdTo: transferOrder.subCompanyIdTo,
      movimentationOrderNumberBihands: transferOrder.movimentationOrderNumberBihands,
      movimentationOrderItemCollection: transferOrder.movimentationOrderItemCollection,
    },
    {
      headers: {
        Authorization: 'Bearer ' + token,
      },
    }
  )

  return response
}

export const editTransferOrderService = async (token, nameUrl, transferOrder) => {
  const response = await axios.post(
    `/${nameUrl}/api/MovimentationOrderItem/AddProductMovimentationOrderList`,

    transferOrder,

    {
      headers: {
        Authorization: 'Bearer ' + token,
      },
    }
  )

  return response
}

export const calcPaleteService = async (token, nameUrl, transferOrder) => {
  const response = await axios.get(
    `/${nameUrl}/api/MovimentationOrderItemERP/GetCalcInfoPalete?MovimentationOrderId=${transferOrder}`,
    {
      headers: {
        Authorization: 'Bearer ' + token,
      },
    }
  )

  return response
}

export const getIdMovimentationERP = async (token, nameUrl, id) => {
  const response = await axios.get(
    `/${nameUrl}/api/MovimentationOrderItemERP/Get?MovimentationOrderId=${id}`,
    {
      headers: {
        Authorization: 'Bearer ' + token,
      },
    }
  )

  return response
}

//Expedição de Peças
export const getExpeditionOrderService = async (token, nameUrl, limit, offset, order) => {
  let orderToString
  if (order) {
    orderToString = 'asc'
  } else {
    orderToString = 'desc'
  }

  const response = await axios.get(
    `/${nameUrl}/api/MovimentationOrder/GetExpeditionPagination?limit=${limit}&offset=${offset}&Order=${orderToString}`,
    {
      headers: {
        Authorization: 'Bearer ' + token,
      },
    }
  )

  return response
}

export const createExpeditionOrderService = async (token, nameUrl, expeditionOrder) => {
  const response = await axios.post(
    `/${nameUrl}/api/MovimentationOrder/SendExpedition`,
    expeditionOrder,
    {
      headers: {
        Authorization: 'Bearer ' + token,
      },
    }
  )

  return response
}

//Nova ExpeditionOrder Create
export const createOrderFullService = async (token, nameUrl, data) => {
  const dados = {
    movimentationOrderNumberOtherSystem: data.movimentationOrderNumberOtherSystem,
    typeOrder: 4,
    reasonfororderId: data.reasonfororderId,
    observation: data.observation,
    quantityFaction: data.quantityFaction,
    quantityTotal: data.quantityTotal,
    subcompanyCNPJ: data.subcompanyCNPJ,
    itensOrder: data.itensOrder,
  }

  const response = await axios.post(`/${nameUrl}/api/MovimentationOrder/CreateOrderFull`, data, {
    headers: { Authorization: 'Bearer ' + token },
  })
  return response
}

export const createOrderFullUpdateService = async (token, nameUrl, data) => {
  const response = await axios.post(`/${nameUrl}/api/MovimentationOrder/CreateOrderFull`, data, {
    headers: { Authorization: 'Bearer ' + token },
  })
  return response
}

export const createTypeOrder = async (token, nameUrl, data) => {
  const response = await axios.post(`/${nameUrl}/api/MovimentationOrder/CreateOrderFullERP`, data, {
    headers: { Authorization: 'Bearer ' + token },
  })
  return response
}

//Connfirmção de Pedido
export const confirmOrderService = async (token, nameUrl, movimentationOrderId) => {
  const response = await axios.post(
    `/${nameUrl}/api/MovimentationOrder/SendConfirmOrder`,
    {
      movimentationOrderId: movimentationOrderId,
    },
    {
      headers: {
        Authorization: 'Bearer ' + token,
      },
    }
  )

  return response
}

//conferencia de produtos

export const getConference = async (token, nameUrl, subCompanyId) => {
  // const response = await axios.get(
  //   `/${nameUrl}/api/MovimentationOrder/GetConferenceFinish`,
  const response = await axios.get(
    `/${nameUrl}/api/MovimentationOrderMobile/GetPaginationOrdersConferenceMobile?SubCompanyId=${subCompanyId}&search=&limit=240&offset=0&order=desc`,
    {
      headers: {
        Authorization: 'Bearer ' + token,
      },
    }
  )

  return response
}
// api/MovimentationOrderMobile/GetOrdersitensErpMobile
export const getConferenceFinish = async (token, nameUrl) => {
  const response = await axios.get(`/${nameUrl}/api/MovimentationOrder/GetConferenceFinish`, {
    headers: {
      Authorization: 'Bearer ' + token,
    },
  })

  return response
}

export const getOrderItems = async (token, nameUrl, subCompanyId, movimentationOrderId) => {
  const response = await axios.get(
    `/${nameUrl}/api/MovimentationOrderMobile/GetOrdersitensErpMobile?SubCompanyId=${subCompanyId}&MovimentationOrderNumberOtherSystem=${movimentationOrderId}`,
    {
      headers: {
        Authorization: 'Bearer ' + token,
      },
    }
  )
  return response
}

export const sendConference = async (token, nameUrl, data) => {
  const response = await axios.post(
    `/${nameUrl}/api/MovimentationOrder/SendConferenceOther`,
    data,
    {
      headers: {
        Authorization: 'Bearer ' + token,
        'Content-Type': 'application/json',
      },
    }
  )
  return response
}

export const getConferenceErp = async (token, nameUrl) => {
  const response = await axios.get(`/${nameUrl}/api/MovimentationOrder/GetConferenceERP`, {
    headers: {
      Authorization: 'Bearer ' + token,
    },
  })

  return response
}

export const orderMovimentation = async (token, nameUrl) => {
  const response = await axios.post(
    `/${nameUrl}/api/MovimentationOrderItem/AddProductMovimentationOrder`,
    {
      idProductPartner: 'string',
      serial: 'string',
      createDate: '2022-08-21T22:23:48.246Z',
      productName: 'string',
      price: 'string',
      orderTypeInfo: 0,
      sku: 'string',
      movimentationOrderId: '3fa85f64-5717-4562-b3fc-2c963f66afa6',
      productItensId: '3fa85f64-5717-4562-b3fc-2c963f66afa6',
      createDateRead: '2022-08-21T22:23:48.246Z',
    },
    {
      headers: {
        Authorization: 'Bearer ' + token,
      },
    }
  )

  return response
}

//solicitar ao sistema fazer a adição de items para conferencia
export const addConfereceSimulation = async token => {
  const config = {
    headers: {
      'Content-Type': 'application/json',
      Accept: '*/*',
      Authorization: 'Bearer ' + token,
    },
  }

  //https://integration.bihands.com.br/api/Simulation/SimulationAddItemOrder
  const response = await axios.post(
    `http://bihandsintegration.azurewebsites.net/api/Simulation/SimulationAddItemOrder`,
    {
      subcompanyCNPJ: 39660079000112,
      movimentationOrderNumberOtherSystem: '2323123',
      movimentationOrderNumberBihands: '2312312',
      productQuantity: 10,
    },
    config
  )

  return response
}

//fazer a adição de items para conferencia manualmente
export const addConfereceHands = async token => {
  const response = await axios.post(
    `​https://integration.bihands.com.br/api​/MovimentationOrder​/CreateOrder`,
    {
      subcompanyCNPJ: 39660079000112,
      movimentationOrderNumberOtherSystem: '1234567',
      typeOrder: 11,
    }
  )

  return response
}

//teste
export const getOrdersMovimentation = async (token, nameUrl) => {
  const response = await axios.get(`/${nameUrl}/api/MovimentationOrder/GetOrder`, {
    headers: {
      Authorization: 'Bearer ' + token,
    },
  })
  return response
}

export const deleteProductMovimentationOrderByIdPartner = async (
  token,
  nameUrl,
  orderId,
  idProduct
) => {
  const response = await axios.get(
    `/${nameUrl}/api/MovimentationOrderItem/DeleteProductMovimentationOrderByIdPartener?MovimentationOrderId=${orderId}&IdProductPartner=${idProduct}`,
    { headers: { Authorization: 'Bearer ' + token } }
  )
  return response
}

export const deleteProductMovimentationOrderItemByIdPartner = async (
  token,
  nameUrl,
  orderId,
  idProduct
) => {
  const response = await axios.get(
    `/${nameUrl}/api/MovimentationOrderItem/DeleteProductMovimentationOrderByIdPartener?MovimentationOrderId=${orderId}&IdProductPartner=${idProduct}`,
    { headers: { Authorization: 'Bearer ' + token } }
  )
  return response
}

export const deleteProductListMovimentations = async (token, nameUrl, data) => {
  const response = await axios.post(
    `/${nameUrl}/api/MovimentationOrderItem/DeleteProductMovimentationOrder`,
    data,
    {
      headers: { Authorization: 'Bearer ' + token },
    }
  )
  return response
}
