import axios from 'axios'

export const getAllMachineReceipts = async (token, nameUrl, data) => {
  const response = await axios.get(
    `/${nameUrl}/api/MachineReceipt/GetAll?SubCompanyId=${data.SubCompanyId}&limit=${
      data.limit
    }&offset=${data.offset}${data.search ? `&search=${data.search}` : ''}`,
    { headers: { Authorization: 'Bearer ' + token } }
  )
  return response
}

export const getMachineReceipt = async (token, nameUrl, id) => {
  const response = await axios.get(`/${nameUrl}/api/MachineReceipt/Get/${id}`, {
    headers: { Authorization: 'Bearer ' + token },
  })
  return response
}

export const createMachineReceipt = async (token, nameUrl, data) => {
  const response = await axios.post(`/${nameUrl}/api/MachineReceipt/Create`, data, {
    headers: { Authorization: 'Bearer ' + token },
  })
  return response
}

export const editMachineReceipt = async (token, nameUrl, data) => {
  const response = await axios.post(`/${nameUrl}/api/MachineReceipt/Edit`, data, {
    headers: { Authorization: 'Bearer ' + token },
  })
  return response
}

export const deleteMachineReceipt = async (token, nameUrl, data) => {
  const response = await axios.post(`/${nameUrl}/api/MachineReceipt/Delete`, data, {
    headers: { Authorization: 'Bearer ' + token },
  })
  return response
}
