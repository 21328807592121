import {
  Alert,
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  Grid,
  IconButton,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  Skeleton,
  Snackbar,
  styled,
  Table,
  TableBody,
  TableCell,
  tableCellClasses,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TextField,
  Tooltip,
  Typography,
} from '@mui/material'
import HelpIcon from '@mui/icons-material/Help'
import { colorTableDefault } from '../../TemplateDefault'
import { useContext, useEffect, useState } from 'react'
import { Context } from '../../../context/AuthContext'
import { cancelReading, getAllReadings, useGetAllReadings } from '../../../services/sisplanService'
import DeleteForeverIcon from '@mui/icons-material/DeleteForever'
import EditIcon from '@mui/icons-material/Edit'
import { useMutation } from '@tanstack/react-query'
import { useSearchParams } from 'react-router-dom'
import SaveIcon from '@mui/icons-material/Save'

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: colorTableDefault,
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}))

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: theme.palette.action.hover,
  },

  '&:last-child td, &:last-child th': {
    border: 0,
  },
}))

const ListReading = () => {
  const token = localStorage.getItem('token')
  const { subCompany, company, userId, nameUrl, urlLogo } = useContext(Context)
  const [companyId, setCompanyId] = useState('')
  const [dataReadings, setDataReadings] = useState([])
  const [startDate, setStartDate] = useState('')
  const [endDate, setEndDate] = useState('')
  const [cancel, setCancel] = useState('S')
  const [imported, setImported] = useState('N')
  const [page, setPage] = useState(1)
  const [subCompanyId, setSubCompanyId] = useState('')
  const [isSearchActive, setIsSearchActive] = useState(false)
  const [openDialog, setOpenDialog] = useState(false)
  const [selectedRow, setSelectedRow] = useState(null)
  const [currentPage, setCurrentPage] = useState(0)
  const [searchParams, setSearchParams] = useSearchParams()
  const [rowsPerPage, setRowsPerPage] = useState(10)
  const [severity, setSeverity] = useState('warning')
  const [title, setTitle] = useState('')
  const [alerta, setAlerta] = useState(false)
  const vertical = 'top'
  const horizontal = 'center'

  // USAR AS CONSTS E FUNCTIONS ABAIXO CASO O DANILO QUEIRA EDITAR MAIS ALGUMA COISA.
  // const [editRowId, setEditRowId] = useState(null)
  // const [editedRow, setEditedRow] = useState({})

  // const handleEditClick = row => {
  //   setEditRowId(row.id)
  //   setEditedRow(row)
  // }

  // const handleSaveClick = () => {
  //   setEditRowId(null)
  // }

  // const handleInputChange = (field, value) => {
  //   setEditedRow(prev => ({
  //     ...prev,
  //     [field]: value,
  //   }))
  // }

  const { data, isLoading, isError } = useGetAllReadings(
    token,
    nameUrl,
    startDate,
    endDate,
    cancel,
    imported,
    companyId,
    isSearchActive
  )

  const {
    mutate: handleCancelReading,
    isLoading: isCancelLoading,
    isError: isCancelError,
  } = useMutation({
    mutationFn: cancelReading,
    onSuccess: data => {
      setTitle(data.message)
      setAlerta(true)
      setSeverity('success')
      // console.log('Cancelamento bem-sucedido', data)
    },
    onError: error => {
      setTitle(error.message)
      setAlerta(true)
      setSeverity('warning')
      // console.error('Erro ao cancelar leitura', error)
    },
  })

  const handleSearch = () => {
    setIsSearchActive(true)
  }

  const handleConfirmCancel = () => {
    if (selectedRow) {
      const updatedRow = { ...selectedRow, cancelado: cancel }
      handleCancelReading({
        token,
        nameUrl,
        data: updatedRow,
        // companyId: companyId,
      })
      setOpenDialog(false)
    }
  }

  const handleCloseDialog = () => {
    setOpenDialog(false)
  }

  const handleCancel = row => {
    setSelectedRow(row)
    setOpenDialog(true)
  }

  useEffect(() => {
    if (company?.companyId) {
      setCompanyId(company.companyId)
    }
  }, [company])

  useEffect(() => {
    if (isSearchActive) {
      setDataReadings(data)
    } else {
      setDataReadings([])
    }
  }, [isSearchActive, data, subCompanyId])

  const handleChangePage = (event, newPage) => {
    setCurrentPage(newPage)
    setSearchParams({ ...searchParams, page: newPage + 1 })
  }

  const handleChangeRowsPerPage = event => {
    const newRowsPerPage = +event.target.value
    setRowsPerPage(newRowsPerPage)
    setCurrentPage(0)
  }

  const startIndex = currentPage * rowsPerPage
  const endIndex = startIndex + rowsPerPage
  const paginatedData = dataReadings?.slice(startIndex, endIndex)

  const handleClose = () => {
    setAlerta(false)
  }

  return (
    <>
      <Box sx={{ width: 1, display: 'flex', justifyContent: 'space-between', mt: 10 }}>
        <Typography variant="h5">Status de leituras</Typography>
        <IconButton sx={{ justifySelf: 'flex-end' }} onClick={() => console.log('')}>
          <HelpIcon />
        </IconButton>
      </Box>
      <Box sx={{ width: 1, display: 'flex', justifyContent: 'space-between' }}>
        <Snackbar
          anchorOrigin={{ vertical, horizontal }}
          open={alerta}
          onClose={handleClose}
          key={vertical + horizontal}
        >
          <Alert onClose={handleClose} severity={severity} sx={{ width: '100%' }}>
            {title}
          </Alert>
        </Snackbar>
      </Box>

      <Grid container component={Paper}>
        <Grid item xs={12} sm={12} md={4} lg={4} padding={2}>
          <FormControl sx={{ width: '100%', mt: 0 }}>
            <InputLabel id="demo-simple-select-label">Filial</InputLabel>
            <Select
              value={subCompanyId}
              onChange={event => setSubCompanyId(event.target.value)}
              sx={{ width: '100%' }}
              label="Filial"
              disabled={subCompany.length >= 1 ? false : true}
            >
              {subCompany.map((item, index) => (
                <MenuItem value={item.subCompanyId} key={item.subCompanyId}>
                  {item.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
        <Grid item padding={2}>
          <TextField
            label="Data de Início"
            type="date"
            value={startDate}
            onChange={event => setStartDate(event.target.value)}
            InputLabelProps={{
              shrink: true,
            }}
            variant="outlined"
          />
        </Grid>
        <Grid item padding={2}>
          <TextField
            label="Data de Término"
            type="date"
            value={endDate}
            onChange={event => setEndDate(event.target.value)}
            InputLabelProps={{
              shrink: true,
            }}
            variant="outlined"
          />
        </Grid>
        <Grid item xs={12} sm={12} md={2} lg={2} padding={2}>
          <FormControl sx={{ width: '100%', mt: 0 }}>
            <InputLabel id="demo-simple-select-label">Importado</InputLabel>
            <Select
              value={imported}
              onChange={event => setImported(event.target.value)}
              sx={{ width: '100%' }}
              label="Importado"
              disabled={subCompany.length >= 1 ? false : true}
            >
              <MenuItem value="S">Sim</MenuItem>
              <MenuItem value="N">Não</MenuItem>
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={12} sm={12} md={2} lg={2} padding={2}>
          <FormControl sx={{ width: '100%', mt: 0 }}>
            <InputLabel id="demo-simple-select-label">Cancelado</InputLabel>
            <Select
              value={cancel}
              onChange={event => setCancel(event.target.value)}
              sx={{ width: '100%' }}
              label="Cancelado"
              disabled={subCompany.length >= 1 ? false : true}
            >
              <MenuItem value="S">Sim</MenuItem>
              <MenuItem value="N">Não</MenuItem>
            </Select>
          </FormControl>
        </Grid>
        <Grid item padding={2} xs={12} sm={12} md={6} lg={6}>
          <Button variant="contained" onClick={handleSearch}>
            Buscar
          </Button>
        </Grid>
      </Grid>

      <Box
        sx={{
          width: '100%',
          alignItems: 'center',
          justifyContent: 'flex-end',
          mb: 2,
          mt: 2,
        }}
        display={{ xs: 'block', sm: 'block', md: 'flex', lg: 'flex', xl: 'flex' }}
      >
        <TableContainer>
          <Typography sx={{ width: 1, my: 2 }}>Resultados: {dataReadings?.length}</Typography>
          <Table sx={{ minWidth: 650 }} aria-label="simple table">
            <TableHead>
              <TableRow>
                <StyledTableCell align="left">Cancelamento de leitura</StyledTableCell>
                <StyledTableCell align="left">Tipo de leitura</StyledTableCell>
                <StyledTableCell align="left">Número</StyledTableCell>
                <StyledTableCell align="left">Data de leitura</StyledTableCell>
                <StyledTableCell align="left">Data Cancelamento</StyledTableCell>
                <StyledTableCell align="left">Importado</StyledTableCell>
                <StyledTableCell align="left">Cancelado</StyledTableCell>
                <StyledTableCell align="left">Data da importação</StyledTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {isLoading
                ? Array.from({ length: 10 }).map((_, rowIndex) => (
                    <TableRow key={rowIndex}>
                      {Array.from({ length: 7 }).map((_, colIndex) => (
                        <TableCell key={colIndex}>
                          <Skeleton animation="wave" />
                        </TableCell>
                      ))}
                    </TableRow>
                  ))
                : paginatedData?.map((row, i) => (
                    <StyledTableRow key={i}>
                      <StyledTableCell component="th" scope="row">
                        <Tooltip title="Cancelar leitura" arrow>
                          <IconButton onClick={() => handleCancel(row)} sx={{ cursor: 'pointer' }}>
                            <DeleteForeverIcon />
                          </IconButton>
                          {/* {editRowId === row.id ? (
                            <IconButton onClick={handleSaveClick} sx={{ cursor: 'pointer' }}>
                              <SaveIcon />
                            </IconButton>
                          ) : (
                            <IconButton
                              onClick={() => handleEditClick(row)}
                              sx={{ cursor: 'pointer' }}
                            >
                              <EditIcon />
                            </IconButton>
                          )} */}
                        </Tooltip>
                      </StyledTableCell>
                      <StyledTableCell>{row.iD_TIPO_LEITURA}</StyledTableCell>
                      <StyledTableCell>{row.numero}</StyledTableCell>
                      <StyledTableCell>{row.datA_LEITURA}</StyledTableCell>
                      <StyledTableCell>{row.datA_CANCELAMENTO}</StyledTableCell>
                      <StyledTableCell>{row.importado}</StyledTableCell>
                      <StyledTableCell>{row.cancelado}</StyledTableCell>
                      <StyledTableCell>{row.datA_IMPORTACAO}</StyledTableCell>
                    </StyledTableRow>
                  ))}
            </TableBody>
          </Table>
          <TablePagination
            labelRowsPerPage="items por página"
            rowsPerPageOptions={[10, 25, 50, 100]}
            component="div"
            count={dataReadings?.length}
            rowsPerPage={rowsPerPage}
            page={currentPage}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </TableContainer>
        <Dialog open={openDialog} onClose={handleCloseDialog}>
          <DialogTitle>Confirmar Cancelamento</DialogTitle>
          <DialogContent>
            <Typography>Você tem certeza que deseja cancelar esta leitura?</Typography>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleCloseDialog} color="primary">
              Não
            </Button>
            <Button onClick={handleConfirmCancel} color="secondary">
              Sim
            </Button>
          </DialogActions>
        </Dialog>
      </Box>
    </>
  )
}
export default ListReading
