import {
  Alert,
  Box,
  Button,
  CircularProgress,
  FormControl,
  Grid,
  IconButton,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  Snackbar,
  styled,
  Table,
  TableBody,
  TableCell,
  tableCellClasses,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TextField,
  Typography,
} from '@mui/material'
import { colorTableDefault } from '../../TemplateDefault'
import { Add, Clear, Delete, Help } from '@mui/icons-material'
import { useContext, useEffect, useRef, useState } from 'react'
import { Context } from '../../../context/AuthContext'
import { useParams } from 'react-router-dom'
import { getOnlyOrder } from '../../../services/reportServices'
import {
  getDeposit,
  getProductItensRfid,
  receiveOrderTransferBetweenBranches,
} from '../../../services/productService'
import { getAlocationStocGetList } from '../../../services/alocationServices'

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: colorTableDefault,
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}))

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: theme.palette.action.hover,
  },
  '&:last-child td, &:last-child th': {
    border: 0,
  },
}))

const TransferBetweenBranches = () => {
  const token = localStorage.getItem('token')
  const { userRoles, nameUrl, subCompany, company, userId } = useContext(Context)
  const params = useParams()
  const inputRef = useRef(null)
  const [subCompanyId, setSubCompanyId] = useState('')
  const [subCompanyIdFrom, setSubCompanyIdFrom] = useState('')
  const [companyId, setCompanyId] = useState(company?.companyId)
  const [selectedEquipment, setSelectedEquipment] = useState('')
  const [valueText, setValueText] = useState('')
  const [messageError, setMessageError] = useState(false)
  const [serials, setSerials] = useState([])
  const [title, setTitle] = useState('')
  const [alerta, setAlerta] = useState(false)
  const [severity, setSeverity] = useState('warning')
  const [viewElearning, setViewElearning] = useState(false)
  const [orders, setOrders] = useState([])
  const [codeList, setCodeList] = useState([])
  const [page, setPage] = useState(0)
  const [rowsPerPage, setRowsPerPage] = useState(10)
  const [orderSelect, setOrderSelect] = useState('')
  const vertical = 'top'
  const horizontal = 'center'
  const [displayOrder, setDisplayOrder] = useState('')
  const [orderResponse, setOrderResponse] = useState('')
  const [selectedReason, setSelectedReason] = useState('')
  const [alocations, setAlocations] = useState([])
  const [deposit, setDeposit] = useState([])
  const [selectedAlocation, setSelectedAlocation] = useState('')
  const [selectedDeposit, setSelectedDeposit] = useState('')
  const [loading, setLoading] = useState(false)
  const [loadingAddCode, setLoadingAddCode] = useState(false)
  const [manualMode, setManualMode] = useState(false)

  useEffect(() => {
    if (params.subCompanyId) {
      setSubCompanyId(params.subCompanyId)
      setSubCompanyIdFrom(params.subCompanyIdTo)
      getOrders(params.subCompanyId)
    } else {
      setManualMode(true)
    }
    if (params.orderId) {
      setOrderSelect(params.orderId)
    }
  }, [params])

  const handleDelete = itemToDelete => {
    const updatedRows = codeList.filter(item => item !== itemToDelete)
    const updatedSerials = serials.filter(serial => !itemToDelete.serials.includes(serial))
    setCodeList(updatedRows)
    setSerials(updatedSerials)
    setTitle('Produto removido com sucesso.')
    setAlerta(true)
    setSeverity('success')
  }

  const handleCleanList = () => {
    setSerials([])
    setCodeList([])
    setTitle('Lista limpa com sucesso.')
    setAlerta(true)
    setSeverity('success')
  }

  const handleChangePage = (event, newPage) => {
    setPage(newPage)
  }

  const handleChangeRowsPerPage = event => {
    setRowsPerPage(+event.target.value)
    setPage(0)
  }

  const handleClose = () => {
    setAlerta(false)
  }

  const getOrders = async subCompanyId => {
    try {
      const response = await getOnlyOrder(
        token,
        nameUrl,
        subCompanyId,
        Number(params.typeOrder) || 5
      )
      setOrders(response.data)
      console.log(response.data)
      const selectedOrder = response.data.find(
        order => order.movimentationOrderId === params.orderId
      )
      if (selectedOrder) {
        setDisplayOrder(selectedOrder)
      }
    } catch (e) {
      console.log(e)
    }
  }

  const handleChangeCodigoRfid = e => {
    setValueText(e.target.value)
    setMessageError('')
  }
  const handleEmptyText = errorMessage => {
    setMessageError(errorMessage)
  }
  const handleThrowError = title => {
    setTitle(title)
    setAlerta(true)
    setSeverity('error')
    setValueText('')
    setMessageError('')
  }

  const handleAddList = async () => {
    if (valueText === '') {
      handleEmptyText('Campo obrigatório.')
      // setPendingRequests(prev => prev - 1)
      return
    }
    const serialExist = serials.find(item => item === valueText)

    if (serialExist) {
      handleThrowError(`Codigo ${valueText} já adicionado.`)
      setValueText('')
      setMessageError('')
      // setPendingRequests(prev => prev - 1)
      return
    }
    let newProduct
    let response

    try {
      setLoadingAddCode(true)
      response = await getProductItensRfid(token, nameUrl, subCompanyId, valueText)
      setValueText('')
    } catch (error) {
      handleThrowError(error.response.data.title ?? 'Erro ao buscar produto.')
      setLoadingAddCode(false)
      //   setPendingRequests(prev => prev - 1)
      return
    }

    if (!response || !response.data) {
      handleThrowError('Produto não encontrado.')
      // setPendingRequests(prev => prev - 1)
      setLoadingAddCode(false)
      return
    }

    if (response.data.length === 0) {
      handleThrowError('Produto não encontrado.')
      // setPendingRequests(prev => prev - 1)
      setLoadingAddCode(false)
      return
    }
    const productExists = codeList.some(product => product.productId === response.data.productId)

    if (productExists) {
      setCodeList(prevList =>
        prevList.map(item =>
          item.productId === response.data.productId
            ? {
                ...item,
                serials: [...item.serials, valueText],
                quantityPallet: (item.quantityPallet || 0) + 1,
              }
            : item
        )
      )
    } else {
      newProduct = {
        idProductPartner: response.data.idProductPartner ?? '',
        reference: response.data.referencia ?? '',
        color: response.data.color ?? '',
        barCode: response.data.barCode ?? '',
        subCompanyCNPJ: response.data.subCompanyCNPJ ?? '',
        dataExpiration: response.data.dataExpiration ?? '',
        productName: response.data.productName ?? '',
        size: response.data.size ?? '',
        productId: response.data.productId ?? '',
        serials: [valueText],
        quantityPallet: 1,
        sku: response.data.sku,
      }

      setCodeList(prevList => [...prevList, newProduct])
    }

    setSerials(prevSerials => [...prevSerials, valueText])
    setValueText('')
    setLoadingAddCode(false)
    //   setPendingRequests(prev => prev - 1)
  }
  const handleSendCodeList = async () => {
    if (codeList.length === 0) {
      setTitle('A lista está vazia. Adicione itens antes de enviar.')
      setAlerta(true)
      setSeverity('warning')
      return
    }
    if (userId) {
      setLoading(true)
      try {
        const allSerials = codeList.flatMap(item => item.serials)

        const payload = [
          {
            movimentationOrderId: displayOrder.movimentationOrderId,
            subCompanyToId: subCompanyId,
            subCompanyFromId: subCompanyIdFrom,
            serials: allSerials,
          },
        ]

        const response = await receiveOrderTransferBetweenBranches(token, nameUrl, payload)
        setTitle(response.data.message)
        setAlerta(true)
        setSeverity('success')
        setCodeList([])

        console.log('Resposta da API:', response.data)
      } catch (error) {
        console.error('Erro ao enviar lista:', error)
        setTitle('Erro ao enviar lista. Tente novamente.')
        setAlerta(true)
        setSeverity('error')
      } finally {
        setLoading(false)
      }
    }
  }

  //   const handleFindAllLocation = async () => {
  //     try {
  //       const response = await getAlocationStocGetList(token, nameUrl, subCompanyId, companyId)
  //       console.log(response.data.alocation)
  //       if (response && response.data) {
  //         setAlocations(response.data.alocation)
  //       } else {
  //         console.error('Resposta inválida:', response)
  //         setAlocations([])
  //       }
  //     } catch (error) {
  //       console.error('Erro ao buscar alocações:', error)
  //       setAlocations([])
  //     }
  //   }

  const handleFinAllDeposit = async () => {
    try {
      const response = await getDeposit(token, nameUrl, subCompanyId)
      if (response && response.data) {
        setDeposit(response.data.result)
      } else {
        console.error('Resposta inválida:', response)
        setDeposit([])
      }
    } catch (error) {
      console.error('Erro ao buscar depósitos:', error)
      setDeposit([])
    }
  }

  useEffect(() => {
    if (subCompanyId) {
      //   handleFindAllLocation()
      handleFinAllDeposit()
    }
  }, [subCompanyId])

  return (
    <>
      <Box sx={{ mt: 7.5 }}>
        <Box sx={{ width: 1, display: 'flex', justifyContent: 'space-between' }}>
          <Snackbar
            anchorOrigin={{ vertical, horizontal }}
            open={alerta}
            onClose={handleClose}
            key={vertical + horizontal}
          >
            <Alert onClose={handleClose} severity={severity} sx={{ width: '100%' }}>
              {title}
            </Alert>
          </Snackbar>
          <Typography variant="h5"> Contagem de transferência entre filiais</Typography>
          <IconButton sx={{ justifySelf: 'flex-end' }}>
            <Help onClick={() => setViewElearning(true)} />
          </IconButton>
        </Box>
        <Grid container xs={12}>
          <Grid container component={Paper}>
            <Grid item xs={12} sm={12} md={6} lg={6} padding={2}>
              <FormControl sx={{ width: '100%', mt: 0 }}>
                <InputLabel id="demo-simple-select-label">Filial Origem</InputLabel>
                <Select
                  value={subCompanyId}
                  onChange={event => {
                    setSubCompanyId(event.target.value)
                    getOrders(event.target.value)
                  }}
                  sx={{ width: '100%' }}
                  label="Filial Origem"
                  disabled={!manualMode}
                >
                  {subCompany.map(item => (
                    <MenuItem key={item.subCompanyId} value={item.subCompanyId}>
                      {item.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>

            <Grid item xs={12} sm={12} md={6} lg={6} padding={2}>
              <FormControl sx={{ width: '100%' }}>
                <InputLabel id="demo-simple-select-label">Nome do pedido</InputLabel>

                <Select
                  label="Nome do pedido"
                  value={displayOrder}
                  onChange={e => setDisplayOrder(e.target.value)}
                  sx={{ width: '100%' }}
                  disabled={!manualMode}
                >
                  {orders.map((order, index) => (
                    <MenuItem key={index} value={order}>
                      {order.movimentationOrderNumberOtherSystem}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={6} padding={2}>
              <FormControl sx={{ width: '100%', mt: 0 }}>
                <InputLabel id="demo-simple-select-label">Filial Destino</InputLabel>
                <Select
                  value={subCompanyIdFrom}
                  onChange={event => {
                    setSubCompanyIdFrom(event.target.value)
                    getOrders(event.target.value)
                  }}
                  sx={{ width: '100%' }}
                  label="Filial Destino"
                  disabled={!manualMode}
                >
                  {subCompany.map((item, index) => (
                    <MenuItem key={item.subCompanyId} value={item.subCompanyId}>
                      {item.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={6} padding={2}>
              <FormControl sx={{ width: '100%', mt: 0 }}>
                <InputLabel sx={{ color: 'black' }} id="demo-simple-select-label">
                  Escolha o equipamento
                </InputLabel>
                <Select
                  value={selectedEquipment}
                  onChange={event => {
                    setSelectedEquipment(event.target.value)
                    //   handleEquipmentChange(event)
                  }}
                  sx={{ width: '100%', color: 'black' }}
                  label="Escolha o equipamento"
                >
                  <MenuItem selected value={1}>
                    HID
                  </MenuItem>
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={6} padding={2}>
              <TextField
                inputRef={inputRef}
                autoFocus
                label="Codigo RFID"
                type="text"
                //   disabled={handleDisableInput()}
                //   onKeyDown={handleKeyPress}
                value={valueText}
                //   error={!!messageError}
                //   helperText={messageError}
                onChange={e => handleChangeCodigoRfid(e)}
                fullWidth
                variant="outlined"
                InputLabelProps={{
                  style: { color: 'black' },
                }}
                sx={{
                  '& .MuiInputLabel-root': {
                    color: 'black',
                  },
                  '& .MuiInputLabel-root.Mui-focused': {
                    color: 'red',
                  },
                }}
              />
            </Grid>
            {subCompanyId && (
              <Grid item xs={12} sm={12} md={6} lg={6} padding={2}>
                <FormControl sx={{ width: '100%', mt: 0 }}>
                  <InputLabel sx={{ color: 'black' }} id="demo-simple-select-label">
                    Escolha a depósito
                  </InputLabel>
                  <Select
                    value={selectedDeposit}
                    onChange={event => {
                      setSelectedDeposit(event.target.value)
                    }}
                    sx={{ width: '100%', color: 'black' }}
                    label="Escolha o equipamento"
                  >
                    {deposit?.map(deposit => (
                      <MenuItem key={deposit.depositId} value={deposit.depositId}>
                        {deposit.name}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
            )}
          </Grid>
          <Grid item xs={12} sm={12} md={6} lg={6} padding={2}>
            <Box
              display="flex"
              justifyContent="flex-end"
              width="100%"
              alignItems="start"
              height="100%"
              sx={{ zIndex: theme => theme.zIndex.drawer + 1 }}
            >
              <Button
                sx={{
                  mr: 2,
                  height: '100%',
                  width: '100%',
                  maxHeight: '56px',
                }}
                variant="outlined"
                startIcon={<Clear />}
                onClick={() => handleCleanList()}
                // disabled={counting}
              >
                Limpar
              </Button>
              <Button
                sx={{
                  mr: 2,
                  height: '100%',
                  width: '100%',
                  maxHeight: '56px',
                  color: 'black',
                }}
                startIcon={<Add />}
                variant="contained"
                onClick={() => {
                  handleAddList()
                }}
                // disabled={counting}
              >
                {loadingAddCode ? <CircularProgress size={24} /> : 'Adicionar Código'}
              </Button>
              <Button
                sx={{
                  height: '100%',
                  width: '100%',
                  maxHeight: '56px',
                  color: 'black',
                }}
                variant="contained"
                onClick={handleSendCodeList}
                // disabled={counting}
              >
                {loading ? <CircularProgress size={24} /> : 'Enviar Informações'}
              </Button>
            </Box>
          </Grid>
        </Grid>
        <Box
          position="relative"
          display="flex"
          justifyContent="center"
          alignItems="center"
          flexDirection="column"
        >
          <Box display="flex" alignItems="center" justifyContent="center" gap={2} mt={2}>
            <Typography variant="h5">{`Total de produtos: ${codeList.length}`}</Typography>
            <Typography variant="h5">{`Total de itens contados: ${codeList.reduce(
              (total, item) => total + item.quantityPallet,
              0
            )}`}</Typography>
          </Box>
          <TableContainer component={Paper} sx={{ mt: '2rem' }}>
            <Table sx={{ minWidth: 700 }} aria-label="customized table">
              <TableHead>
                <TableRow>
                  <StyledTableCell align="left"> Código ERP</StyledTableCell>
                  <StyledTableCell align="left">Nome do Produto</StyledTableCell>
                  <StyledTableCell align="left">Quantidade</StyledTableCell>
                  <StyledTableCell align="left">Cor</StyledTableCell>
                  <StyledTableCell align="left">Tamanho</StyledTableCell>
                  <StyledTableCell align="left">Referencia</StyledTableCell>
                  <StyledTableCell align="left">SKU</StyledTableCell>

                  <StyledTableCell align="left">Ações</StyledTableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {codeList.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map(row => (
                  <StyledTableRow key={row.name}>
                    <StyledTableCell align="left">{row.idProductPartner}</StyledTableCell>
                    <StyledTableCell align="left">{row.productName}</StyledTableCell>
                    <StyledTableCell align="left">{row.quantityPallet}</StyledTableCell>
                    <StyledTableCell align="left">{row.color}</StyledTableCell>
                    <StyledTableCell align="left">{row.size}</StyledTableCell>
                    <StyledTableCell align="left">{row.reference}</StyledTableCell>
                    <StyledTableCell align="left">{row.sku}</StyledTableCell>

                    <StyledTableCell align="left">
                      <IconButton onClick={() => handleDelete(row)}>
                        <Delete />
                      </IconButton>
                    </StyledTableCell>
                  </StyledTableRow>
                ))}
              </TableBody>
            </Table>
            <TablePagination
              rowsPerPageOptions={[10, 25, 100]}
              component="div"
              count={codeList.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </TableContainer>
        </Box>
      </Box>
    </>
  )
}
export default TransferBetweenBranches
