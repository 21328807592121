import axios from 'axios'

//Contagem
export const getCountStockService = async (token, nameUrl, countStockDTO) => {
  const response = await axios.post(
    `/${nameUrl}/api/Report/GetStockCount`,
    // {
    //   subCompanyId: countStockDTO.subCompanyId,
    //   createDateTime: countStockDTO.createDateTime,
    //   endDateTime: countStockDTO.endDateTime,
    // }
    countStockDTO,
    {
      headers: {
        Authorization: 'Bearer ' + token,
      },
    }
  )
  return response
}
export const getMovimentationConferenceService = async (token, nameUrl, data) => {
  const response = await axios.post(
    `/${nameUrl}/api/MovimentationOrder/SendConferencePicking`,
    // {
    //   subCompanyId: data.subCompanyId,
    //   createDateTime: data.createDateTime,
    //   endDateTime: data.endDateTime,
    // }
    data,
    {
      headers: {
        Authorization: 'Bearer ' + token,
      },
    }
  )
  return response
}

export const getFilters = async (token, nameUrl, filter, companyId, txt) => {
  const response = await axios.get(
    `/${nameUrl}/api/Product/${filter}?CompanyId=${companyId}&Txt=${txt}`,

    {
      headers: {
        Authorization: 'Bearer ' + token,
      },
    }
  )
  return response
}

export const getFilteredProducts = async (token, nameUrl, data) => {
  const response = await axios.get(
    `/${nameUrl}/api/Product/GetFilteredProducts?CompanyId=${data.companyId}&Search=${data.search}&Category=${data.category}&Brand=${data.brand}&Color=${data.color}&Size=${data.size}&Limit=${data.limit}&Offset=${data.offset}&Order=desc`,

    {
      headers: {
        Authorization: 'Bearer ' + token,
      },
    }
  )
  return response
}

export const getCountStockInventoryService = async (token, nameUrl, data) => {
  console.log(data, 'dssadsa')
  const response = await axios.get(
    `/${nameUrl}/api/Report/GetStockInventory?subCompanyId=${data.subCompanyId}`,

    data.movimentationOrderIds,
    {
      headers: {
        Authorization: 'Bearer ' + token,
      },
    }
  )
  return response
}

export const getCountStockInventoryPostService = async (token, nameUrl, data) => {
  const response = await axios.post(
    `/${nameUrl}/api/Report/GetStockInventory?subCompanyId=${data.subCompanyId}`,

    data.movimentationOrderIds,
    {
      headers: {
        Authorization: 'Bearer ' + token,
      },
    }
  )
  return response
}

export const getReportInventoryService = async (token, nameUrl, movimentationOrderId) => {
  console.log(
    `/${nameUrl}/api/Report/GetInventoryStockWMS?MovimentationOrderId=${movimentationOrderId}`
  )

  const response = await axios.get(
    `/${nameUrl}/api/Report/GetInventoryStockWMS?MovimentationOrderId=${movimentationOrderId}`,
    {
      headers: {
        Authorization: 'Bearer ' + token,
      },
    }
  )

  return response
}

export const getQuantityProduct = async (token, nameUrl, subCompanyId, deposityId) => {
  const response = await axios.get(
    `/${nameUrl}/api/Product/GetQuantityStock?SubCompanyId=${subCompanyId}&DeposityId=${deposityId}`,
    {
      headers: {
        Authorization: 'Bearer ' + token,
      },
    }
  )

  return response
}

export const updateAuditory = async (
  token,
  nameUrl,
  subCompanyId,
  movimentationOrderId,
  quantity,
  idproductparterner
) => {
  const response = await axios.get(
    `/${nameUrl}/api/MovimentationOrder/UpdateAuditory?SubCompanyId=${subCompanyId}&MovimentationOrderId=${movimentationOrderId}&Quantity=${quantity}&Idproductparterner=${idproductparterner}`,
    {
      headers: {
        Authorization: 'Bearer ' + token,
      },
    }
  )

  return response
}

export const divergenceStatus = async (token, nameUrl, ItemStokWmsId) => {
  const response = await axios.post(
    `/${nameUrl}/api/Product/GetUpdateInfo_StockActive?CompanyId=de15bf17-876e-4e8b-b779-44e226874ac9&ResponsibleEmployee=11303822&Picking=1451&IdProductPartner=16107`,
    ItemStokWmsId,
    {
      headers: {
        Authorization: 'Bearer ' + token,
      },
    }
  )

  return response
}

export const getUpdateInfoStockActive = async (token, nameUrl, data) => {
  const response = await axios.get(
    `/${nameUrl}/api/Product/GetUpdateInfo_StockActive?CompanyId=${data.companyId}&ResponsibleEmployee=${data.responsibleEmployee}&Picking=${data.picking}&IdProductPartner=${data.idProductPartner}`,

    {
      headers: {
        Authorization: 'Bearer ' + token,
      },
    }
  )

  return response
}

//Vendas
export const getSalesService = async (token, nameUrl, sales) => {
  const response = await axios.post(
    `/${nameUrl}/api/Report/GetSales`,
    {
      subCompanyId: sales.subCompanyId,
      createDateTime: sales.createDateTime,
      movimentationOrderNumberOtherSystem: sales.movimentationOrderNumberOtherSystem,
      endDateTime: sales.endDateTime,
    },
    {
      headers: {
        Authorization: 'Bearer ' + token,
      },
    }
  )

  return response
}

//Vendas paginação
export const getSalesServicePagination = async (token, nameUrl, data) => {
  const response = await axios.post(
    `/${nameUrl}/api/Report/GetSalesPagination`,
    {
      subCompanyId: data.subCompanyId,
      createDateTime: data.createDateTime,
      limit: data.limit,
      offset: data.offset,
      endDateTime: data.endDateTime,
    },
    {
      headers: {
        Authorization: 'Bearer ' + token,
      },
    }
  )

  return response
}

//Graficos Vendas
export const getSalesConsolidate = async (token, nameUrl, sales) => {
  const response = await axios.post(
    //"/api/Report/GetSalesConslidate",
    `/${nameUrl}/api/Report/GetSales`,
    {
      subCompanyId: sales.subCompanyId,
      createDateTime: sales.startDate,
      endDateTime: sales.endDateTime,
    },
    {
      headers: {
        Authorization: 'Bearer ' + token,
      },
    }
  )
  return response
}

//Extrair Contagem TXT
export const getExtractCountService = async (token, nameUrl, extract) => {
  const response = await axios.post(
    `/${nameUrl}/api/Report/ExtractCountStockAchieve`,
    // {
    //   subCompanyId: extract.subCompanyId,
    //   startDate: extract.startDate,
    //   movimentationOrderNumberBihands:
    //     extract.movimentationOrderNumberOtherSystem,
    //   finishDate: extract.finishDate,
    //   formatAchieve: extract.formatAchieve,
    // }
    extract,
    {
      headers: {
        Authorization: 'Bearer ' + token,
      },
    }
  )

  return response
}

export const getOnlyOrder = async (token, nameUrl, subCompanyId, typeOrder) => {
  console.log(subCompanyId, typeOrder)
  const response = await axios.post(
    `/${nameUrl}/api/MovimentationOrder/GetGetOnlyOrderId`,
    {
      subCompanyId: subCompanyId,
      enumTypeOrder: typeOrder ? typeOrder : 6,
    },
    {
      headers: {
        Authorization: 'Bearer ' + token,
      },
    }
  )
  return response
}

export const createOrderCount = async (token, nameUrl, data) => {
  const response = await axios.post(
    `/${nameUrl}/api/MovimentationOrder/CreateOrderAuditoria`,
    data,
    {
      headers: {
        Authorization: 'Bearer ' + token,
      },
    }
  )
  return response
}

export const confirmOrderErp = async (
  token,
  nameUrl,
  MovimentationOrderNumberOtherSystem_Client,
  data
) => {
  const response = await axios.post(
    `/${nameUrl}/api/MovimentationOrder/SendConfirmIntegrationInventoryOrder?MovimentationOrderNumberOtherSystem_Client=${MovimentationOrderNumberOtherSystem_Client}`,
    {
      movimentationOrderNumberOtherSystem: data.movimentationOrderNumberOtherSystem,
      subCompanyCNPJ: data.subCompanyCNPJ,
    },
    {
      headers: {
        Authorization: 'Bearer ' + token,
      },
    }
  )
  return response
}

//Anti furto
export const resetAuditory = async (token, nameUrl, subCompanyId, movimentationOrderId) => {
  const response = await axios.get(
    `/${nameUrl}/api/MovimentationOrder/ResetAuditory?SubCompanyId=${subCompanyId}&MovimentationOrderId=${movimentationOrderId}`,
    {
      headers: {
        Authorization: 'Bearer ' + token,
      },
    }
  )
  return response
}

//Anti furto
export const handleAntiTheft = async (token, nameUrl, data) => {
  const response = await axios.post(`/${nameUrl}/api/Report/AntiTheftReports`, data, {
    headers: {
      Authorization: 'Bearer ' + token,
    },
  })
  return response
}

export const handleRoadMap = async (token, nameUrl, subCompanyId) => {
  const response = await axios.get(
    `/${nameUrl}/api/Report/GetMovimentationOrderOnly?subCompanyId=${subCompanyId}`,
    {
      headers: {
        Authorization: 'Bearer ' + token,
      },
    }
  )

  return response
}

export const getMovimentationRoadMapService = async (token, nameUrl, data) => {
  const response = await axios.get(
    `/${nameUrl}/api/Report/GetMovimentationOrder?SubCompanyId=${data.subCompanyId}&movimentationOrderRouteId=${data.movimentationOrderRouteId}`,
    {
      headers: {
        Authorization: 'Bearer ' + token,
      },
    }
  )

  return response
}

export const handleAntiTheftGet = async (token, nameUrl, data) => {
  const response = await axios.get(
    `/${nameUrl}/api/Report/AntiTheftReportsPagination?SubCompanyId=${data.subCompanyId}&createDateTime=${data.createDateTime}&endDateTime=${data.endDateTime}&limit=${data.limit}&offset=${data.offset}&Order=${data.order}`,
    {
      headers: {
        Authorization: 'Bearer ' + token,
      },
    }
  )
  return response
}

export const handleAntiTheftOnlyGet = async (token, nameUrl, data) => {
  const response = await axios.get(
    `/${nameUrl}/api/Report/AntiTheftReportsOnly?SubCompanyId=${data.subCompanyId}&createDateTime=${data.createDateTime}&endDateTime=${data.endDateTime}`,
    {
      headers: {
        Authorization: 'Bearer ' + token,
      },
    }
  )
  return response
}

//Dissassociation
export const handleDissassociation = async (token, nameUrl, data) => {
  const response = await axios.get(
    `/${nameUrl}/api/Report/DissassociationReport?subCompanyId=${data.subCompanyId}&startDate=${data.startDate}&finishDate=${data.finishDate}&limit=${data.limit}&offset=${data.offset}`,
    {
      headers: {
        Authorization: 'Bearer ' + token,
      },
    }
  )
  return response
}

export const handleInventoryMovement = async (token, nameUrl, data) => {
  const response = await axios.get(
    `/${nameUrl}/api/Report/GetMovimentationStockByDate?SubCompanyId=${data.subCompanyId}&StartDate=${data.startDate}&FinishDate=${data.finishDate}`,
    {
      headers: {
        Authorization: 'Bearer ' + token,
      },
    }
  )
  return response
}

export const handleStockActual = async (token, nameUrl, data) => {
  const response = await axios.get(
    `/${nameUrl}/api/Report/GetStockActual?subCompanyId=${data.subCompanyId}&startDate=${data.startDate}&finishDate=${data.finishDate}`,
    {
      headers: {
        Authorization: 'Bearer ' + token,
      },
    }
  )
  return response
}

export const handleValidationAndLote = async (token, nameUrl, data) => {
  let orderToString
  if (data.order) {
    orderToString = 'asc'
  } else {
    orderToString = 'desc'
  }

  const response = await axios.get(
    `/${nameUrl}/api/Report/GetValidationAndLote?subCompanyId=${data.subCompanyId}&ProductId=${data.productId}&limit=${data.limit}&offset=${data.offset}&Order=${orderToString}`,
    {
      headers: {
        Authorization: 'Bearer ' + token,
      },
    }
  )
  return response
}

export const handleMovimentationLabel = async (token, nameUrl, data, startDate, finishDate) => {
  const response = await axios.get(
    `/${nameUrl}/api/Report/GetMovimentationLabel?SubCompanyId=${data}0&InitialDate=${startDate}&LastDate=${finishDate}
`,
    {
      headers: {
        Authorization: 'Bearer ' + token,
      },
    }
  )
  return response
}

export const handleReportPicking = async (token, nameUrl, data) => {
  const response = await axios.get(
    `/${nameUrl}/api/Report/GetMovimentationPicking?SubCompanyId=${data}`,
    {
      headers: {
        Authorization: 'Bearer ' + token,
      },
    }
  )
  return response
}

export const handleReportPosition = async (token, nameUrl, data) => {
  const response = await axios.get(`/${nameUrl}/api/Report/GetLocationBusy?SubCompanyId=${data}`, {
    headers: {
      Authorization: 'Bearer ' + token,
    },
  })
  return response
}

export const handleReportProductsActive = async (token, nameUrl, data) => {
  const response = await axios.get(
    `/${nameUrl}/api/Report/GetAllProductsActive?SubCompanyId=${data}`,
    {
      headers: {
        Authorization: 'Bearer ' + token,
      },
    }
  )
  return response
}

export const handleReportPositionFree = async (token, nameUrl, data) => {
  const response = await axios.get(`/${nameUrl}/api/Report/GetLocationFree?SubCompanyId=${data}`, {
    headers: {
      Authorization: 'Bearer ' + token,
    },
  })
  return response
}

export const getValidationandLote = async (token, nameUrl, data) => {
  let orderToString
  if (data.order) {
    orderToString = 'asc'
  } else {
    orderToString = 'desc'
  }
  const response = await axios.get(
    `/${nameUrl}/api/Report/GetDateValidationAndLoteByProduct?SubCompanyId=${data.SubCompanyId}&ProductId=${data.ProductId}&limit=${data.limit}&offset=${data.offset}&Order=${orderToString}`,
    {
      headers: {
        Authorization: 'Bearer ' + token,
      },
    }
  )

  return response
}

export const getReportProductPickingNotFound = async (token, nameUrl, data) => {
  const response = await axios.get(
    `/${nameUrl}/api/Report/GetProductPickingNotFound?CompanyId=${data}`,
    {
      headers: {
        Authorization: 'Bearer ' + token,
      },
    }
  )
  return response
}

export const getBlockedProducts = async (token, nameUrl, data) => {
  const response = await axios.get(
    `api/Report/ProductBlocked?SubCompanyId=${data.SubCompanyId}&StartDate=${data.StartDate}&FinishDate=${data.FinishDate}`,
    {
      headers: {
        Authorization: 'Bearer ' + token,
      },
    }
  )
  return response
}

export const handleMovimentationStockByProduct = async (token, nameUrl, data) => {
  const response = await axios.get(
    `/${nameUrl}/api/Report/GetMovimentationStockByProduct?subCompanyId=${data.subCompanyId}&startDate=${data.startDate}&finishDate=${data.finishDate}&ProductId=${data.productId}`,
    {
      headers: {
        Authorization: 'Bearer ' + token,
      },
    }
  )
  return response
}

export const handleMovimentationStockInfo = async (token, nameUrl, data) => {
  const response = await axios.get(
    `/${nameUrl}/api/Report/GetMovimentationStockInfo?SubCompanyId=${data.subCompanyId}&StartDate=${data.startDate}&FinishDate=${data.finishDate}&ProductId=${data.productId}&TypeOrder=${data.typeOrder}`,
    {
      headers: {
        Authorization: 'Bearer ' + token,
      },
    }
  )
  return response
}

export const handleMovimentationStockInfoAndSubCompany = async (token, nameUrl, data) => {
  const response = await axios.get(
    `/${nameUrl}/api/Report/GetMovimentationStockInfo?SubCompanyId=${data.subCompanyId}&StartDate=${data.startDate}&FinishDate=${data.finishDate}&TypeOrder=${data.typeOrder}`,
    {
      headers: {
        Authorization: 'Bearer ' + token,
      },
    }
  )
  return response
}

export const handleMovimentationStockInfoAndProduct = async (token, nameUrl, data) => {
  const response = await axios.get(
    `/${nameUrl}/api/Report/GetMovimentationStockInfo?StartDate=${data.startDate}&FinishDate=${data.finishDate}&TypeOrder=${data.typeOrder}&ProductId=${data.productId}`,
    {
      headers: {
        Authorization: 'Bearer ' + token,
      },
    }
  )
  return response
}

export const handleMovimentationStockInfoAndProductAndSubCompany = async (token, nameUrl, data) => {
  const response = await axios.get(
    `/${nameUrl}/api/Report/GetMovimentationStockInfo?StartDate=${data.startDate}&FinishDate=${data.finishDate}&TypeOrder=${data.typeOrder}`,
    {
      headers: {
        Authorization: 'Bearer ' + token,
      },
    }
  )
  return response
}

export const handleMovimentationHistoryByProduct = async (token, nameUrl, data) => {
  console.log(data, `data`)

  const response = await axios.get(
    `/${nameUrl}/api/Report/GetMovimentationStockByLote?subCompanyId=${data.SubCompanyId}&ProductId=${data.ProductId}&Lote=${data.Lote}&Palete=${data.Palete}`,
    {
      headers: {
        Authorization: 'Bearer ' + token,
      },
    }
  )
  return response
}

export const getAllPhotos = async (token, nameUrl, subCompanyId) => {
  const response = await axios.get(
    `/${nameUrl}api/ProductItens/GetPhotoAll?subCompanyId=${subCompanyId}`,
    {
      headers: {
        Authorization: 'Bearer ' + token,
      },
    }
  )
  return response.data
}
