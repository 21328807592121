import {
  Alert,
  Backdrop,
  Button,
  CircularProgress,
  FormControl,
  Autocomplete,
  FormLabel,
  Grid,
  IconButton,
  InputLabel,
  MenuItem,
  Paper,
  Radio,
  RadioGroup,
  Select,
  Snackbar,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TextField,
  Typography,
  tableCellClasses,
  InputAdornment,
  Tooltip,
} from '@mui/material'
import { Box, styled } from '@mui/system'
import React, { useContext, useEffect, useRef, useState } from 'react'
import { colorTableDefault } from '../../TemplateDefault'
import ViewElearning from '../../ViewElearning'
import { Add, Clear, Delete, Help } from '@mui/icons-material'
import { Context } from '../../../context/AuthContext'
import { getCountStockService, getOnlyOrder } from '../../../services/reportServices'
import {
  createAssociationItensList,
  getProductItensRfid,
  searchProductService,
} from '../../../services/productService'
import { useDebounce } from '../Product/Components/useDebounce'
import ComponentQuantityCreate from './ComponentRfid/Create'
import ComponentQuantityEdit from './ComponentRfid/Edit'
import { useParams } from 'react-router-dom'

export const CountStockDesk = () => {
  const { subCompany, nameUrl } = useContext(Context)
  const token = localStorage.getItem('token')
  const params = useParams()
  const { debounce } = useDebounce(1000, true)
  const [subCompanyId, setSubCompanyId] = useState('')
  const [search, setSearch] = useState('')
  const [orders, setOrders] = useState([])
  const [loading, setLoading] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [loadingCount, setLoadingCount] = useState(false)
  const [orderSelect, setOrderSelect] = useState('')
  const [page, setPage] = useState(0)
  const [rowsPerPage, setRowsPerPage] = useState(10)
  const [pagination, setPagination] = useState({})
  const [rowsProducts, setRowsProducts] = useState([])
  const [rowsFilter, setRowsFilter] = useState([])
  const [viewElearning, setViewElearning] = useState(false)
  const [countStock, setCountStock] = useState([])
  const [searchFilter, setSearchFilter] = useState('')
  const [product, setProduct] = useState([])
  const vertical = 'top'
  const horizontal = 'center'
  const [alerta, setAlerta] = useState(false)
  const [title, setTitle] = useState('')
  const [selectedId, setSelectedId] = useState(undefined)
  const [severity, setSeverity] = useState('warning')
  const [count, setCount] = useState(0)
  const [counting, setCounting] = useState(true)
  const [finished, setFinished] = useState(false)
  const [shouldStopCounting, setShouldStopCounting] = useState(false)
  const [showInput, setShowInput] = useState(false)
  const [selectedEquipment, setSelectedEquipment] = useState(0)
  const [formattedIP, setFormattedIP] = useState('')
  const [formattedPort, setFormattedPort] = useState('')
  const [products, setProducts] = useState('')
  const [error, setError] = useState('')
  const [movimentationOrderNumberOtherSystem, setMovimentationOrderNumberOtherSystem] = useState('')
  const [intervalId, setIntervalId] = useState(null)
  const [openModalCreate, setOpenModalCreate] = useState(false)
  const [productItem, setProductItem] = useState([])
  const [messageError, setMessageError] = useState(false)
  const [isEditModalOpen, setIsEditModalOpen] = useState(false)
  const [valueText, setValueText] = useState('')
  const [codeList, setCodeList] = useState([])
  const [register, setRegister] = useState(false)
  const inputRef = useRef(null)

  const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: colorTableDefault,
      color: theme.palette.common.white,
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: 14,
    },
  }))

  const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.action.hover,
    },
    '&:last-child td, &:last-child th': {
      border: 0,
    },
  }))

  const formatIP = ip => {
    if (!ip) return ''

    const numericValue = ip.replace(/[^0-9]/g, '')
    const formattedValue = numericValue.replace(/(\d{3})(?=\d)/g, '$1.')

    return formattedValue
  }

  const formatPort = port => {
    if (!port) return ''

    const numericValue = port.replace(/\D/g, '')
    return numericValue
  }

  const handleChangePage = (event, newPage) => {
    setPage(newPage)
  }

  const handleChangeRowsPerPage = event => {
    setRowsPerPage(+event.target.value)
    setPage(0)
  }

  const handleClose = () => {
    setAlerta(false)
  }

  const getOrders = async e => {
    setSubCompanyId(e)
    try {
      const response = await getOnlyOrder(token, nameUrl, e, Number(params.typeOrder))
      setOrders(response.data)
    } catch (e) {
      console.log(e)
    }
  }

  useEffect(() => {
    if (params.movimentationOrderId) {
      setOrderSelect(params.movimentationOrderId)
    }
  }, [params])

  const handleOrder = e => {
    const newOrder = e.target.value
    setOrderSelect(newOrder)

    const selectedOrder = orders.find(order => order.movimentationOrderId === newOrder)

    if (selectedOrder) {
      const movimentationOrderNumberOtherSystem = selectedOrder.movimentationOrderNumberOtherSystem
      setMovimentationOrderNumberOtherSystem(movimentationOrderNumberOtherSystem)
      // Faça o que você precisa com movimentationOrderNumberOtherSystem
    }
  }

  const getCountStock = async () => {
    setLoading(true)
    const countStockDTO = {
      subCompanyId: subCompanyId,
      movimentationOrderId: orderSelect,
    }
    try {
      const response = await getCountStockService(token, nameUrl, countStockDTO)
      if (response.data.length === 0) {
        setTitle('Não existem dados para essa consulta.')
        setAlerta(true)
        setSeverity('error')
      } else {
        setCountStock(response.data)
        setPagination(response.data)
      }
      setLoading(false)
    } catch (err) {
      setLoading(false)
      console.log(err)
    }
  }

  const handleEquipmentChange = e => {
    const selectedEquipment = e.target.value
    setSelectedEquipment(selectedEquipment)
    if (selectedEquipment === 'MID40') {
      setShowInput(true)
    } else if (selectedEquipment === 1) {
      setShowInput(true)
    } else {
      setShowInput(false)
    }
    setCounting(false)
  }

  const initialize = () => {
    console.log(shouldStopCounting)
    setShouldStopCounting(false)
    setFinished(false)

    if (selectedEquipment === 'MID40' && (!formattedIP || !formattedPort)) {
      setError('Informe o IP e a porta antes de iniciar a contagem.')
      return
    }
    if (selectedEquipment === 'HID' && !products) {
      setError('Informe o código do produto antes de iniciar a contagem.')
      return
    }

    if (!orderSelect) return
    setLoadingCount(true)
    setCounting(true)
    setCount(0)
    setShouldStopCounting(false)

    const newIntervalId = setInterval(() => {
      if (shouldStopCounting) {
        clearInterval(newIntervalId)
        setCounting(false)
        setFinished(true)
        setShouldStopCounting(false)
        return
      }
      setCount(prevCount => prevCount + 1)
    }, 1000)

    setIntervalId(newIntervalId)
    setTimeout(() => {
      clearInterval(newIntervalId)
      setCounting(false)
      setFinished(true)
      getCountStock()
      setLoadingCount(false)
    }, 10000)
  }

  const stopCounting = () => {
    console.log('parar')
    clearInterval(intervalId)
    setCounting(false)
    setShouldStopCounting(true)
    setShouldStopCounting(false)
  }

  useEffect(() => {
    setCounting(false)
  }, [orderSelect, countStock, shouldStopCounting])

  const handleGetAllProductsSearch = async () => {
    setPage(0)
    setIsLoading(true)
    const dataProduct = {
      companyId: subCompany[0].companyId,
      limit: 10,
      offset: 0,
      order: 'desc',
      SearchWord: search,
    }
    try {
      searchProductService(token, nameUrl, dataProduct).then(response => {
        const data = response.data.result
        setProduct(data)

        setIsLoading(false)
      })
    } catch (error) {
      console.error(error)
      setIsLoading(false)
    }
  }

  useEffect(() => {
    if (search !== '') {
      setIsLoading(true)
      debounce(() => {
        handleGetAllProductsSearch()
      })
    }
  }, [search])

  useEffect(() => {
    if (selectedEquipment === 1) {
      setOpenModalCreate(true)
      setSearch('')
    }
  }, [selectedEquipment])

  const handleSaveCodeRfidEdit = newProduct => {
    const updatedProducts = rowsProducts.filter(product => product.serial !== productItem.serial)

    updatedProducts.push(newProduct)

    setRowsProducts(updatedProducts)
  }

  // const filterRow = () => {
  //   const filtered = rowsProducts.filter(
  //     item =>
  //       item.name.toLowerCase().includes(searchFilter.toLowerCase()) ||
  //       item.idProductPartner.toLowerCase().includes(searchFilter.toLowerCase()) ||
  //       item.serial.toLowerCase().includes(searchFilter.toLowerCase()) ||
  //       item.barCode.toLowerCase().includes(searchFilter.toLowerCase())
  //   )
  //   setRowsFilter(filtered)
  // }

  // useEffect(() => {
  //   filterRow()
  // }, [searchFilter, rowsProducts])

  function Row(props) {
    const { row, onDelete } = props

    const handleDeleteClick = () => {
      onDelete(row)
    }

    return (
      <React.Fragment>
        <StyledTableRow sx={{ '& > *': { borderBottom: 'unset' } }}>
          <StyledTableCell align="center" width="200px">
            <Tooltip title="Apagar" arrow>
              <IconButton onClick={handleDeleteClick}>
                <Delete />
              </IconButton>
            </Tooltip>
          </StyledTableCell>
          <StyledTableCell align="left">{row.productName}</StyledTableCell>
          <StyledTableCell align="left">{row.sEPCHex}</StyledTableCell>
          <StyledTableCell align="left">{row.sku}</StyledTableCell>
        </StyledTableRow>
      </React.Fragment>
    )
  }

  const handleAddList = async () => {
    setIsLoading(true)
    const codeExists = codeList.some(item => item.sEPCHex === valueText)
    console.log(codeExists)

    if (codeExists) {
      setTitle('Este código RFID ja foi lido.')
      setSeverity('error')
      setValueText('')
      setRegister(true)
      setIsLoading(false)
      return
    }
    console.log(codeList)
    //Aqui eu posso mudar o produto que vou enviar a API - 
    try {
      const response = await getProductItensRfid(token, nameUrl, subCompanyId, valueText)
      console.log(response, 'RESPONSE')
      const newProduct = {
        sEPCHex: response.data.serial,
        productName: response.data.productName,
        sku: response.data.sku,
        subCompanyId: response.data.subCompanyId,
      }
      console.log(newProduct)
      setCodeList(prevList => [...prevList, newProduct])
      setIsLoading(false)
      setValueText('')
    } catch (error) {
      setIsLoading(false)
      setTitle('Nenhum produto encontrado.')
      setSeverity('error')
      setValueText('')
      setRegister(true)
    }
  }

  const handleDelete = itemToDelete => {
    const updatedRows = codeList.filter(item => item !== itemToDelete)
    setCodeList(updatedRows)
  }

  const handleKeyPress = event => {
    if (event.key === 'Enter') {
      return handleAddList()
    }
  }

  return (
    <>
      <Box sx={{ mt: 7.5 }}>
        <Snackbar
          anchorOrigin={{ vertical, horizontal }}
          open={alerta}
          onClose={handleClose}
          key={vertical + horizontal}
        >
          <Alert onClose={handleClose} severity={severity} sx={{ width: '100%' }}>
            {title}
          </Alert>
        </Snackbar>
        <ViewElearning
          pageName={'ReportCountStock'}
          open={viewElearning}
          setOpen={setViewElearning}
        />
        <Box sx={{ width: 1, display: 'flex', justifyContent: 'space-between' }}>
          <Typography variant="h5">Contagem de Estoque</Typography>
          <IconButton sx={{ justifySelf: 'flex-end' }}>
            <Help onClick={() => setViewElearning(true)} />
          </IconButton>
        </Box>
        {/* <ComponentQuantityCreate
          open={openModalCreate}
          subCompanyId={subCompanyId}
          onClose={() => {
            setOpenModalCreate(false)
            setSelectedEquipment(0)
          }}
          product={productItem}
          movimentationOrderNumberOtherSystem={movimentationOrderNumberOtherSystem}
          message={messageError}
          onGetCount={() => {
            getCountStock()
          }}
        /> */}
        <Box
          sx={{
            width: '100%',
            alignItems: 'center',
            justifyContent: 'space-between',
            mb: 2,
            mt: 2,
          }}
          display={{ xs: 'block', sm: 'block', md: 'flex', lg: 'flex', xl: 'flex' }}
        >
          <Grid container spacing={2}>
            <Grid item xs={12} md={6}>
              <Box flex={1} display="flex" justifyContent="flex-end">
                <FormControl sx={{ width: '100%', mt: 0 }}>
                  <InputLabel id="demo-simple-select-label">Filial</InputLabel>
                  <Select
                    value={subCompanyId}
                    onChange={event => {
                      setSubCompanyId(event.target.value)
                      getOrders(event.target.value)
                    }}
                    sx={{ width: '100%' }}
                    label="Filial"
                  >
                    {subCompany.map((item, index) => (
                      <MenuItem value={item.subCompanyId} key={item.subCompanyId}>
                        {item.name}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Box>
            </Grid>

            <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
              <FormControl sx={{ width: '100%', mt: 0 }}>
                <InputLabel id="demo-simple-select-label">Selecione o pedido</InputLabel>
                <Select
                  label="Selecione o pedido"
                  value={orderSelect}
                  disabled={!subCompanyId}
                  onChange={handleOrder}
                  sx={{ width: '100%' }}
                >
                  {orders.map((item, index) => {
                    return (
                      <MenuItem value={item.movimentationOrderId} key={index}>
                        {item.movimentationOrderNumberOtherSystem}
                      </MenuItem>
                    )
                  })}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12} md={6}>
              <Box flex={1} display="flex" justifyContent="flex-end">
                <FormControl sx={{ width: '100%', mt: 0 }}>
                  <InputLabel id="demo-simple-select-label">Escolha o equipamento</InputLabel>
                  <Select
                    value={selectedEquipment}
                    disabled={!orderSelect}
                    onChange={event => {
                      setSelectedEquipment(event.target.value)
                      handleEquipmentChange(event)
                    }}
                    sx={{ width: '100%' }}
                    label="Selecione o tipo de impressora"
                  >
                    <MenuItem selected value={1}>
                      HID
                    </MenuItem>
                  </Select>
                </FormControl>
              </Box>
            </Grid>
            <Grid item xs={12} md={6}>
              <Box
                display="flex"
                alignItems="center"
                justifyContent="space-between"
                gap={0}
                width={'100%'}
              >
                <TextField
                  inputRef={inputRef}
                  // sx={{ my: 2 }}
                  label="Código RFID"
                  autoFocus
                  // margin="dense"
                  id="rfid-code"
                  // size="small"
                  type="text"
                  onKeyDown={handleKeyPress}
                  value={valueText}
                  disabled={isLoading}
                  error={!!messageError}
                  helperText={messageError}
                  onChange={e => setValueText(e.target.value)}
                  fullWidth
                  variant="outlined"
                  inputProps={{ min: 1 }}
                  InputProps={{
                    endAdornment: isLoading && (
                      <InputAdornment position="end">
                        <CircularProgress size={20} />
                      </InputAdornment>
                    ),
                  }}
                />
                {/* <Button
                  startIcon={!isLoading && <Add />}
                  endIcon={isLoading && <CircularProgress size={20} />}
                  disabled={isLoading}
                  variant="contained"
                  sx={{ width: '250px', mb: messageError ? 2 : 0 }}
                  // onClick={handleAddList}
                >
                  {isLoading ? 'Adicionando...' : 'Adicionar [ENTER]'}
                </Button> */}
              </Box>
            </Grid>
            {/* <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
              <FormControl sx={{ display: 'flex', justifyContent: 'flex-end', width: '100%' }}>
                <FormLabel id="demo-row-radio-buttons-group-label">
                  Escolha a conexão do equipamento
                </FormLabel>
                <RadioGroup
                  sx={{ display: 'flex', justifyContent: 'space-between', width: '100%' }}
                  row
                  aria-labelledby="demo-row-radio-buttons-group-label"
                  name="row-radio-buttons-group"
                  value={selectedEquipment}
                  onChange={handleEquipmentChange}
                >
                  <FormControlLabel
                    disabled={!orderSelect || counting}
                    value="USB"
                    control={<Radio />}
                    label="USB"
                  />
                  <FormControlLabel
                    disabled={!orderSelect || counting}
                    value="MID40"
                    control={<Radio />}
                    label="MID40"
                  />
                  <FormControlLabel
                    disabled={!orderSelect || counting}
                    value="MID10W"
                    control={<Radio />}
                    label="MID10W"
                  />
                  <FormControlLabel
                    sx={{ mr: 0 }}
                    disabled={!orderSelect || counting}
                    value="HID"
                    control={<Radio />}
                    label="HID"
                  />
                </RadioGroup>
              </FormControl>
            </Grid> */}

            {showInput && (
              <>
                {selectedEquipment === 'MID40' && (
                  <>
                    <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                      <TextField
                        id="outlined-basic"
                        label="IP"
                        variant="outlined"
                        sx={{ width: '100%' }}
                        value={formattedIP}
                        error={!!error}
                        onChange={e => {
                          setFormattedIP(formatIP(e.target.value))
                        }}
                        inputProps={{
                          maxLength: 15,
                        }}
                      />
                    </Grid>
                    <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                      <TextField
                        id="outlined-basic"
                        label="Porta"
                        variant="outlined"
                        value={formattedPort}
                        error={!!error}
                        onChange={e => {
                          setFormattedPort(formatPort(e.target.value))
                        }}
                        sx={{ width: '100%' }}
                      />
                    </Grid>
                  </>
                )}
                {error && (
                  <Grid item xs={12}>
                    <Typography variant="body2" color="error">
                      {error}
                    </Typography>
                  </Grid>
                )}
              </>
            )}
            {/* 
            <Grid item xs={12}>
              <Box
                display="flex"
                justifyContent="flex-start"
                width="100%"
                alignItems="start"
                height="100%"
              >
                <Button
                  sx={{ mr: 2 }}
                  variant="contained"
                  disabled={!orderSelect || counting}
                  onClick={initialize}
                >
                  Iniciar Leitura
                </Button>
                <Button variant="contained" onClick={stopCounting} disabled={!counting}>
                  Parar Leitura
                </Button>
              </Box>
            </Grid> */}

            <Grid item xs={12}>
              <Box
                display="flex"
                justifyContent="flex-end"
                width="100%"
                alignItems="end"
                height="100%"
              >
                <Button
                  sx={{ mr: 2 }}
                  variant="outlined"
                  startIcon={<Clear />}
                  onClick={() => setCountStock([])}
                  disabled={!orderSelect || counting}
                >
                  Limpar
                </Button>
                <Button
                  sx={{ mr: 2 }}
                  startIcon={<Add />}
                  variant="contained"
                  onClick={() => handleAddList()}
                  disabled={!orderSelect || counting}
                >
                  Adicionar Codigo
                </Button>
                <Button
                  variant="contained"
                  onClick={getCountStock}
                  disabled={!orderSelect || counting}
                >
                  Enviar informação
                </Button>
              </Box>
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
              <Box display="flex">
                {finished && (
                  <Typography variant="h6" mr={2}>
                    {countStock.length} Peças contadas
                  </Typography>
                )}
              </Box>
            </Grid>
          </Grid>
        </Box>

        <Box
          position="relative"
          display="flex"
          justifyContent="center"
          alignItems="center"
          flexDirection="column"
        >
          <Box display="flex" alignItems="center" justifyContent="center" gap={2}>
            <Typography variant="h5">{`Total de produtos: ${codeList.length}`}</Typography>
            <Typography variant="h5">{`Total de itens contados: ${countStock.reduce(
              (total, item) => total + item.quantityPallet,
              0
            )}`}</Typography>
          </Box>
          <TableContainer component={Paper} sx={{ mt: '2rem' }}>
            <Table sx={{ minWidth: 700 }} aria-label="customized table">
              <TableHead>
                <TableRow>
                  <StyledTableCell align="left"></StyledTableCell>
                  <StyledTableCell align="left">Nome do produto</StyledTableCell>
                  <StyledTableCell align="left">Serial</StyledTableCell>
                  <StyledTableCell align="left">SKU</StyledTableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {codeList.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map(row => (
                  <Row key={row.serial} row={row} onDelete={handleDelete} />
                ))}
              </TableBody>
            </Table>
            <TablePagination
              rowsPerPageOptions={[10, 25, 100]}
              component="div"
              count={countStock.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </TableContainer>
        </Box>
        <Backdrop sx={{ color: '#fff', zIndex: theme => theme.zIndex.drawer + 1 }} open={loading}>
          <CircularProgress color="inherit" />
        </Backdrop>
      </Box>
    </>
  )
}
