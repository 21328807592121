import axios from 'axios'

//campos de integração
export const getAllIntegrationFieldsService = async (token, nameUrl, intId) => {
  const response = await axios.get(
    `/${nameUrl}/api/IntegrationFields/GetAll?IntegrationDefaultId=${intId}&limit=50&offset=0&order=asc`,
    { headers: { Authorization: 'Bearer ' + token } }
  )
  return response
}

export const createIntegrationFieldService = async (token, nameUrl, data) => {
  const response = await axios.post(`/${nameUrl}/api/IntegrationFields/Create`, data, {
    headers: { Authorization: 'Bearer ' + token },
  })
  return response
}

export const deleteIntegrationFieldService = async (token, nameUrl, data) => {
  const response = await axios.post(`/${nameUrl}/api/IntegrationFields/Delete`, data, {
    headers: { Authorization: 'Bearer ' + token },
  })
  return response
}

//Coletores Automáticos
export const getEquipmentService = async (token, nameUrl, subCompanyId, page, limit, order) => {
  let orderToString
  if (order) {
    orderToString = 'asc'
  } else {
    orderToString = 'desc'
  }

  const response = await axios.get(
    `/${nameUrl}/api/Equipment/GetAll?SubCompanyId=${subCompanyId}&page=${page}&limit=${limit}&Order=${orderToString}`,
    {
      headers: {
        Authorization: 'Bearer ' + token,
      },
    }
  )

  return response
}
//Coletores Automáticos
export const getEquipmentLogs = async (token, nameUrl, equipmentId, page, limit, order) => {
  let orderToString
  if (order) {
    orderToString = 'asc'
  } else {
    orderToString = 'desc'
  }

  const response = await axios.get(
    `/${nameUrl}/api/EquipmentLog/GetAll?IdEquipment=${equipmentId}&page=${page}&limit=${limit}&Order=${orderToString}`,
    {
      headers: {
        Authorization: 'Bearer ' + token,
      },
    }
  )

  return response
}
export const getEquipmentSerial = async (token, nameUrl, equipmentId, page, limit, order) => {
  let orderToString
  if (order) {
    orderToString = 'asc'
  } else {
    orderToString = 'desc'
  }

  const response = await axios.get(
    `/${nameUrl}/api/EquipmentSerial/GetAll?EquipamentId=${equipmentId}&page=${page}&limit=${limit}&Order=${orderToString}`,
    {
      headers: {
        Authorization: 'Bearer ' + token,
      },
    }
  )

  return response
}

export const getUniqueEquipmentService = async (token, nameUrl, equipmentId) => {
  const response = await axios.get(`/${nameUrl}/api/Equipment/Get/${equipmentId}`, {
    headers: {
      Authorization: 'Bearer ' + token,
    },
  })

  return response
}

export const createEquipmentService = async (token, nameUrl, equipment) => {
  const response = await axios.post(`/${nameUrl}/api/Equipment/Create`, equipment, {
    headers: {
      Authorization: 'Bearer ' + token,
    },
  })

  return response
}

export const deleteEquipmentService = async (token, nameUrl, equipmentId) => {
  const response = await axios.post(
    `/${nameUrl}/api/Equipment/Delete`,
    {
      equipmentId: equipmentId,
    },
    {
      headers: {
        Authorization: 'Bearer ' + token,
      },
    }
  )

  return response
}

export const editEquipmentService = async (token, nameUrl, equipment) => {
  const response = await axios.post(
    `/${nameUrl}/api/Equipment/Edit`,
    {
      equipmentId: equipment.equipmentId,
      ip: equipment.ip,
      name: equipment.name,
      typeEquipmentHardware: equipment.typeEquipmentHardware,
      port: equipment.port,
      location: equipment.location,
      mac: equipment.mac,
      createDate: equipment.createDate,
      active: equipment.active,
      subCompanyId: equipment.subCompanyId,
      power: equipment.power,
      typeOrder: equipment.typeOrder,
    },
    {
      headers: {
        Authorization: 'Bearer ' + token,
      },
    }
  )

  return response
}

//Monitores automáticos

export const getMonitoringEquipmentService = async (token, nameUrl, limit, offset, order) => {
  let orderToString
  if (order) {
    orderToString = 'asc'
  } else {
    orderToString = 'desc'
  }

  const response = await axios.get(
    `/${nameUrl}/api/MonitoringEquipment/GetAll?limit=${limit}&offset=${offset}&Order=${orderToString}`,
    {
      headers: {
        Authorization: 'Bearer ' + token,
      },
    }
  )

  return response
}

export const getMonitoringEquipmentConnectService = async (
  token,
  nameUrl,
  equipamentId,
  connect
) => {
  const response = await axios.get(
    `/${nameUrl}/api/MonitoringEquipment/ReConnect?EquipmentId=${equipamentId}&Connect=${connect}`,
    {
      headers: {
        Authorization: 'Bearer ' + token,
      },
    }
  )

  return response
}

export const getUniqueMonitoringEquipmentService = async (token, nameUrl, equipmentId) => {
  const response = await axios.get(`/${nameUrl}/api/MonitoringEquipment/Get/${equipmentId}`, {
    headers: {
      Authorization: 'Bearer ' + token,
    },
  })

  return response
}

export const createMonitoringEquipmentService = async (token, nameUrl, equipment) => {
  const response = await axios.post(
    `/${nameUrl}/api/MonitoringEquipment/Create`,
    {
      ip: equipment.ip,
      port: equipment.port,
      location: equipment.location,
      createDate: equipment.createDate,
      active: equipment.active,
      subCompanyId: equipment.subCompanyId,
      typeEquipmentHardware: equipment.typeEquipmentHardware,
    },
    {
      headers: {
        Authorization: 'Bearer ' + token,
      },
    }
  )

  return response
}

export const deleteMonitoringEquipmentService = async (token, nameUrl, equipmentId) => {
  const response = await axios.post(
    `/${nameUrl}/api/MonitoringEquipment/Delete`,
    {
      equipmentId: equipmentId,
    },
    {
      headers: {
        Authorization: 'Bearer ' + token,
      },
    }
  )

  return response
}

export const editMonitoringEquipmentService = async (token, nameUrl, equipment) => {
  const response = await axios.post(
    `/${nameUrl}/api/MonitoringEquipment/Edit`,
    {
      equipmentId: equipment.monitorId,
      ip: equipment.ip,
      port: equipment.port,
      location: equipment.location,
      active: equipment.active,
      subCompanyId: equipment.subCompanyId,
      mac: equipment.mac,
      typeEquipmentHardware: equipment.typeEquipmentHardware,
    },
    {
      headers: {
        Authorization: 'Bearer ' + token,
      },
    }
  )

  return response
}

//Filiais
export const getSubCompanyService = async (token, nameUrl, limit, offset, order) => {
  let orderToString
  if (order) {
    orderToString = 'asc'
  } else {
    orderToString = 'desc'
  }

  const response = await axios.get(
    `/${nameUrl}/api/SubCompanies/GetAll?limit=${limit}&offset=${offset}&Order=${orderToString}`,
    {
      headers: {
        Authorization: 'Bearer ' + token,
      },
    }
  )

  return response
}
//Filiais
export const getSubCompanyCompanyIdService = async (token, nameUrl, companyId) => {
  const response = await axios.get(`/${nameUrl}/api/SubCompanies/GetAll/${companyId}`, {
    headers: {
      Authorization: 'Bearer ' + token,
    },
  })

  return response
}
//Filiais
export const getLogs = async (token, nameUrl, processStatus) => {
  const response = await axios.get(`/${nameUrl}/api/Log/Get?ProcessStatus=${processStatus}`, {
    headers: {
      Authorization: 'Bearer ' + token,
    },
  })

  return response
}

export const getUserSubCompanyIdService = async (token, nameUrl, subCompanyId) => {
  const response = await axios.get(
    `/${nameUrl}/api/Employee/GetEmployeeWithSubCompanyAll?SubCompanyId=${subCompanyId}`,
    {
      headers: {
        Authorization: 'Bearer ' + token,
      },
    }
  )

  return response
}

export const getUniqueSubCompanyService = async (token, nameUrl, subCompanyId) => {
  const response = await axios.get(`/${nameUrl}/api/SubCompanies/Get/${subCompanyId}`, {
    headers: {
      Authorization: 'Bearer ' + token,
    },
  })

  return response
}

export const createSubCompanyService = async (token, nameUrl, subCompany) => {
  const response = await axios.post(
    `/${nameUrl}/api/SubCompanies/Create`,
    {
      name: subCompany.name,
      cnpj: subCompany.cnpj,
      active: subCompany.active,
      companyId: subCompany.companyId,
      address: subCompany.address,
      city: subCompany.city,
      neighborhood: subCompany.neighborhood,
      cep: subCompany.cep,
      phone: subCompany.phone,
    },
    {
      headers: {
        Authorization: 'Bearer ' + token,
      },
    }
  )

  return response
}

export const createSubCompanyItemService = async (token, nameUrl, data) => {
  const response = await axios.post(`/${nameUrl}/api/SubCompanies/Create`, data, {
    headers: {
      Authorization: 'Bearer ' + token,
    },
  })

  return response
}

export const editSubCompanyService = async (token, nameUrl, subCompany) => {
  const response = await axios.post(
    `/${nameUrl}/api/SubCompanies/Edit`,
    {
      subCompanyId: subCompany.subCompanyId,
      name: subCompany.name,
      cnpj: subCompany.cnpj,
      active: subCompany.active,
      companyId: subCompany.companyId,
      created: subCompany.created,
      address: subCompany.address,
      city: subCompany.city,
      neighborhood: subCompany.neighborhood,
      cep: subCompany.cep,
      phone: subCompany.phone,
    },
    {
      headers: {
        Authorization: 'Bearer ' + token,
      },
    }
  )

  return response
}

export const deleteSubCompanyService = async (token, nameUrl, subCompanyId) => {
  const response = await axios.post(
    `/${nameUrl}/api/SubCompanies/Delete`,
    {
      subCompanyId: subCompanyId,
    },
    {
      headers: {
        Authorization: 'Bearer ' + token,
      },
    }
  )

  return response
}

//Integrações
export const getIntegrationCredencialService = async (token, nameUrl, limit = 20, offset = 0, order = "desc") => {
  const orderToString = order ? "asc" : "desc"

  const response = await axios.get(`/${nameUrl}/api/IntegrationCredencial/GetAll`, {
    params: {
      limit,
      offset,
      Order: orderToString,
    },
    headers: {
      Authorization: `Bearer ${token}`,
    },
  })

  return response
}

export const getUniqueIntegrationCredencialService = async (
  token,
  nameUrl,
  integrationCredencialId
) => {
  const response = await axios.get(
    `/${nameUrl}/api/IntegrationCredencial/Get/${integrationCredencialId}`,
    {
      headers: {
        Authorization: 'Bearer ' + token,
      },
    }
  )

  return response
}

export const createIntegrationCredencialService = async (token, nameUrl, credencial) => {
  const response = await axios.post(
    `/${nameUrl}/api/IntegrationCredencial/Create`,
    {
      tokenType: credencial.tokenType,
      name: credencial.name,
      email: credencial.email,
      userName: credencial.userName,
      password: credencial.password,
      accessToken: credencial.accessToken,
      clientToken: credencial.clientToken,
      clientSecret: credencial.clientSecret,
      refreshToken: credencial.refreshToken,
      expiresIn: credencial.expiresIn,
      appId: credencial.appId,
      active: credencial.active,
      createdateTime: credencial.createdateTime,
      integrationDefaultId: credencial.integrationDefaultId,
      companyId: credencial.companyId,
    },
    {
      headers: {
        Authorization: 'Bearer ' + token,
      },
    }
  )

  return response
}

export const editIntegrationCredencialService = async (token, nameUrl, credencial) => {
  const response = await axios.post(
    `/${nameUrl}/api/IntegrationCredencial/Edit`,
    {
      companyId: credencial.companyId,
      integrationCredencialId: credencial.integrationCredencialId,
      name: credencial.name,
      tokenType: credencial.tokenType,
      email: credencial.email,
      userName: credencial.userName,
      password: credencial.password,
      accessToken: credencial.accessToken,
      clientToken: credencial.clientToken,
      clientSecret: credencial.clientSecret,
      refreshToken: credencial.refreshToken,
      expiresIn: credencial.expiresIn,
      appId: credencial.appId,
      active: credencial.active,
      createdateTime: credencial.createdateTime,
      integrationDefaultId: credencial.integrationDefaultId,
    },
    {
      headers: {
        Authorization: 'Bearer ' + token,
      },
    }
  )

  return response
}

export const deleteIntegrationCredencialService = async (
  token,
  nameUrl,
  integrationCredencialId
) => {
  const response = await axios.post(
    `/${nameUrl}/api/IntegrationCredencial/Delete`,
    {
      integrationCredencialId: integrationCredencialId,
    },
    {
      headers: {
        Authorization: 'Bearer ' + token,
      },
    }
  )

  return response
}

export const getIntegrationAssociationAllService = async (token, nameUrl, data) => {
  let orderToString
  if (data.order) {
    orderToString = 'asc'
  } else {
    orderToString = 'desc'
  }

  const response = await axios.get(
    `/${nameUrl}/api/IntegrationAssociation/GetAll?CompanyId=${data.companyId}&limit=${data.limit}&offset=${data.offset}&Order=${orderToString}`,
    {
      headers: {
        Authorization: 'Bearer ' + token,
      },
    }
  )

  return response
}

export const getIntegrationAssociationAllsService = async (token, nameUrl, companyId) => {
  const response = await axios.get(
    `/${nameUrl}/api/IntegrationAssociation/GetAlls?CompanyId=${companyId}`,
    {
      headers: {
        Authorization: 'Bearer ' + token,
      },
    }
  )

  return response
}

export const getIntegrationAssociationService = async (token, nameUrl, id) => {
  const response = await axios.get(`/${nameUrl}/api/IntegrationAssociation/Get/${id}`, {
    headers: {
      Authorization: 'Bearer ' + token,
    },
  })

  return response
}

export const editIntegrationAssociationService = async (token, nameUrl, credencial) => {
  const data = {
    companyId: credencial.companyId,
    IntegrationCredencialFromId: credencial.integrationCredentialFromId,
    IntegrationCredencialToId: credencial.integrationCredentialToId,
  }

  const response = await axios.post(`/${nameUrl}/api/IntegrationAssociation/Create`, data, {
    headers: {
      Authorization: 'Bearer ' + token,
    },
  })

  return response
}

export const integrationAssociationEditService = async (token, nameUrl, credencial) => {
  const data = {
    integrationAssociationId: credencial.integrationAssociationId,
    companyId: credencial.companyId,
    IntegrationCredencialFromId: credencial.integrationCredentialFromId,
    IntegrationCredencialToId: credencial.integrationCredentialToId,
  }

  const response = await axios.post(`/${nameUrl}/api/IntegrationAssociation/Edit`, data, {
    headers: {
      Authorization: 'Bearer ' + token,
    },
  })

  return response
}

export const deleteIntegrationAssociationService = async (
  token,
  nameUrl,
  integrationAssociationId
) => {
  const response = await axios.post(
    `/${nameUrl}/api/IntegrationAssociation/Delete`,
    {
      integrationAssociationId: integrationAssociationId,
    },
    {
      headers: {
        Authorization: 'Bearer ' + token,
      },
    }
  )

  return response
}

//Colaboradores
export const getEmployeeService = async (token, nameUrl, limit, offset, order, SubCompanyId) => {
  let orderToString
  if (order) {
    orderToString = 'asc'
  } else {
    orderToString = 'desc'
  }

  const response = await axios.get(
    `/${nameUrl}/api/Employee/GetAllPagination?SubCompanyId=${SubCompanyId}&limit=${limit}&offset=${offset}&Order=${orderToString}`,
    {
      headers: {
        Authorization: 'Bearer ' + token,
      },
    }
  )

  return response
}

export const getEmployeeAll = async token => {
  const response = await axios.get(`/api/Employee/GetAll`, {
    headers: {
      Authorization: 'Bearer ' + token,
    },
  })

  return response
}

export const getUniqueEmployeeService = async (token, nameUrl, employeeId) => {
  const response = await axios.get(`/${nameUrl}/api/Employee/Get?Id=${employeeId}`, {
    headers: {
      Authorization: 'Bearer ' + token,
    },
  })

  return response
}

export const addSubCompanyUser = async (token, nameUrl, employeeId, subCompanyId) => {
  const response = await axios.get(
    `/${nameUrl}/api/Employee/AddSubCompany?UserId=${employeeId}&SubCompanyId=${subCompanyId}`,
    {
      headers: {
        Authorization: 'Bearer ' + token,
      },
    }
  )

  return response
}

export const removeSubCompanyUser = async (token, nameUrl, employeeId, subCompanyId) => {
  const response = await axios.get(
    `/${nameUrl}/api/Employee/RemoveSubCompany?UserId=${employeeId}&SubCompanyId=${subCompanyId}`,
    {
      headers: {
        Authorization: 'Bearer ' + token,
      },
    }
  )

  return response
}

export const createEmployeeService = async (token, nameUrl, employee) => {
  const response = await axios.post(
    `/${nameUrl}/api/Employee/Create`,
    {
      companyId: employee.companyId,
      subCompanyId: employee.subCompanyId,
      nameEmployee: employee.nameEmployee,
      emailEmployee: employee.emailEmployee,
      permissionName: employee.permissionName,
      segmentGroupId: employee.segmentGroupId,
      active: true,
    },
    {
      headers: {
        Authorization: 'Bearer ' + token,
      },
    }
  )

  return response
}

export const editEmployeeService = async (token, nameUrl, employee) => {
  const response = await axios.post(
    `/${nameUrl}/api/Employee/Edit`,
    {
      companyId: employee.companyId,
      subCompanyId: employee.subCompanyId,
      active: true,
      nameEmployee: employee.nameEmployee,
      segmentGroupId: employee.segmentGroupId,
      emailEmployee: employee.emailEmployee,
      userId: employee.userId,
      permissionName: employee.permissionName,
    },
    {
      headers: {
        Authorization: 'Bearer ' + token,
      },
    }
  )

  return response
}

export const deleteEmployeeService = async (token, nameUrl, employeeId) => {
  const response = await axios.post(
    `/${nameUrl}/api/Employee/Delete?Id=${employeeId}`,
    {},
    {
      headers: {
        Authorization: 'Bearer ' + token,
      },
    }
  )

  return response
}

//Regras dos Colaboradores
export const getEmployeeRoleService = async (token, nameUrl, employeeId) => {
  const response = await axios.get(
    `/${nameUrl}/api/{ subCompanyId: subCompanyId, typeOrder:?UserId=${employeeId}`,
    {
      headers: {
        Authorization: 'Bearer ' + token,
      },
    }
  )

  return response
}

export const getSubCompanyList = async (token, nameUrl, employeeId) => {
  const response = await axios.get(
    `/${nameUrl}/api/{ subCompanyId: subCompanyId, typeOrder:?UserId=${employeeId}`,
    {
      headers: {
        Authorization: 'Bearer ' + token,
      },
    }
  )

  return response
}

export const addEmployeeRoleService = async (token, nameUrl, employeeRole) => {
  const response = await axios.post(
    `/${nameUrl}/api/Employee/RoleUserAdd`,
    {
      userId: employeeRole.userId,
      rolesId: employeeRole.rolesId,
    },
    {
      headers: {
        Authorization: 'Bearer ' + token,
      },
    }
  )

  return response
}

export const deleteEmployeeRoleService = async (token, nameUrl, employeeRole) => {
  const response = await axios.post(
    `/${nameUrl}/api/Employee/RoleUserDelete?Id=${employeeRole.userId}&RolesId=${employeeRole.rolesId}`,
    {},
    {
      headers: {
        Authorization: 'Bearer ' + token,
      },
    }
  )

  return response
}

export const getAllEmployeeRoleService = async (token, nameUrl) => {
  const response = await axios.get(`/${nameUrl}/api/Role/GetAllEmployee`, {
    headers: {
      Authorization: 'Bearer ' + token,
    },
  })

  return response
}

export const getAllSegmentRoleService = async (token, nameUrl, companyId) => {
  const response = await axios.get(
    `/${nameUrl}/api/SegmentGroup/GetAllCompany?CompanyId=${companyId}`,
    {
      headers: {
        Authorization: 'Bearer ' + token,
      },
    }
  )

  return response
}

export const getUpdateRoleService = async (token, nameUrl, data) => {
  const response = await axios.get(
    `/${nameUrl}/api/Role/UpdateRole?UserId=${data.UserId}&SegmentGroupId=${data.SegmentGroupId}`,
    {
      headers: {
        Authorization: 'Bearer ' + token,
      },
    }
  )

  return response
}

//Impressora
export const getPrinterService = async (token, nameUrl, subCompanyId, limit, offset, order) => {
  let orderToString
  if (order) {
    orderToString = 'asc'
  } else {
    orderToString = 'desc'
  }

  const response = await axios.get(
    `/${nameUrl}/api/Printer/GetAll?SubCompanyId=${subCompanyId}&limit=${limit}&offset=${offset}&Order=${orderToString}`,
    {
      headers: {
        Authorization: 'Bearer ' + token,
      },
    }
  )

  return response
}

export const getUniquePrinterService = async (token, nameUrl, printerId) => {
  const response = await axios.get(`/${nameUrl}/api/Printer/Get/${printerId}`, {
    headers: {
      Authorization: 'Bearer ' + token,
    },
  })

  return response
}

export const createPrinterService = async (token, nameUrl, printer) => {
  const response = await axios.post(
    `/${nameUrl}/api/Printer/Create`,
    {
      ip: printer.ip,
      port: printer.port,
      location: printer.location,
      createDate: printer.createDate,
      active: printer.active,
      subCompanyId: printer.subCompanyId,
      typeEquipmentHardware: printer.typeEquipmentHardware,
    },
    {
      headers: {
        Authorization: 'Bearer ' + token,
      },
    }
  )

  return response
}

export const createReprocessingTags = async (token, nameUrl, subCompanyId, data) => {
  const response = await axios.post(
    `/${nameUrl}/api/AssociationItens/CheckRFID?SubCompanyId=${subCompanyId}`,
    data,
    {
      headers: {
        Authorization: 'Bearer ' + token,
      },
    }
  )

  return response
}

export const deletePrinterService = async (token, nameUrl, printerId) => {
  const response = await axios.post(
    `/${nameUrl}/api/Printer/Delete`,
    {
      equipmentId: printerId,
    },
    {
      headers: {
        Authorization: 'Bearer ' + token,
      },
    }
  )

  return response
}

export const editPrinterService = async (token, nameUrl, printer) => {
  const response = await axios.post(
    `/${nameUrl}/api/Printer/Edit`,
    {
      equipmentId: printer.equipmentId,
      ip: printer.ip,
      port: printer.port,
      location: printer.location,
      active: printer.active,
      subCompanyId: printer.subCompanyId,
      typeEquipmentHardware: printer.typeEquipmentHardware,
    },
    {
      headers: {
        Authorization: 'Bearer ' + token,
      },
    }
  )

  return response
}

//ZPL Impressora

export const getPrinterZplService = async (
  token,
  nameUrl,
  subCompanyId,
  limit,
  offset,
  order,
  text
) => {
  let orderToString
  if (order) {
    orderToString = 'asc'
  } else {
    orderToString = 'desc'
  }

  const response = await axios.get(
    `/${nameUrl}/api/PrinterZpl/GetAllPagination/${subCompanyId}?text=${text}`,
    {
      headers: {
        Authorization: 'Bearer ' + token,
      },
    }
  )

  return response
}

export const getUniquePrinterZplService = async (token, nameUrl, equipmentId) => {
  const response = await axios.get(`/${nameUrl}/api/PrinterZpl/Get/${equipmentId}`, {
    headers: {
      Authorization: 'Bearer ' + token,
    },
  })

  return response
}

export const createPrinterZplService = async (token, nameUrl, equipment) => {
  const response = await axios.post(
    `/${nameUrl}/api/PrinterZpl/Create`,
    {
      name: equipment.name,
      zpl: equipment.zpl,
      subCompanyId: equipment.subCompanyId,
    },
    {
      headers: {
        Authorization: 'Bearer ' + token,
      },
    }
  )

  return response
}

export const deletePrinterZplService = async (token, nameUrl, equipmentId) => {
  const response = await axios.post(
    `/${nameUrl}/api/PrinterZpl/Delete`,
    {
      printerZplId: equipmentId,
    },
    {
      headers: {
        Authorization: 'Bearer ' + token,
      },
    }
  )

  return response
}

export const editPrinterZplService = async (token, nameUrl, equipment) => {
  const response = await axios.post(
    `/${nameUrl}/api/PrinterZpl/Edit`,
    {
      subCompanyId: equipment.subCompanyId,
      printerZplId: equipment.printerZplId,
      name: equipment.name,
      zpl: equipment.zpl,
      create: equipment.create,
      active: equipment.active,
    },
    {
      headers: {
        Authorization: 'Bearer ' + token,
      },
    }
  )

  return response
}

export const getAllTemplateEquipment = async (token, nameUrl, data) => {
  const response = await axios.get(
    `/${nameUrl}/api/TemplateEquipment/GetAll?limit=${data.limit}&offset=${data.offset}&Order=${data.order}`,
    {
      headers: {
        Authorization: 'Bearer ' + token,
      },
    }
  )
  return response
}

export const createTemplateEquipment = async (token, nameUrl, data) => {
  const response = await axios.post(`/${nameUrl}/api/TemplateEquipment/Create`, data, {
    headers: {
      Authorization: 'Bearer ' + token,
    },
  })
  return response
}

export const deleteTemplateEquipment = async (token, nameUrl, data) => {
  const response = await axios.post(`/${nameUrl}/api/TemplateEquipment/Delete`, data, {
    headers: {
      Authorization: 'Bearer ' + token,
    },
  })
  return response
}

export const editTemplateEquipment = async (token, nameUrl, data) => {
  const response = await axios.post(`/${nameUrl}/api/TemplateEquipment/Edit`, data, {
    headers: {
      Authorization: 'Bearer ' + token,
    },
  })
  return response
}

export const getTemplateLabelService = async (token, nameUrl, limit, offset, order) => {
  let orderToString
  if (order) {
    orderToString = 'asc'
  } else {
    orderToString = 'desc'
  }

  const response = await axios.get(
    `/${nameUrl}/api/TemplateLabel/GetAll?limit=${limit}&offset=${offset}&Order=${orderToString}`,
    {
      headers: {
        Authorization: 'Bearer ' + token,
      },
    }
  )

  return response
}

export const updateTemplateLabelService = async (token, nameUrl, data) => {
  const response = await axios.post(`/${nameUrl}/api/TemplateLabel/Edit`, data, {
    headers: {
      Authorization: 'Bearer ' + token,
    },
  })

  return response
}

export const getUniqueTemplateLabelService = async (token, nameUrl, templateId) => {
  const response = await axios.get(`/${nameUrl}/api/TemplateLabel/Get/${templateId}`, {
    headers: {
      Authorization: 'Bearer ' + token,
    },
  })

  return response
}

export const deleteTemplateLabelService = async (token, nameUrl, templateId) => {
  const response = await axios.post(
    `/${nameUrl}/api/TemplateLabel/Delete`,
    {
      labelTemplateId: templateId,
    },
    {
      headers: {
        Authorization: 'Bearer ' + token,
      },
    }
  )

  return response
}

export const createTemplateLabelService = async (token, nameUrl, data) => {
  const response = await axios.post(`/${nameUrl}/api/TemplateLabel/Create`, data, {
    headers: {
      Authorization: 'Bearer ' + token,
    },
  })

  return response
}
