import { useQuery } from '@tanstack/react-query'
import axios from 'axios'

export const getAllReadings = async ({
  token,
  nameUrl,
  startDate,
  endDate,
  cancelType,
  importType,
  companyId,
}) => {
  try {
    const response = await axios.get(
      `/${nameUrl}/api/Sisplan/Get_RFID_Status_Read?DATA_LEITURA_INICIO=${startDate}&DATA_LEITURA_FIM=${endDate}&CANCELADOTYPE=${cancelType}&IMPORTTYPE=${importType}&CompanyId=${companyId}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    )
    return Array.isArray(response.data) && Array.isArray(response.data[0])
      ? response.data[0]
      : response.data
  } catch (error) {
    console.error('Erro ao enviar dados:', error)
    throw error
  }
}

export const useGetAllReadings = (
  token,
  nameUrl,
  startDate,
  endDate,
  cancelType,
  importType,
  companyId
) => {
  return useQuery({
    queryKey: ['readings', token, nameUrl, startDate, endDate, cancelType, importType],
    queryFn: () =>
      getAllReadings({
        token,
        nameUrl,
        startDate,
        endDate,
        cancelType,
        importType,
        companyId,
      }),
    enabled: !!token && !!nameUrl,
    keepPreviousData: true,
  })
}

export const cancelReading = async ({ token, nameUrl, data, companyId }) => {
  try {
    const response = await axios.get(
      `/${nameUrl}/api/sisplan/CancelReadRFIDPagination?ID=${data.iD_LEITURA}&CompanyId=${companyId}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    )
    return response.data
  } catch (error) {
    console.error('Erro ao enviar dados:', error)
    throw error
  }
}
